import { createSelector } from 'reselect';

import { getEntitiesSession } from '../../entities/selectors';
import { getSelectedPartId } from './getSelectedPartId';

export const getSelectedPart = createSelector(
  [getEntitiesSession, getSelectedPartId],
  (session, selectedPartId) => {
    const { Part } = session;

    if (!selectedPartId || !Part.idExists(selectedPartId)) {
      return null;
    }
    
    const part = Part.withId(selectedPartId);
    return part;
  }
);
