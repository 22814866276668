//TODO: bring this in as a library

/* eslint-disable */

import * as tslib_1 from "tslib";
import { Matrix4, Vector3 } from 'three';
import { CommonOps, Matrix2d, SizeOps } from "./hebimath";

///////////////////////////////////////////////////
// Extensions/helpers for ThreeJS

/// <reference types="three">
/**
 * Values of interest:
 * --------------------
 *  0    -Wz   +Wy    x
 * +Wz    0    -Wx    y
 * -Wy   +Wx    0     z
 *  0     0     0     1
 *  --------------------
 *
 *  Col-Major Index
 *  https://github.com/mrdoob/three.js/blob/dev/src/math/Matrix4.js#L43-L46
 *  --------------------
 *  0   4   8   12
 *  1   5   9   13
 *  2   6   10  14
 *  3   7   11  15
 *  --------------------
 */
Matrix4.prototype.getX = function () {
    return this.elements[12];
};
Matrix4.prototype.getY = function () {
    return this.elements[13];
};
Matrix4.prototype.getZ = function () {
    return this.elements[14];
};
Matrix4.prototype.setX = function (value) {
    this.elements[12] = value;
    return this;
};
Matrix4.prototype.setY = function (value) {
    this.elements[13] = value;
    return this;
};
Matrix4.prototype.setZ = function (value) {
    this.elements[14] = value;
    return this;
};
Matrix4.prototype.getWx = function () {
    return (this.elements[6] - this.elements[9]) / 2;
};
Matrix4.prototype.getWy = function () {
    return (this.elements[8] - this.elements[2]) / 2;
};
Matrix4.prototype.getWz = function () {
    return (this.elements[1] - this.elements[4]) / 2;
};
Matrix4.prototype.toString = function () {
    var str = '';
    for (var row = 0; row < 4; row++) {
        for (var col = 0; col < 4; col++) {
            var value = this.elements[col * 4 + row];
            str += value.toFixed(4) + ", ";
        }
        str += '\n';
    }
    return str;
};



///////////////////////////////////////////////////

export var JointAxis;
(function (JointAxis) {
    JointAxis[JointAxis["rz"] = 0] = "rz"; // rotation about z axis
})(JointAxis || (JointAxis = {}));
var PayloadStats = /** @class */ (function () {
    function PayloadStats() {
        this.continuousPayload = 0;
        this.peakPayload = 0;
    }
    return PayloadStats;
}());
export { PayloadStats };
var RobotStats = /** @class */ (function () {
    function RobotStats() {
        this.maxHorizontalReach = 0; // [m]
        this.continuousPayload = 0; // [kg]
        this.peakPayload = 0; // [kg]
        this.mass = 0; // [kg]
        this.continuousPower24V = 0; // [w]
        this.peakPower24V = 0; // [w]
    }
    return RobotStats;
}());
export { RobotStats };
/**
 * For the simple FK/Jacobian/IK case we only need to know about
 * the output transform. Once we get into gravity and acceleration
 * compensation, we will also need to know about the mass and
 * center of mass locations.
 */
var Body = /** @class */ (function () {
    function Body(output, com, mass) {
        this.output = output;
        this.com = com;
        this.mass = mass;
    }
    return Body;
}());
var Joint = /** @class */ (function (_super) {
    tslib_1.__extends(Joint, _super);
    function Joint(axis) {
        var _this = _super.call(this, new Matrix4().identity(), new Matrix4().identity(), 0) || this;
        _this.axis = axis;
        _this.position = 0;
        _this.maxVel = NaN;
        _this.contEffort = NaN;
        _this.peakEffort = NaN;
        _this.contCurrent24V = NaN;
        _this.peakCurrent24V = NaN;
        return _this;
    }
    // Sets transform to match the desired position ([rad] or [m])
    Joint.prototype.setPosition = function (position) {
        // Only update transform if position actually changes
        if (this.position != position) {
            this.position = position;
            switch (this.axis) {
                case JointAxis.rz:
                    this.output.makeRotationZ(position);
                    break;
                default:
                    throw new Error('Unsupported Axis');
            }
        }
    };
    return Joint;
}(Body));
var KinematicChain = /** @class */ (function () {
    function KinematicChain() {
        this.bodies = [];
        this.joints = [];
    }
    KinematicChain.prototype.getNumJoints = function () {
        return this.joints.length;
    };
    KinematicChain.prototype.getNumBodies = function () {
        return this.bodies.length;
    };
    KinematicChain.prototype.addBody = function (body) {
        this.bodies.push(body);
    };
    KinematicChain.prototype.addJoint = function (joint) {
        this.joints.push(joint);
        this.bodies.push(joint);
    };
    KinematicChain.prototype.setPositions = function (positions) {
        if (positions.length != this.joints.length) {
            throw Error('Dimension mismatch');
        }
        for (var i = 0; i < this.joints.length; i++) {
            this.joints[i].setPosition(positions[i]);
        }
    };
    return KinematicChain;
}());
/**
 * Public API
 */
var RobotModel = /** @class */ (function () {
    function RobotModel() {
        this.EXTENSION_EDGE_TO_CENTER_Z_DISTANCE = 0.035 / 2; // 35mm edge to edge
        this.gravCompEfforts = new Matrix2d();
        this.unitPayloadEfforts = new Matrix2d();
        this.tmpCompEffort = new Matrix2d();
        this.tmpWrench3 = new Matrix2d(3, 1);
        this.tmpR = new Matrix4();
        this.tmpRtrans = new Matrix4();
        this.tmpW = new Matrix4();
        this.tmpAngVel = new Vector3();
        this.tmpFrames = [];
        this.tmpTestFrames = [];
        this.tmpJacobians = [];
        this.tmpMatrix = new Matrix4();
        this.robot = new KinematicChain();
        this.deltas = new Matrix2d();
    }
    RobotModel.prototype.addActuator = function (type) {
        var x = 0;
        var y = 0;
        var z = 0;
        var comZ = 0;
        var joint = new Joint(JointAxis.rz);
        // Class-type dependent variables
        switch (type) {
            case "X5-1":
            case "X5-4":
            case "X5-9":
                z = 0.03105;
                comZ = 0.0165;
                joint.contCurrent24V = 0.8;
                joint.peakCurrent24V = 2.4;
                break;
            case "X8-3":
            case "X8-9":
            case "X8-16":
                z = 0.0451;
                comZ = 0.0242;
                joint.contCurrent24V = 2.0;
                joint.peakCurrent24V = 4.5;
                break;
            case "R8-3":
            case "R8-9":
            case "R8-16":
                z = 0.0510;
                comZ = 0.0256;
                joint.contCurrent24V = 1.3;
                joint.peakCurrent24V = 3.0;
                break;
            default:
                throw new Error("Unknown Actuator type: " + type);
        }
        // Individual type specific variables copied from spec sheet
        var mass = 0;
        switch (type) {
            case "X5-1":
                joint.peakEffort = 2.5;
                joint.contEffort = 1.3;
                joint.maxVel = 9.5;
                mass = 0.315;
                break;
            case "X5-4":
                joint.peakEffort = 7;
                joint.contEffort = 4;
                joint.maxVel = 3.35;
                mass = 0.335;
                break;
            case "X5-9":
                joint.peakEffort = 13;
                joint.contEffort = 9;
                joint.maxVel = 1.466;
                mass = 0.360;
                break;
            case "X8-3":
                joint.peakEffort = 7;
                joint.contEffort = 3;
                joint.maxVel = 8.8;
                mass = 0.460;
                break;
            case "X8-9":
                joint.peakEffort = 20;
                joint.contEffort = 8;
                joint.maxVel = 3.142;
                mass = 0.480;
                break;
            case "X8-16":
                joint.peakEffort = 38;
                joint.contEffort = 16;
                joint.maxVel = 1.571;
                mass = 0.500;
                break;
            case "R8-3":
                joint.peakEffort = 7;
                joint.contEffort = 3;
                joint.maxVel = 8.8;
                mass = 0.670;
                break;
            case "R8-9":
                joint.peakEffort = 20;
                joint.contEffort = 8;
                joint.maxVel = 3.142;
                mass = 0.685;
                break;
            case "R8-16":
                joint.peakEffort = 38;
                joint.contEffort = 16;
                joint.maxVel = 1.571;
                mass = 0.715;
                break;
        }
        // Add static transform to CoM
        var com = new Matrix4().makeTranslation(x, y, comZ);
        // Add static transform part
        var T = new Matrix4().makeTranslation(x, y, z);
        this.addBody(T, com, mass);
        // Add dynamic rotation about z axis
        this.addJoint(joint);
        return this;
    };
    RobotModel.prototype.addBracket = function (type) {
        var rx = Math.PI / 2; // right -> positive, left -> negative
        var x = 0; // center stays the same
        var y = 0;
        var z = 0;
        var mass = 0;
        var comY = 0;
        var HEAVY_IN_Y = 0.0225;
        var HEAVY_OUT_Y = 0.0375;
        switch (type) {
            case "X5HeavyRightInside":
                y = HEAVY_IN_Y;
                z = 0.055;
                mass = 0.215;
                comY = HEAVY_OUT_Y / 2;
                break;
            case "X5HeavyRightOutside":
                y = -HEAVY_OUT_Y;
                z = 0.055;
                mass = 0.215;
                comY = y / 2;
                break;
            case "X5HeavyLeftInside":
                rx *= -1;
                y = -HEAVY_IN_Y;
                z = 0.055;
                mass = 0.215;
                comY = -HEAVY_OUT_Y / 2;
                break;
            case "X5HeavyLeftOutside":
                rx *= -1;
                y = HEAVY_OUT_Y;
                z = 0.055;
                mass = 0.215;
                comY = y / 2;
                break;
            case "X5LightRight":
                y = -0.043;
                z = 0.040;
                mass = 0.100;
                comY = y / 2;
                break;
            case "X5LightLeft":
                rx *= -1;
                y = 0.043;
                z = 0.040;
                mass = 0.100;
                comY = y / 2;
                break;
            case "R8HeavyRightInside":
                y = HEAVY_IN_Y;
                z = 0.055;
                mass = 0.215;
                comY = HEAVY_OUT_Y / 2;
                break;
            case "R8HeavyRightOutside":
                y = -HEAVY_OUT_Y;
                z = 0.055;
                mass = 0.215;
                comY = y / 2;
                break;
            case "R8HeavyLeftInside":
                rx *= -1;
                y = -HEAVY_IN_Y;
                z = 0.055;
                mass = 0.215;
                comY = -HEAVY_OUT_Y / 2;
                break;
            case "R8HeavyLeftOutside":
                rx *= -1;
                y = HEAVY_OUT_Y;
                z = 0.055;
                mass = 0.215;
                comY = y / 2;
                break;
            case "R8LightRight":
                y = -0.043;
                z = 0.040;
                mass = 0.100;
                comY = y / 2;
                break;
            case "R8LightLeft":
                rx *= -1;
                y = 0.043;
                z = 0.040;
                mass = 0.100;
                comY = y / 2;
                break;
            default:
                throw new Error("Unknown Bracket type: " + type);
        }
        // Translation to center of mass
        var com = new Matrix4().identity()
            .setX(x / 2)
            .setY(comY)
            .setZ(z / 2);
        // Form static transform and add to model
        var T = new Matrix4()
            .makeRotationX(rx)
            .setX(x)
            .setY(y)
            .setZ(z);
        this.addBody(T, com, mass);
        return this;
    };
    RobotModel.prototype.addLink = function (type, extension, twist) {
        switch (type) {
            case "X5":
                break;
            case "R8":
                break;
            default:
                throw new Error("Unknown Link type: " + type);
        }
        // 193g for screws + adapters + ~0.421g / mm pipe
        var mass = 0.42074 * (extension - 0.025) + 0.193;
        // Move first half to CoM
        var com = new Matrix4().makeTranslation(extension / 2, 0, this.EXTENSION_EDGE_TO_CENTER_Z_DISTANCE);
        // Rotate by twist and move second half
        var T = new Matrix4().makeRotationX(twist)
            .premultiply(com)
            .multiply(com); // Move second half to output (second distance is equal to first)
        this.addBody(T, com, mass);
        return this;
    };
    /**
     * Adds a static 4x4 transform
     *
     * @param transform
     * @param com
     * @param mass
     */
    RobotModel.prototype.addBody = function (transform, com, mass) {
        if (com === void 0) { com = new Matrix4().identity(); }
        if (mass === void 0) { mass = 0; }
        this.robot.addBody(new Body(transform, com, mass));
    };
    /**
     * Adds a mass-less body with a dynamic
     * transform around the specified axis
     *
     * @param jointOrAxis
     * @param maxVel
     * @param maxEffort
     */
    RobotModel.prototype.addJoint = function (jointOrAxis) {
        if (jointOrAxis instanceof Joint)
            this.robot.addJoint(jointOrAxis);
        else {
            this.robot.addJoint(new Joint(jointOrAxis));
        }
    };
    /**
     * Computes forward kinematics to end effector given the
     * desired joint positions.
     *
     * @param positions desired positions for all joints
     * @param result store. Modified
     */
    RobotModel.prototype.getEndEffector = function (positions, result) {
        this.robot.setPositions(positions);
        result.identity();
        for (var i = 0; i < this.robot.bodies.length; i++) {
            result.multiply(this.robot.bodies[i].output);
        }
    };
    /**
     * Returns the Jacobian, the partial derivatives of the forward kinematics
     * equation, for the end effector frame.
     *
     * The Jacobian is of size 6x numDoF. Rows 1-3 of this matrix correspond to
     * this body's linear velocities [m/s] along the X-Y-Z axes in the base frame,
     * while rows 4-6 correspond to rotational velocities [rad/s] about the X-Y-Z
     * axes in the world frame. The columns define the contributions to these linear
     * and rotational velocities that would be a result of the respective degree of
     * freedom instantaneously moving at unit velocity (e.g., a rotational joint
     * being driven at 1 rad/s).
     *
     * @param positions
     * @param result
     */
    RobotModel.prototype.getJEndEffector = function (positions) {
        var J = new Matrix2d(6, this.robot.getNumJoints());
        // Calculate FK to compare to
        var frame = new Matrix4();
        this.getEndEffector(positions, frame);
        var testFrame = new Matrix4();
        var R = new Matrix4().extractRotation(frame); // R
        var Rtrans = R.clone().transpose(); // R'
        var W = new Matrix4();
        var angVel = new Vector3();
        // Perturb each joint slightly and compare effect
        var epsilon = RobotModel.EPSILON;
        for (var col = 0; col < this.robot.getNumJoints(); col++) {
            // Re-compute FK with perturbed input
            var originalPosition = positions[col];
            positions[col] += epsilon;
            this.getEndEffector(positions, testFrame);
            positions[col] = originalPosition;
            // Differentiate positions to get translational velocities
            var x = (testFrame.getX() - frame.getX()) / epsilon;
            var y = (testFrame.getY() - frame.getY()) / epsilon;
            var z = (testFrame.getZ() - frame.getZ()) / epsilon;
            // Differentiate rotation matrices to get rotational velocities
            // W = R' * R_test;
            W.multiplyMatrices(Rtrans, testFrame);
            // Extract angular velocities
            angVel.set(W.getWx() / epsilon, W.getWy() / epsilon, W.getWz() / epsilon);
            // Rotate angular velocities into the Body frame of the robot.
            // R * [ w_x; w_y; w_z ]
            angVel.applyMatrix4(R);
            // Set column
            J.unsafe_set(0, col, x);
            J.unsafe_set(1, col, y);
            J.unsafe_set(2, col, z);
            J.unsafe_set(3, col, angVel.x);
            J.unsafe_set(4, col, angVel.y);
            J.unsafe_set(5, col, angVel.z);
        }
        return J;
    };
    /**
     *  Reduced Jacobian that only returns upper 3 rows (x/y/z)
     *
     * @param positions
     */
    RobotModel.prototype.getJacobianXyz = function (positions) {
        var J = new Matrix2d(3, this.robot.getNumJoints());
        // Calculate FK to compare to
        var frame = new Matrix4();
        this.getEndEffector(positions, frame);
        var testFrame = new Matrix4();
        // Perturb each joint slightly and compare effect
        var epsilon = RobotModel.EPSILON;
        for (var col = 0; col < this.robot.getNumJoints(); col++) {
            // Re-compute FK with perturbed input
            var originalPosition = positions[col];
            positions[col] += epsilon;
            this.getEndEffector(positions, testFrame);
            positions[col] = originalPosition;
            // Differentiate positions to get translational velocities
            var x = (testFrame.getX() - frame.getX()) / epsilon;
            var y = (testFrame.getY() - frame.getY()) / epsilon;
            var z = (testFrame.getZ() - frame.getZ()) / epsilon;
            // Set column
            J.set(0, col, x);
            J.set(1, col, y);
            J.set(2, col, z);
        }
        return J;
    };
    /**
     *
     * @param initialPositions joint angles to start with
     * @param goal goal object
     * @param positions absolute positions to reach desired effect
     */
    RobotModel.prototype.getInverseKinematics = function (initialPositions, goal, positions) {
        switch (goal.kind) {
            case 'delta-translation':
                // d(position) = J^T * (xyz vector) * tuned scaling factor
                var J = this.getJacobianXyz(initialPositions);
                var vector = Matrix2d.wrap(3, 1, [goal.x, goal.y, goal.z]);
                CommonOps.multTransA(J, vector, this.deltas);
                var tuningFactor = 1.0;
                for (var i = 0; i < this.robot.getNumJoints(); i++) {
                    positions[i] = initialPositions[i] + this.deltas.data[i] * tuningFactor;
                }
                return;
        }
        throw new Error("Invalid Goal");
    };
    RobotModel.prototype.getCoMFrames = function (positions, result) {
        SizeOps.resizeTransforms(result, this.robot.getNumBodies());
        this.robot.setPositions(positions);
        var output = this.tmpMatrix.identity();
        for (var i = 0; i < this.robot.getNumBodies(); i++) {
            var body = this.robot.bodies[i];
            // store CoM
            result[i].multiplyMatrices(output, body.com);
            // advance to next output
            output.multiply(body.output);
        }
    };
    /**
     * @param positions array of positions at which to calculate the Jacobians
     * @param jacobians array of Jacobians at the CoM
     */
    RobotModel.prototype.getJacobiansCoM = function (positions, jacobians) {
        if (positions.length != this.robot.getNumJoints())
            throw new Error("Expected one value for each joint");
        SizeOps.resizeMatrices(jacobians, this.robot.getNumBodies(), 3, this.robot.getNumJoints());
        // Make sure Jacobians are zeroed
        for (var _i = 0, jacobians_1 = jacobians; _i < jacobians_1.length; _i++) {
            var J = jacobians_1[_i];
            J.zero();
        }
        // Calculate the state to linearize around and set up the
        // Jacobian matrix based on the return vector
        var numBodies = this.robot.getNumBodies();
        var numJoints = this.robot.getNumJoints();
        var frames = SizeOps.resizeTransforms(this.tmpFrames, numBodies);
        var testFrames = SizeOps.resizeTransforms(this.tmpTestFrames, numBodies);
        this.getCoMFrames(positions, frames);
        // Perturb each joint slightly and compare effect
        var epsilon = RobotModel.EPSILON;
        for (var jointIx = 0; jointIx < this.robot.getNumJoints(); jointIx++) {
            // Re-compute FK with perturbed input
            var originalPosition = positions[jointIx];
            positions[jointIx] += epsilon;
            this.getCoMFrames(positions, testFrames);
            positions[jointIx] = originalPosition;
            // Build each row for a column, corresponding to wrench outputs
            for (var bodyIx = 0; bodyIx < numBodies; bodyIx++) {
                // Extract transforms
                var frame = frames[bodyIx];
                var testFrame = testFrames[bodyIx];
                // Differentiate positions to get translational velocities
                var x = (testFrame.getX() - frame.getX()) / epsilon;
                var y = (testFrame.getY() - frame.getY()) / epsilon;
                var z = (testFrame.getZ() - frame.getZ()) / epsilon;
                // Fill translational rows of Jacobian
                var J = jacobians[bodyIx];
                J.unsafe_set(0, jointIx, x);
                J.unsafe_set(1, jointIx, y);
                J.unsafe_set(2, jointIx, z);
                /*
                // NOTE: we currently only use the first 3 rows for grav comp
                // and we don't do any dynamics comp. Uncomment the lines below
                // to enable 6 dof

                // Differentiate rotation matrices to get rotational velocities
                // W = R' * R_test;
                let R = this.tmpR.extractRotation(frame); // R
                let Rtrans = this.tmpRtrans.copy(R).transpose(); // R'
                let W = this.tmpW.multiplyMatrices(Rtrans, testFrame);

                // Extract angular velocities
                let angVel = this.tmpAngVel.set(
                    W.getWx() / epsilon,
                    W.getWy() / epsilon,
                    W.getWz() / epsilon);

                // Rotate angular velocities into the Body frame of the robot.
                // R * [ w_x; w_y; w_z ]
                angVel.applyMatrix4(R);

                // Set rotational rows
                J.unsafe_set(3, jointIx, angVel.x);
                J.unsafe_set(4, jointIx, angVel.y);
                J.unsafe_set(5, jointIx, angVel.z);
                */
            }
        }
    };
    /**
     * @param positions given joint angles. Not modified
     * @param gravCompEfforts vector of torques needed to sustain weight of robot given gravity. Modified
     * @param unitPayloadEfforts vector of torque needed to sustain a unit weight (1kg) at the current position. Modified
     * @param gravity vector showing where gravity is. Defaults to gravity coming from [0 0 -1]
     */
    RobotModel.prototype.getGravCompEfforts = function (positions, gravCompEfforts, unitPayloadEfforts, gravity) {
        if (gravity === void 0) { gravity = RobotModel.DEFAULT_GRAVITY; }
        // Calculate Jacobians at the center of mass
        var numBodies = this.robot.getNumBodies();
        var numJoints = this.robot.getNumJoints();
        var jacobians = SizeOps.resizeMatrices(this.tmpJacobians, numBodies, 3, numJoints);
        this.getJacobiansCoM(positions, jacobians);
        var tmpResult = this.tmpCompEffort.reshape(numJoints, 1).zero();
        gravCompEfforts.reshape(numJoints, 1).zero();
        unitPayloadEfforts.reshape(numJoints, 1).zero();
        // Sum gravity effects for all bodies
        var wrench = this.tmpWrench3.zero();
        for (var bodyIx = 0; bodyIx < numBodies; bodyIx++) {
            // Set translational part of wrench vector
            var mass = this.robot.bodies[bodyIx].mass;
            if (mass == 0)
                continue;
            wrench.data[0] = -gravity.x * mass;
            wrench.data[1] = -gravity.y * mass;
            wrench.data[2] = -gravity.z * mass;
            // compEffort = J' * wrench
            var J_1 = jacobians[bodyIx];
            CommonOps.multTransA(J_1, wrench, tmpResult);
            CommonOps.addEquals(gravCompEfforts, tmpResult);
        }
        // Calculate what torque would be needed per joint for
        // a unit-payload at the end effector
        var J = jacobians[numBodies - 1];
        var unitMass = 1;
        wrench.data[0] = -gravity.x * unitMass;
        wrench.data[1] = -gravity.y * unitMass;
        wrench.data[2] = -gravity.z * unitMass;
        CommonOps.multTransA(J, wrench, unitPayloadEfforts);
    };
    RobotModel.prototype.getPayloadStats = function (positions, result) {
        // Figure out torques needed to cancel the weight of the robot itself
        var numJoints = this.robot.getNumJoints();
        this.getGravCompEfforts(positions, this.gravCompEfforts, this.unitPayloadEfforts);
        // See what we can do with the leftover portion
        result.continuousPayload = Infinity;
        result.peakPayload = Infinity;
        for (var i = 0; i < numJoints; i++) {
            var joint = this.robot.joints[i];
            var multiplier = 1 / Math.abs(this.unitPayloadEfforts.data[i]);
            var cont = (joint.contEffort - this.gravCompEfforts.data[i]) * multiplier;
            var peak = (joint.peakEffort - this.gravCompEfforts.data[i]) * multiplier;
            result.continuousPayload = Math.min(result.continuousPayload, cont);
            result.peakPayload = Math.min(result.peakPayload, peak);
        }
    };
    RobotModel.prototype.getRobotStats = function (result) {
        var numBodies = this.robot.getNumBodies();
        var numJoints = this.robot.getNumJoints();
        // Sum total mass
        result.mass = 0;
        for (var bodyIx = 0; bodyIx < numBodies; bodyIx++) {
            result.mass += this.robot.bodies[bodyIx].mass;
        }
        // Sum total joint power
        result.continuousPower24V = 0;
        result.peakPower24V = 0;
        for (var jointIx = 0; jointIx < numJoints; jointIx++) {
            result.continuousPower24V += this.robot.joints[jointIx].contCurrent24V;
            result.peakPower24V += this.robot.joints[jointIx].peakCurrent24V;
        }
        result.continuousPower24V *= 24; // convert current to watts
        result.peakPower24V *= 24;
        // Find max horizontal reach
        var positions = new Array(numJoints);
        for (var i = 1; i < numJoints; i++) {
            // initialize slightly offset so we never go straight up
            positions[i] = 0.1;
        }
        // Find the maximum distance increase for each joint
        // Brute force with knowledge that parts can be oriented
        // at a minimum of 90 deg increments
        for (var jointIx = 0; jointIx < numJoints; jointIx++) {
            // try zero angle as base line
            var jointMax = 0;
            positions[jointIx] = 0;
            var jointMaxDistance = this.getHorizontalReach(positions);
            // try other 3 options
            for (var angle = Math.PI / 2; angle < 2 * Math.PI; angle += Math.PI / 2) {
                positions[jointIx] = angle;
                var distance = this.getHorizontalReach(positions);
                if (distance > jointMaxDistance) {
                    jointMaxDistance = distance;
                    jointMax = angle;
                }
            }
            // save highest per joint
            positions[jointIx] = jointMax;
        }
        // Find max payload at the maximum reach
        var payloadStats = new PayloadStats();
        this.getPayloadStats(positions, payloadStats);
        result.continuousPayload = payloadStats.continuousPayload;
        result.peakPayload = payloadStats.peakPayload;
        result.maxHorizontalReach = this.getHorizontalReach(positions);
    };
    RobotModel.prototype.getHorizontalReach = function (positions) {
        var T = this.tmpMatrix;
        this.getEndEffector(positions, T);
        return Math.sqrt(T.getX() * T.getX() + T.getY() * T.getY());
    };
    RobotModel.G_TO_MPS2 = 9.80665;
    RobotModel.DEFAULT_GRAVITY = new Vector3(0, 0, -RobotModel.G_TO_MPS2);
    RobotModel.EPSILON = 1E-6;
    return RobotModel;
}());
export { RobotModel };

