
import {
  call,
  put,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';

import {
  ADD_PART_TO_WORKSPACE_ERROR,
  ADD_PART_TO_WORKSPACE_NOT_SUPPORTED_MESSAGE,
} from '../../actions';

export function* ensurePotentialParentSelectedBeforeCreationSaga() {
  // TODO: Version 1.0+
  // If Multiple potential parents, wait for user to select one, validate, and continue
  yield put({ type: ADD_PART_TO_WORKSPACE_ERROR });
  yield call(toast.error, ADD_PART_TO_WORKSPACE_NOT_SUPPORTED_MESSAGE);
}