
import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import { apiGet, apiPost } from '../../../../common/utils/saga-utils';

import {
  getSelectedPartId,
} from '../../../catalog/selectors';

import {
  getCurrentRobotId,
} from '../../selectors';

import {
  createRobotPartSuccess,
} from '../../actions';

import addPartToWorkspaceSaga from '../addPartToWorkspaceSaga';

export function* createRootPartSaga(threeProcessor) {
  const partId = yield select(getSelectedPartId);
  const robotId = yield select(getCurrentRobotId);

  const id = yield call(apiPost, '/Robot/Part', {
    robotId,
    partId,
  });

  const newPart = yield call(apiGet, `/Robot/Part/${id}`);

  yield put(createRobotPartSuccess(newPart));
  yield call(addPartToWorkspaceSaga, id, threeProcessor);
}
