import {
  POPPER_UPDATE_SUCCESS,
} from './updatePopperRequest';

export const updatePopperSuccess = (location, popperProps) => ({
  type: POPPER_UPDATE_SUCCESS,
  payload: {
    location,
    popperProps,
  },
});
