import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  CLEAR_SELECTED_ROBOT_PART,
  SET_SELECTED_ROBOT_PART,
  SET_SELECTED_ROBOT_PART_ERROR,
  SET_SELECTED_ROBOT_PART_ERROR_MESSAGE,
  setPlacementMode,
} from '../actions';

import {
  getPlacementMode,
  getSceneLoaded,
} from '../selectors';

import {
  hidePopper,
  showPopper,
} from '../../poppers/actions';

export default function* selectRobotPartWatcher() {
  const configurationDialogName = 'ConfiguratorDialog';

  while (true) {
    try {
      const { payload, type } = yield take([
        SET_SELECTED_ROBOT_PART,
        CLEAR_SELECTED_ROBOT_PART,
      ]);

      const sceneLoaded = yield select(getSceneLoaded);

      if (!sceneLoaded) {
        continue;
      }

      const placementMode = yield select(getPlacementMode);
      if (placementMode !== 'place') {
        yield put(setPlacementMode('place'));
      }

      if (type === SET_SELECTED_ROBOT_PART) {
        const { location: { x, y }} = payload;
        yield put(showPopper(x, y, configurationDialogName));
      } else if (type === CLEAR_SELECTED_ROBOT_PART) {
        yield put(hidePopper(configurationDialogName));
      }
    } catch (error) {
      yield put({ type: SET_SELECTED_ROBOT_PART_ERROR, error });
      yield call(toast.error, SET_SELECTED_ROBOT_PART_ERROR_MESSAGE);
    }
  }
}
