import { createReducer } from '../../../common/utils/reducer-utils';

import {
  SET_SELECTED_ROBOT_PART,
  CLEAR_SELECTED_ROBOT_PART,
} from '../actions';

const initialState = {
  selectedRobotPartId: null,
  selectedRobotPartLocation: null,
};

const setSelectedRobotPart = (state, payload) => ({
  ...state,
  selectedRobotPartId: payload.selectedRobotPartId,
  selectedRobotPartLocation: payload.location,
});

const clearSelectedRobotPart = (state) => ({
  ...state,
  selectedRobotPartId: null,
  selectedRobotPartLocation: null,
});

export default createReducer(initialState, {
  [SET_SELECTED_ROBOT_PART]: setSelectedRobotPart,
  [CLEAR_SELECTED_ROBOT_PART]: clearSelectedRobotPart,
});
