import {
  push,
} from 'connected-react-router';

import {
  call,
  put,
  take,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';

import {
  apiGet,
  apiPost,
} from '../../../common/utils/saga-utils';

import {
  setDrawerVisible,
} from '../../catalog/actions';

import {
  ROBOT_CREATE_WITH_PART_REQUEST,
  ROBOT_CREATE_WITH_PART_ERROR,
  ROBOT_CREATE_WITH_PART_ERROR_MESSAGE,
  ROBOT_CREATE_REQUEST,
  ROBOT_CREATE_ERROR,
  ROBOT_CREATE_ERROR_MESSAGE,
  ROBOT_UPLOAD_HRDF_REQUEST,
  ROBOT_UPLOAD_HRDF_ERROR,
  ROBOT_UPLOAD_HRDF_ERROR_MESSAGE,
  createRobotWithPartSuccess,
  createRobotSuccess,
  uploadHRDFSuccess,
} from '../actions';

export default function* createRobotWatcher() {
  while (true) {
    try {
      const { payload, type } = yield take([
        ROBOT_CREATE_REQUEST,
        ROBOT_CREATE_WITH_PART_REQUEST,
        ROBOT_UPLOAD_HRDF_REQUEST,
      ]);

      const { createRobot } = createRobotStrategies[type];
      const id = yield call(createRobot, payload);
      yield put(push(`/configurator/build?robotId=${id}`));
    } catch (error) {
      yield put({ type: ROBOT_CREATE_ERROR, error });
      yield call(toast.error, ROBOT_CREATE_ERROR_MESSAGE);
    }
  }
}

function* createRobotWithPart({ robot, partName }) {
  try {
    const basePart = yield call(getPartByName, partName);
    if (!basePart) {
      return yield call(createEmptyRobot);
    }

    robot = robot || {};
    robot.robotParts = robot.robotParts || [];

    robot = {
      ...robot,
      robotParts: [
        ...robot.robotParts,
        {
          partId: basePart.id,
        },
      ]
    }

    const id = yield call(createRobot, robot);
    yield put(createRobotWithPartSuccess(id));
    yield put(setDrawerVisible(true));
    return id;
  } catch (error) {
    yield put({ type: ROBOT_CREATE_WITH_PART_ERROR, payload: { error } });
    yield call(toast.error, ROBOT_CREATE_WITH_PART_ERROR_MESSAGE);
  } 
}

function* getPartByName(name) {
  const parts = yield call(apiGet, `/Parts?Filters=name==${name}`);
  if (!parts || !parts.length) {
    return null;
  }

  return parts[0];
}

function* createEmptyRobot() {
  try {
    const id = yield call(createRobot, {});
    yield put(createRobotSuccess(id));
    yield put(setDrawerVisible(true));
    return id;
  } catch (error) {
    yield put({ type: ROBOT_CREATE_ERROR, payload: { error } });
    yield call(toast.error, ROBOT_CREATE_ERROR_MESSAGE);
  }
}

function* createRobot(robot) {
  const id = yield call(apiPost, '/Robot', {
    name: 'ROBOT_V1',
    ...robot,
  });

  return id;
}

function* createRobotFromHRDF({ file }) {
  try {
    const id = yield call(apiPost, '/Robot/Upload', {
      file,
    });

    yield put(uploadHRDFSuccess(id));
    yield put(setDrawerVisible(false));
    return id;
  } catch (error) {
    yield put({ type: ROBOT_UPLOAD_HRDF_ERROR, payload: { error } });
    yield call(toast.error, ROBOT_UPLOAD_HRDF_ERROR_MESSAGE);
  }
}

const createRobotStrategies = {
  ROBOT_CREATE_REQUEST: {
    createRobot: createEmptyRobot,
  },
  ROBOT_CREATE_WITH_PART_REQUEST: {
    createRobot: createRobotWithPart,
  },
  ROBOT_UPLOAD_HRDF_REQUEST: {
    createRobot: createRobotFromHRDF,
  }
};
