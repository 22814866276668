import { createSelector } from 'reselect';
import { getCurrentRobot } from './getCurrentRobot';

export const getRequestQuoteTabShouldBeEnabled = createSelector(
  getCurrentRobot,
  currentRobot => {
    if (!currentRobot) {
      return false;
    }
    
    const baseParts = currentRobot.robotParts.toModelArray();
    const hasPart = Boolean(baseParts && baseParts.length);
    return hasPart;
  }
);
