export const UPDATE_ROBOT_STATISTICS = 'UPDATE_ROBOT_STATISTICS';
export const UPDATE_ROBOT_STATISTICS_ERROR = `${UPDATE_ROBOT_STATISTICS}_ERROR`;
export const UPDATE_ROBOT_STATISTICS_ERROR_MESSAGE = 'Error setting the selected part';

export const updateRobotStatistics = (statistics) => ({
  type: UPDATE_ROBOT_STATISTICS,
  payload: {
    statistics,
  },
});
