import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import logo from '../../../common/images/hebi-logo.svg';

const styles = theme => ({
  logo: {
    height: '26.98px',
    width: '81.36px',
  },
});

const HebiLogo = ({ classes }) => (
  <Link to="/">
    <img
      src={logo}
      className={classes.logo}
      alt="HEBI Robotics"
    />
  </Link>
);

HebiLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default withStyles(styles)(HebiLogo);
