import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import startCase from 'lodash-es/startCase';

import { formatNumber } from '../../../common/utils/formatters';

import {
  getPopperProps,
} from '../../poppers/selectors';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 0.9,
  },
});

const formatLabel = (key, value) => {
  let formattedValue = '';
  value = value || 0;

  switch (key) {
    case 'x':
    case 'y':
    case 'z':
      formattedValue = `${formatNumber(value * 1000)} mm`;
      break;
    case 'continuousPayload':
      formattedValue = `${formatNumber(value)} kg`;
      break;
    case 'peakPayload':
      formattedValue = `${formatNumber(value)} kg`;
      break;
    default:
      break;
  }

  const formattedKey = startCase(key);
  return `${formattedKey}: ${formattedValue}`;
}

const TestDriveGrabberLabel = ({
  classes,
  statistics,
}) => {
  if (!statistics) {
    return null;
  }

  return (
    <Paper elevation={10} className={classes.container}>
      <Card>
        <CardContent className={classes.content}>
          {Object.entries(statistics).map(([key, value]) => (
            <Typography
              key={key}
              variant="body2"
              gutterBottom
            >
              {formatLabel(key, value)}
            </Typography>
          ))}
        </CardContent>
      </Card>
    </Paper>
  );
};

TestDriveGrabberLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  statistics: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
    continuousPayload: PropTypes.number,
    peakPayload: PropTypes.number,
  }),
};

const mapStateToProps = state => {
  const popperProps = getPopperProps(state);
  if (!popperProps) {
    return {};
  };

  const { statistics } = popperProps;

  return {
    statistics,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(TestDriveGrabberLabel)
);
