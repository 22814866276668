import { createSelector } from 'reselect';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';

import { getCurrentRobotPart } from './getCurrentRobotPart';
import { getCurrentRobotPartParentOffsets } from './getCurrentRobotPartParentOffsets';

export const getCurrentRobotPartWorkspaceArguments = createSelector(
  [getCurrentRobotPart, getCurrentRobotPartParentOffsets],
  (currentRobotPart, parentOffsets) => {
    if (!currentRobotPart) {
      return {};
    }

    const {
      rotation,
      translation,
      boltPattern,
    } = parentOffsets;

    return {
      part: currentRobotPart.part.toJSON(),
      userData: {
        ...omit(currentRobotPart.toJSON(), [
          'children',
          'part',
        ]),
        ...pick(currentRobotPart.part.toJSON(), [
          'partCategory',
          'type',
          'attachments'
        ]),
        parentOutputBoltPattern: boltPattern,
        rotation,
        translation,
      }
    }
  }
);
