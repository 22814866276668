import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';

import {
  apiGet,
} from '../../../common/utils/saga-utils';

import {
  SET_PLACEMENT_MODE,
  SET_CURRENT_ROBOT,
  TOGGLE_PLACEMENT_MODE,
  ADD_PART_TO_WORKSPACE_SUCCESS,
  ROBOT_PART_REMOVE_CONFIRM_SUCCESS,
} from '../../configurator/actions';

import {
  getCurrentRobotId,
  getSelectedRobotPartId,
  getPlacementMode,
} from '../../configurator/selectors';

import {
  UPDATE_AVAILABLE_PARTS_ERROR,
  UPDATE_AVAILABLE_PARTS_ERROR_MESSAGE,
  updateAvailablePartsRequest,
  updateAvailablePartsSuccess,
} from '../actions';

const strategies = {
  place: {
    updateAvailableParts: getAvailablePartsForPlacement,
  },
  replace: {
    updateAvailableParts: getAvailablePartsForReplacement,
  },
};

export default function* updateAvailablePartsWatcher() {
  while (true) {
    try {
      yield take([
        ADD_PART_TO_WORKSPACE_SUCCESS,
        SET_CURRENT_ROBOT,
        SET_PLACEMENT_MODE,
        TOGGLE_PLACEMENT_MODE,
        ROBOT_PART_REMOVE_CONFIRM_SUCCESS,
      ]);

      const placementMode = yield select(getPlacementMode);
      const { updateAvailableParts } = strategies[placementMode];
      yield put(updateAvailablePartsRequest());

      const parts = yield call(updateAvailableParts);
      const partIds = parts.map(p => p.partId);
      yield put(updateAvailablePartsSuccess(partIds))
    } catch (error) {
      yield put({ type: UPDATE_AVAILABLE_PARTS_ERROR, error });
      yield call(toast.error, UPDATE_AVAILABLE_PARTS_ERROR_MESSAGE);
    }
  }
}

function* getAvailablePartsForPlacement() {
  const robotId = yield select(getCurrentRobotId);
  const parts = yield call(apiGet, `/Robot/${robotId}/Parts/Placement`);
  return parts;
}

function* getAvailablePartsForReplacement() {
  const replaceId = yield select(getSelectedRobotPartId);
  const parts = yield call(apiGet, `/Robot/Part/${replaceId}/Replacement`);
  return parts;
}
