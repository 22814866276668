import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import {
  removeRobotPartRequest,
  togglePlacementMode,
} from '../../actions';

import {
  getPlacementMode,
  getSelectedRobotPart,
} from '../../selectors';

import {
  setDrawerVisible,
} from '../../../catalog/actions';

import strategies from './strategies';

const styles = theme => ({
  card: {
    width: '330px',
    pointerEvents: 'auto',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
  },
  subheader: {
    fontSize: '16px',
    whiteSpace: 'pre',
  },
  cardActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const ConfiguratorDialog = ({
  category,
  classes,
  className,
  handleRemove,
  handleReplace,
  placementMode,
  title,
  subheader,
}) => {
  const { DialogContent } = strategies[category] || strategies.default;

  const onReplaceClicked = () => {
    handleReplace(placementMode);
  };

  const getReplaceButton = () => {
    const inReplaceMode = placementMode === 'replace';
    const color = inReplaceMode ? 'primary' : 'default';
    const variant = inReplaceMode ? 'contained' : 'text';

    return (
      <Button
        onClick={onReplaceClicked}
        color={color}
        variant={variant}
      >
        Replace
      </Button>
    )
  };

  return (
    <Paper elevation={10}>
      <Card className={classes.card}>
        <CardHeader
          classes={{
            title: classes.title,
            subheader: classes.subheader,
          }}
          title={title}
          subheader={subheader}
          action={
            <Tooltip title="Click and Drag">
              <IconButton aria-label="Click and Drag" className="drag-handle">
                <DragIndicatorIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent className={classes.content}>
          <DialogContent />
        </CardContent>
        <CardActions className={classNames(classes.cardActions, className)}>
          <Button onClick={handleRemove}>Remove</Button>
          {getReplaceButton()}
        </CardActions>
      </Card>
    </Paper>
  )
};

ConfiguratorDialog.propTypes = {
  category: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  handleRemove: PropTypes.func.isRequired,
  handleReplace: PropTypes.func.isRequired,
  placementMode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string,
};

const defaultValues = {
  category: '',
  placementMode: 'place',
  subheader: '',
  title: '',
};

ConfiguratorDialog.defaultProps = defaultValues;

const mapStateToProps = state => {
  const selectedRobotPart = getSelectedRobotPart(state);
  if (!selectedRobotPart) {
    return defaultValues;
  }

  const associatedPart = selectedRobotPart.part;
  const placementMode = getPlacementMode(state);

  return {
    title: associatedPart.name,
    placementMode,
    subheader: associatedPart.description,
    category: associatedPart.partCategory.name,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRemove: () => dispatch(removeRobotPartRequest()),
    handleReplace: placementMode => {
      dispatch(togglePlacementMode());
      const drawerShouldBeVisible = placementMode === 'place';
      dispatch(setDrawerVisible(drawerShouldBeVisible));
    },
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConfiguratorDialog)
);