import { createConditionalSliceReducer } from '../../../common/utils/reducer-utils';

import {
  UPDATE_AVAILABLE_PARTS_REQUEST,
  UPDATE_AVAILABLE_PARTS_SUCCESS,
} from '../../catalog/actions';

import {
  ADD_PART_TO_WORKSPACE_REQUEST,
} from '../../configurator/actions';

import orm from '../../../app/orm';

const disableParts = (state) => {
  const session = orm.session(state);
  const { Part } = session;

  Part.all().update({
    enabledForWorkspace: false,
  });

  return session.state;
};

const enableAvailableParts = (state, payload) => {
  const session = orm.session(state);
  const { Part } = session;
  const { ids } = payload;

  Part.all()
    .filter(p => ids.includes(p.id))
    .update({
      enabledForWorkspace: true,
    });

  return session.state;
};

const robotHandlers = {
  [UPDATE_AVAILABLE_PARTS_REQUEST]: disableParts,
  [UPDATE_AVAILABLE_PARTS_SUCCESS]: enableAvailableParts,
  [ADD_PART_TO_WORKSPACE_REQUEST]: disableParts,
};

export default createConditionalSliceReducer('entities', robotHandlers);

