import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
});

const Placeholder = ({
  children,
  classes,
  innerProps,
}) => (
  <Typography
    color="textSecondary"
    className={classes.placeholder}
    {...innerProps}
  >
    {children}
  </Typography>
);

Placeholder.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
};

export default withStyles(styles)(Placeholder);
