import { createSelector as createOrmSelector } from 'redux-orm';

import orm from '../../../app/orm';
import { getEntities } from '../../entities/selectors';

export const getAssets = createOrmSelector(
  orm,
  getEntities,
  session => {
    const { Asset } = session;

    return Asset.all().toModelArray().map(asset => {
      const obj = asset.toJSON();

      return {
        id: obj.id,
        url: obj.url,
      };
    });
  }
);
