import { createSelector } from 'reselect';

import { getCurrentPathname } from '../../shared/router/selectors';

export const getInBuildMode = createSelector(
  getCurrentPathname,
  pathname => {
    if (!pathname || !pathname.length) {
      return false;
    }

    return pathname === '/configurator/build';
  },
);