import { createSelector } from 'reselect';

import { getEntitiesSession } from '../../entities/selectors';
import { getCurrentRobotId } from './getCurrentRobotId';

export const getCurrentRobot = createSelector(
  [getEntitiesSession, getCurrentRobotId],
  (session, currentRobotId) => {
    const { Robot } = session;

    if (!currentRobotId || !Robot.idExists(currentRobotId)) {
      return null;
    }
    
    const robot = Robot.withId(currentRobotId);
    return robot;
  }
);
