import * as THREE from 'three';

export const createPartGroup = (meshes, userData) => {
  const group = createGroup();

  group.userData = {
    ...userData,
    addChild,
    getChild,
    getChildMeshes,
    removeChild,
    addInterfaceItem,
  };

  return group;

  function createGroup() {
    const group = new THREE.Group();
      meshes.forEach((mesh) => {
        const clonedMesh = mesh.clone();
        clonedMesh.geometry.computeBoundingBox();
        group.add(clonedMesh);
      });
  
    return group;
  }

  function addChild(child) {
    group.add(child);
  }

  function removeChild(child) {
    group.remove(child);
  }

  function getChildMeshes() {
    const meshesToOutline = group.children.filter(child => child.type === 'Mesh');
    return meshesToOutline;
  }

  function getChild() {
    const children = group.children.filter(child => child.type === 'Group');
    if (!children || !children.length) {
      return null;
    }

    return children[0];
  }

  function addInterfaceItem(interfaceItem) {

    if(group.userData.attachments && group.userData.attachments.length) {
      const { childTranslationOffset, childRotationOffset } = group.userData.attachments[0];

      group.add(interfaceItem);

      interfaceItem.position.set(childTranslationOffset.x, childTranslationOffset.y, childTranslationOffset.z);
      interfaceItem.rotation.set(childRotationOffset.x, childRotationOffset.y, childRotationOffset.z, 'XYZ');
    }
  }
}
