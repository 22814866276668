import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';

const ExpansionPanel = withStyles({
  root: {
    minHeight: '67px',
  },
  expanded: {
    margin: 0,
  },
})(MuiExpansionPanel);

export default ExpansionPanel;
