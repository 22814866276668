
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash-es/get';
import omit from 'lodash-es/omit';
import { connect } from 'react-redux';

import countryNames from '../../../common/utils/country-names';

import RequestQuoteForm from '../components/RequestQuoteForm';

import { submitQuoteRequest } from '../actions';

const countries = countryNames.map(name => ({
  value: name,
  label: name,
}));

const RequestQuoteFormContainer = ({
  handleSubmitQuote,
}) => (
  <Formik
    enableReinitialize
    initialValues={{
      emailAddress: '',
      name: '',
      phoneNumber: '',
      country: '',
      fieldOfResearch: '',
      comments: '',
      contactViaPhone: false,
      includeAssembly: false,
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string()
                .required('Required'),
      country: Yup.string()
                .required('Required'),
      contactViaPhone: Yup.bool(),
      includeAssembly: Yup.bool(),
      emailAddress: Yup.string()
        .email('Email address in invalid format')
        .required('Required'),
      phoneNumber: Yup.string()
        .when('contactViaPhone', {
          is: val => val === true,
          then: Yup.string().required('Required'),
          otherwise: Yup.string().notRequired()
        })
    })}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      const formValues = {
        ...omit(values, 'country'),
        country: get(values, ['country', 'value']),
      };
      handleSubmitQuote(formValues, {
        resetForm,
        setSubmitting,
      });
    }}
    render={formikProps =>
      <RequestQuoteForm
        countries={countries}
        {...formikProps}
      />
    }
  />
);

RequestQuoteFormContainer.propTypes = {
  handleSubmitQuote: PropTypes.func.isRequired,
};

const actions = {
  handleSubmitQuote: submitQuoteRequest,
};

export default connect(null, actions)(RequestQuoteFormContainer);
