import React from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

import 'react-toastify/dist/ReactToastify.css';

import ModalManager from '../features/modals/ModalManager';
import PopperManager from '../features/poppers/PopperManager';

import AppNav from '../features/shared/AppNav';
import AppFooter from '../features/shared/AppFooter';
import GettingStartedPage from '../features/getting-started/pages/GettingStartedPage';
import ConfiguratorLoader from '../features/configurator/pages/ConfiguratorLoader';
import NotFoundPage from '../features/shared/NotFoundPage';

import DefaultLayout from '../features/shared/layouts/DefaultLayout';

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

class App extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <CssBaseline />
        <ToastContainer autoClose={3000} position={toast.POSITION.BOTTOM_RIGHT} />
        <ModalManager />
        <PopperManager />
        <div className={classes.container}>
          <AppNav />
          <Switch>
            <DefaultLayout path="/" exact component={GettingStartedPage} />
            <DefaultLayout path="/configurator" component={ConfiguratorLoader} />
            <DefaultLayout component={NotFoundPage} />
          </Switch>
          <AppFooter />
        </div>
      </>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(App)
);
