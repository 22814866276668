import {
  Vector2,
  Vector3,
} from 'three';

export default class WindowManager {
  static instance;

  constructor(canvas, camera, renderer, composer) {
    if (this.instance) {
      return this.instance;
    }

    this.canvas = canvas;
    this.camera = camera;
    this.renderer = renderer;
    this.composer = composer;
    this.workspaceDimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.bindEventListeners = this.bindEventListeners.bind(this);
    this.getWindowCoordinates = this.getWindowCoordinates.bind(this);
    this.normalizeMouseScreenCoords = this.normalizeMouseScreenCoords.bind(this);
    this.removeEventListeners = this.removeEventListeners.bind(this);
    this.resizeCanvas = this.resizeCanvas.bind(this);
    this.bindEventListeners();
    this.instance = this;
  }

  removeEventListeners() {
    window.removeEventListener('resize', this.resizeCanvas);
    window.removeEventListener('beforeunload', this.removeEventListeners);
  }

  bindEventListeners() {
    window.addEventListener('resize', this.resizeCanvas);
    window.addEventListener('beforeunload', this.removeEventListeners);
  }

  getWindowCoordinates(object) {
    const position = new Vector3();
    object.getWorldPosition(position);
    position.project(this.camera);

    const x = Math.round((0.5 + position.x / 2) * (this.workspaceDimensions.width / window.devicePixelRatio));
    const y = Math.round((0.5 - position.y / 2) * (this.workspaceDimensions.height  / window.devicePixelRatio));

    return {
      x,
      y,
    };
  }

  resizeCanvas() {
    this.canvas.style.width = '100%';
    this.canvas.style.height= '100%';
    
    this.canvas.width  = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;

    this.workspaceDimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.camera.aspect = this.workspaceDimensions.width / this.workspaceDimensions.height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.workspaceDimensions.width, this.workspaceDimensions.height);
    this.composer.setSize(this.workspaceDimensions.width, this.workspaceDimensions.height);
  }

  normalizeMouseScreenCoords(x, y) {
    //top left is (-1, 1), bottom right is (1, -1)

    const mouse2D = new Vector2(
      (x / this.workspaceDimensions.width) * 2 - 1,
      - (y / this.workspaceDimensions.height) * 2 + 1
    );

    return mouse2D;
  }
}
