import {
  INITIALIZE_CONFIGURATOR_SUCCESS,
  INITIALIZE_CONFIGURATOR_SUCCESS_CACHED,
} from '../actions';

const initialState = {
  initialized: false,
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case INITIALIZE_CONFIGURATOR_SUCCESS:
    case INITIALIZE_CONFIGURATOR_SUCCESS_CACHED:
      return {
        initialized: true,
      };
    default:
      return state;
  }
};