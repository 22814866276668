import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import BuildRobotPage from '../pages/BuildRobotPage';
import TestDrivePage from '../pages/TestDrivePage';
import RequestQuotePage from '../../request-quote/pages/RequestQuotePage';

import ConfiguratorTabBar from '../components/ConfiguratorTabBar';

const ConfiguratorTabbedRoutes = ({
  match,
}) => (
  <React.Fragment>
    <ConfiguratorTabBar />

    <Switch>
      <Route path={`${match.url}/build`} component={BuildRobotPage} />
      <Route path={`${match.url}/test-drive`} component={TestDrivePage} />
      <Route path={`${match.url}/request-quote`} component={RequestQuotePage} />
    </Switch>
  </React.Fragment>
);

ConfiguratorTabbedRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(ConfiguratorTabbedRoutes);
