import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import ExportRobotButton from '../components/ExportRobotButton';
import WorkspaceInstructions from '../../shared/WorkspaceInstructions';
import WorkspaceToolbar from '../components/WorkspaceToolbar';
import TestDriveSpecifications from '../components/TestDriveSpecifications';
import { activateTestDrive, deactivateTestDrive } from '../actions';
import {
  getSceneLoaded,
} from '../selectors';

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
})

const instructions = [{
  icon: 'touch_app',
  instruction: 'Grab and move the end of the robot to view measurements',
}];

class TestDrivePage extends React.Component {
  componentDidMount() {
    if (this.props.sceneLoaded) {
      this.props.handleActivateTestDrive();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.sceneLoaded && this.props.sceneLoaded) {
      this.props.handleActivateTestDrive();
    }
  }

  componentWillUnmount() {
    this.props.handleDeactivateTestDrive();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <WorkspaceInstructions instructions={instructions} />
        <TestDriveSpecifications />
        <WorkspaceToolbar />
        <ExportRobotButton />
      </div>
    );
  }
}

TestDrivePage.propTypes = {
  classes: PropTypes.object.isRequired,
  handleActivateTestDrive: PropTypes.func.isRequired,
  handleDeactivateTestDrive: PropTypes.func.isRequired,
  sceneLoaded: PropTypes.bool,
};

TestDrivePage.defaultProps = {
  sceneLoaded: false,
};

const mapStateToProps = (state) => ({
  sceneLoaded: getSceneLoaded(state),
});

const actions = {
  handleActivateTestDrive: activateTestDrive,
  handleDeactivateTestDrive: deactivateTestDrive,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    actions,
  )(TestDrivePage)
);
