import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import {
  getShouldWarnBeforeRobotPartRemoval,
} from '../selectors';

import {
  removeRobotPartConfirmRequest,
  removeRobotPartCancel,
} from '../actions';

const RemovePartConfirmationModal = ({
  title,
  description,
  handleCancel,
  handleConfirm,
}) => (
  <Dialog
    open={true}
    onClose={handleCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <Typography id="alert-dialog-description" variant="subtitle1">
        {description}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirm} color="primary" autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

RemovePartConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const shouldWarn = getShouldWarnBeforeRobotPartRemoval(state);
  const title = 'Confirm component removal';
  const description = shouldWarn ?
    'Removing this component from the robot will also remove all components that are dependent on this one.  Are you sure you want to proceed?' :
    'Do you want to remove this component from the robot?';
  
  return {
    title,
    description,
  };
}

const actions = {
  handleCancel: removeRobotPartCancel,
  handleConfirm: removeRobotPartConfirmRequest,
};

export default connect(mapStateToProps, actions)(RemovePartConfirmationModal);
