export const POPPER_SHOW = 'POPPER_SHOW';

export const showPopper = (x, y, type, popperProps) => ({
  type: POPPER_SHOW,
  payload: {
    location: {
      x,
      y,
    },
    type,
    popperProps,
  },
});
