import { createSelector } from 'reselect';

import { getCurrentRobotPartAsset } from './getCurrentRobotPartAsset';

export const getCurrentRobotPartAssetUrl = createSelector(
  getCurrentRobotPartAsset,
  asset => {
    if (!asset) {
      return null;
    }

    const { url } = asset.toJSON();

    return url;
  }
);
