import { createSelector } from 'reselect';
import { getCurrentRobot } from './getCurrentRobot';

export const getTestDriveTabShouldBeEnabled = createSelector(
  getCurrentRobot,
  currentRobot => {
    if (!currentRobot) {
      return false;
    }
    
    const baseParts = currentRobot.robotParts.toModelArray();
    if(baseParts && baseParts.length) {
      const nextPart = baseParts[0].children.toModelArray();
      const hasMultipleParts = Boolean(nextPart && nextPart.length);
      return hasMultipleParts;
    }

    return false;
  }
);
