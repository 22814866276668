import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { exportRobotRequest } from '../actions/exportRobotRequest';
import { openModal } from '../../modals/actions';

const styles = theme => ({
  exportRobot: {
    display: 'flex',
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    zIndex: theme.zIndex.workspace + 1,
  },
  exportRobotButton: {
    borderColor: theme.palette.neutral.main.color,
    height: '40px',
  },
  feedbackButton: {
    borderColor: theme.palette.neutral.main.color,
    height: '40px',
    marginRight: '10px',
    backgroundColor: '#FDFF0055'
  },
});

const ExportRobotButton = ({
  classes,
  handleExportRobot,
  handleFeedbackClick,
}) => (
  <div className={classes.exportRobot}>
    <Button
      variant="outlined"
      color="primary"
      className={classes.feedbackButton}
      onClick={handleFeedbackClick}
    >
      THIS IS A BETA! Provide Feedback
    </Button>
    <Button
      variant="outlined"
      color="primary"
      className={classes.exportRobotButton}
      onClick={handleExportRobot}
    >
      Export HRDF
    </Button>
  </div>
);

ExportRobotButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

const actions = {
    handleExportRobot: () => exportRobotRequest(),
    handleFeedbackClick: () => openModal('FeedbackModal'),
  };

export default withStyles(styles)(connect( null, actions)(ExportRobotButton));
