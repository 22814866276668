import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const GettingStartedCardContent = ({
  description,
  title,
}) => (
  <React.Fragment>
    <Typography gutterBottom variant="h6">
      {title}
    </Typography>
    {
      description && description.length &&
      <Typography variant="body1" component="p">
        {description}
      </Typography>
    }
  </React.Fragment>
);

GettingStartedCardContent.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

GettingStartedCardContent.defaultProps = {
  description: '',
};

export default GettingStartedCardContent;
