import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiPatch } from '../../../common/utils/saga-utils';

import {
  ROBOT_PART_UPDATE_CONSTRAINT_REQUEST,
  ROBOT_PART_UPDATE_CONSTRAINT_ERROR,
  ROBOT_PART_UPDATE_CONSTRAINT_ERROR_MESSAGE,
  updateSelectedRobotPartConstraintSuccess,
} from '../actions';

import {
  getSelectedRobotPartId,
} from '../selectors';

export default function* configurePartWatcher(threeProcessor) {
  while (true) {
    try {
      const { payload } = yield take(ROBOT_PART_UPDATE_CONSTRAINT_REQUEST)
      const { constraint, value } = payload;
      const selectedRobotPartId = yield select(getSelectedRobotPartId);
      
      yield call(updateConstraint, selectedRobotPartId, constraint, value);
      threeProcessor.updatePartConstraint(selectedRobotPartId, constraint, value);

      yield put(updateSelectedRobotPartConstraintSuccess(selectedRobotPartId, constraint, value));
    } catch (error) {
      yield put({ type: ROBOT_PART_UPDATE_CONSTRAINT_ERROR, error });
      yield call(toast.error, ROBOT_PART_UPDATE_CONSTRAINT_ERROR_MESSAGE);
    }
  }
}

function* updateConstraint(robotPartId, constraint, value) {
  yield call(apiPatch, '/Robot/Part/Constraint', {
    id: robotPartId,
    constraint,
    value,
  });
}
