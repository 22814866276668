import { createLoadingSelector } from '../../loading/selectors';
import {
  ROBOT_UPLOAD_HRDF_PREFIX,
  ROBOT_LOAD_PREFIX,
  ROBOT_CREATE_PREFIX,
  ROBOT_CREATE_WITH_PART_PREFIX,
} from '../../configurator/actions';

export const getIsCreatingRobot = createLoadingSelector([
  ROBOT_UPLOAD_HRDF_PREFIX,
  ROBOT_LOAD_PREFIX,
  ROBOT_CREATE_PREFIX,
  ROBOT_CREATE_WITH_PART_PREFIX,
]);
