import {
  call,
  take,
} from 'redux-saga/effects';

import {
  DEACTIVATE_TEST_DRIVE
} from '../actions';

export default function* deactivateTestDriveWatcher(threeProcessor) {
  while (true) {
    yield take(DEACTIVATE_TEST_DRIVE);
    
    yield call(threeProcessor.deactivateTestDrive);
  }
}
