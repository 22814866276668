export const POPPER_UPDATE_PREFIX = 'POPPER_UPDATE';
export const POPPER_UPDATE_REQUEST = `${POPPER_UPDATE_PREFIX}_REQUEST`;
export const POPPER_UPDATE_SUCCESS = `${POPPER_UPDATE_PREFIX}_POPPER_UPDATE_SUCCESS`;

export const updatePopperRequest = (x, y, popperProps) => ({
  type: POPPER_UPDATE_REQUEST,
  payload: {
    location: {
      x,
      y,
    },
    popperProps,
  },
});
