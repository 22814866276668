
export const ROBOT_PART_REMOVE_PREFIX = 'ROBOT_PART_REMOVE';
export const ROBOT_PART_REMOVE_REQUEST = `${ROBOT_PART_REMOVE_PREFIX}_REQUEST`;
export const ROBOT_PART_REMOVE_CANCEL = `${ROBOT_PART_REMOVE_PREFIX}_CANCEL`;
export const ROBOT_PART_REMOVE_SUCCESS = `${ROBOT_PART_REMOVE_PREFIX}_SUCCESS`;
export const ROBOT_PART_REMOVE_ERROR = `${ROBOT_PART_REMOVE_PREFIX}_ERROR`;
export const ROBOT_PART_REMOVE_ERROR_MESSAGE = 'Error removing robot part';

export const removeRobotPartRequest = () => ({
  type: ROBOT_PART_REMOVE_REQUEST,
});
