import placePartSaga from './placePartSaga';
import replacePartSaga from './replacePartSaga';

export default {
  place: {
    createPart: placePartSaga,
  },
  replace: {
    createPart: replacePartSaga,
  },
};
