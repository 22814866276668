export const ROBOT_CREATE_WITH_PART_PREFIX = 'ROBOT_CREATE_WITH_PART';
export const ROBOT_CREATE_WITH_PART_REQUEST = `${ROBOT_CREATE_WITH_PART_PREFIX}_REQUEST`;
export const ROBOT_CREATE_WITH_PART_SUCCESS = `${ROBOT_CREATE_WITH_PART_PREFIX}_SUCCESS`;
export const ROBOT_CREATE_WITH_PART_ERROR = `${ROBOT_CREATE_WITH_PART_PREFIX}_ERROR`;
export const ROBOT_CREATE_WITH_PART_ERROR_MESSAGE = 'Error creating robot with specified base. Check that the part name is correct';

export const createRobotWithPartRequest = (robot, partName) => ({
  type: ROBOT_CREATE_WITH_PART_REQUEST,
  payload: {
    robot,
    partName,
  }
});
