import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  setCurrentRobotPart,
} from '../actions';

import {
  getCurrentRobotPartWorkspaceArguments,
  getCurrentRobotPart,
} from '../selectors';

export default function* addPartToWorkspaceSaga(robotPartId, threeProcessor) {
  yield put(setCurrentRobotPart(robotPartId));
  const robotPart = yield select(getCurrentRobotPart);

  const args = yield select(getCurrentRobotPartWorkspaceArguments);
  yield call(threeProcessor.addPart, args);

  for (const childPart of robotPart.children.toModelArray()) {
    yield call(addPartToWorkspaceSaga, childPart.id, threeProcessor);
  }
}
