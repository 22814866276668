import React from 'react';

import LinkDialogContent from './LinkDialogContent';

export default {
  Link: {
    DialogContent: LinkDialogContent,
  },
  default: {
    DialogContent: () => <React.Fragment />,
  },
}
