/**
 * All HEBI figures are defined in meters
 * @param {number} length 
 * @param {string} targetUnit 
 */
export const standardizeLength = (length, targetUnit) => {
  switch (targetUnit.toLowerCase()) {
    case 'millimeters':
      return length;
    case 'meters':
      return length * 1000;
    default:
      return length;
  }
}