import { createSelector } from 'reselect';

import { getEntitiesSession } from '../../entities/selectors';
import { getCurrentRobotPartId } from './getCurrentRobotPartId';

export const getCurrentRobotPart = createSelector(
  [getEntitiesSession, getCurrentRobotPartId],
  (session, currentRobotPartId) => {
    const { RobotPart } = session;

    if (!currentRobotPartId || !RobotPart.idExists(currentRobotPartId)) {
      return null;
    }
    
    const robotPart = RobotPart.withId(currentRobotPartId);
    return robotPart;
  }
);
