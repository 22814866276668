import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';

import { apiPost } from '../../../common/utils/saga-utils';

import {
  SUBMIT_QUOTE_REQUEST,
  SUBMIT_QUOTE_SUCCESS,
  SUBMIT_QUOTE_SUCCESS_MESSAGE,
  SUBMIT_QUOTE_ERROR,
  SUBMIT_QUOTE_ERROR_MESSAGE,
} from '../actions';

import {
  getCurrentRobotId,
} from '../../configurator/selectors';

export default function* submitQuoteWatcher() {
  while (true) {
    try {
      const { payload } = yield take(SUBMIT_QUOTE_REQUEST);

      const robotId = yield select(getCurrentRobotId);
      if (!robotId) {
        return;
      }

      const {
        quote, 
        setSubmitting,
        resetForm,
      } = payload;

      yield call(submitQuote, robotId, quote, setSubmitting, resetForm);
    } catch (error) {
      yield call(handleError, error);
    }
  }
}

function* submitQuote(robotId, quote, setSubmitting, resetForm) {
  try {
    yield call(apiPost, '/Quote', {
      robotId,
      ...quote,
    });

    resetForm();

    yield call(toast.success, SUBMIT_QUOTE_SUCCESS_MESSAGE);
    yield put({ type: SUBMIT_QUOTE_SUCCESS });
  } catch (error) {
    yield call(handleError, error);
  }

  setSubmitting(false);
}

function* handleError(error) {
  yield put({ type: SUBMIT_QUOTE_ERROR, payload: { error } });
  yield call(toast.error, SUBMIT_QUOTE_ERROR_MESSAGE);
}
