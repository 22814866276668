import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import CancelIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
});

// Needs to be a class component to support refs
class MultiValue extends React.Component {
  render() {
    const {
      children,
      classes,
      isFocused,
      removeProps,
    } = this.props;

    return (
      <Chip
        tabIndex={-1}
        label={children}
        className={classNames(classes.chip, {
          [classes.chipFocused]: isFocused,
        })}
        onDelete={removeProps.onClick}
        deleteIcon={<CancelIcon {...removeProps} />}
      />
    );
  }
}

MultiValue.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultiValue);
