import { createSelector } from 'reselect';

import { getCurrentPathname } from '../../shared/router/selectors';
import { getConfiguratorIsInitialized } from '../../configurator/selectors';

export const getWorkspaceIsVisible = createSelector(
  [getCurrentPathname, getConfiguratorIsInitialized],
  (pathname, isInitialized) => {
    if (!pathname || !pathname.length) {
      return false;
    }

    const isWorkspacePage = pathname === '/configurator/build'
      || pathname === '/configurator/test-drive';
    
    return isWorkspacePage && isInitialized;
  },
);

