export const ROBOT_CREATE_PREFIX = 'ROBOT_CREATE';
export const ROBOT_CREATE_REQUEST = `${ROBOT_CREATE_PREFIX}_REQUEST`;
export const ROBOT_CREATE_SUCCESS = `${ROBOT_CREATE_PREFIX}_SUCCESS`;
export const ROBOT_CREATE_ERROR = `${ROBOT_CREATE_PREFIX}_ERROR`;
export const ROBOT_CREATE_ERROR_MESSAGE = 'Error creating robot';

export const createRobotRequest = () => ({
  type: ROBOT_CREATE_REQUEST,
  payload: {
  }
});
