import { createSelector } from 'reselect';

import { getCurrentRobotPart } from './getCurrentRobotPart';

export const getCurrentRobotPartParent = createSelector(
  getCurrentRobotPart,
  currentRobotPart => {
    if (!currentRobotPart) {
      return null;
    }

    const parentRobotParts = currentRobotPart.parent.toModelArray();
    if (!parentRobotParts || !parentRobotParts.length) {
      return null;
    }

    const parentPart = parentRobotParts[0];
    return parentPart;
  }
);
