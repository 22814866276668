import { createMuiTheme } from '@material-ui/core/styles';

import {
  typography,
  zIndex,
} from './variables';

const secondaryColor = '#DCD521';

let theme = createMuiTheme({
  typography: {
    ...typography,
    color: '#F1F1EF',
  },
  zIndex,
  palette: {
    type: 'dark',
    primary: {
      main: '#64F8AF',
    },
    secondary: {
      main: secondaryColor,
    },
    neutral: {
      light: {
        color: '#6F6E68',
      },
    }
  },
  overrides: {
    MuiSlider: {
      track: { backgroundColor: secondaryColor },
      thumb: { backgroundColor: secondaryColor },
    },
  }
});

export default theme;
