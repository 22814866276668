import { createConditionalSliceReducer } from '../../../common/utils/reducer-utils';

import {
  ROBOT_PART_CREATE_SUCCESS,
  ROBOT_PART_REMOVE_SUCCESS,
  ROBOT_PART_REPLACE_REQUEST,
  ROBOT_PART_UPDATE_CONSTRAINT_SUCCESS,
  ROBOT_LOAD_SUCCESS,
} from '../../configurator/actions';

import orm from '../../../app/orm';

const loadRobot = (state, payload) => {
  const session = orm.session(state);
  const { Robot } = session;
  const { robot } = payload;

  Robot.parse(robot);

  return session.state;
};

const addRobotPart = (state, payload) => {
  const session = orm.session(state);
  const { Robot, RobotPart } = session;
  const { robotPart } = payload;

  const newRobotPart = RobotPart.parse(robotPart);

  if (robotPart.parentRobotPartId) {
    RobotPart.withId(robotPart.parentRobotPartId).children.add(newRobotPart);
  } else {
    const { robotId } = robotPart;
    Robot.withId(robotId).robotParts.add(newRobotPart);
  }

  return session.state;
};

const removeRobotParts = (state, payload) => {
  const session = orm.session(state);
  const { RobotPart } = session;
  const { ids } = payload;

  RobotPart.filter(rp => ids.includes(rp.id)).delete();

  return session.state;
};

const updateRobotPartConstraint = (state, payload) => {
  const session = orm.session(state);
  const { RobotPart } = session;
  const { id, constraint, value } = payload;

  RobotPart.withId(id)[constraint] = value;

  return session.state;
};

const replaceRobotPart = (state, payload) => {
  const session = orm.session(state);
  const { RobotPart } = session;
  const { replaceId, newPart } = payload;

  const {
    partId: newPartId,
    length,
    twist,
  } = newPart;

  const existingRobotPart = RobotPart.withId(replaceId);
  existingRobotPart.part = newPartId;
  existingRobotPart.length = length;
  existingRobotPart.twist = twist;

  return session.state;
};

const robotHandlers = {
  [ROBOT_LOAD_SUCCESS]: loadRobot,
  [ROBOT_PART_CREATE_SUCCESS]: addRobotPart,
  [ROBOT_PART_REMOVE_SUCCESS]: removeRobotParts,
  [ROBOT_PART_REPLACE_REQUEST]: replaceRobotPart,
  [ROBOT_PART_UPDATE_CONSTRAINT_SUCCESS]: updateRobotPartConstraint,
};

export default createConditionalSliceReducer('entities', robotHandlers);
