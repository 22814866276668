import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const RobotBuilderButtonBarMobile = ({
  classes,
  handleAddComponentClick,
  handleNewSessionClick,
}) => (
  <div className={classes.buttons}>
    <Tooltip title="New Session">
      <IconButton
        className={classes.button}
        color="primary"
        aria-label="New Session"
        onClick={handleNewSessionClick}
      >
        <RefreshIcon />
      </IconButton>
    </Tooltip>

    <Tooltip title="Components">
      <IconButton
        className={classes.button}
        color="primary"
        onClick={handleAddComponentClick}
        aria-label="Components"
      >
        <AddBoxIcon />
      </IconButton>
    </Tooltip>
    
  </div>
);

RobotBuilderButtonBarMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAddComponentClick: PropTypes.func.isRequired,
  handleNewSessionClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(RobotBuilderButtonBarMobile);
