const initialState = {};

export default (state = initialState, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR|CANCEL)(.*)/.exec(type);
  
  // not a *_REQUEST / *_SUCCESS / *_ERROR actions, so ignore
  if (!matches) return state;
  
  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_ERROR
    [requestName]: requestState === 'REQUEST',
  };
};
