import {
  LOAD_ENTITIES_SUCCESS,
} from './loadEntitiesRequest';

export const loadEntitiesSuccess = (entities) => ({
  type: LOAD_ENTITIES_SUCCESS,
  payload: {
    ...entities,
  },
});
