import { combineReducers } from 'redux';

import bootstrapper from './bootstrapper';
import builder from './builder';
import workspace from './workspace';

export default combineReducers({
  bootstrapper,
  builder,
  workspace,
});
