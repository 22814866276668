import {
  attr,
  Model,
} from 'redux-orm';

class PartLength extends Model {
  static get fields() {
    return {
      id: attr(),
      length: attr(),
      unit: attr(),
    };
  }

  static parse(partLengthData) {
    const parsedData = {
      ...partLengthData,
    };

    return this.upsert(parsedData);
  }

  toJSON() {
    const partLength = {
      ...this.ref,
    };
  
    return partLength;
  }

  updateFrom(otherPartLength) {
    this.update(otherPartLength.ref);
  }
}

PartLength.modelName = 'PartLength';

export default PartLength;
