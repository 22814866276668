import strategies from './part-group-strategies';

export default (camera) => {
  function create(meshes, userData) {
    const {
      partCategory: category,
      translation,
      rotation,
    } = userData;

    const { createPartGroup } = strategies[category] || strategies.default;
    const group = createPartGroup(meshes, userData);

    const canRotate = category === 'Actuator';

    // Return attributes and functions as part of userData so that the consumer can freely call them
    group.userData = {
      ...group.userData,
      setLocalTransform,
      getOutlineMeshes,
      canRotate,
      updateConstraint,
    };

    group.name = userData.id;

    setLocalTransform(translation, rotation);

    if (group.userData.applyTransformation) {
      group.userData.applyTransformation();
    }

    if (group.userData.twist) {
      updateConstraint('twist', group.userData.twist);
    }
    
    return group;
  
    function setLocalTransform(translation, rotation) {
      if (translation) {
        group.position.x = translation.x;
        group.position.y = translation.y;
        group.position.z = translation.z;
      }

      if (rotation) {
        group.rotation.x = rotation.x;
        group.rotation.y = rotation.y;
        group.rotation.z = rotation.z;
      }
    }

    function getOutlineMeshes() {
      if (!group || !group.userData || !group.userData.getChildMeshes) {
        return [];
      }

      const { getChildMeshes } = group.userData;
      const meshesToOutline = getChildMeshes();
      return meshesToOutline;
    }

    function updateConstraint(constraint, value) {
      switch (constraint) {
        case 'twist':
          if (group.userData.updateTwist) {
            group.userData.updateTwist(value);
          }
          break;
        case 'length':
          if (group.userData.updateLength) {
            group.userData.updateLength(value);
          }
          break;
        default:
          break;
      }
    }
  }

  return {
    create,
  };
}
