import { createSelector } from 'reselect';

import { getEntitiesSession } from '../../entities/selectors';
import { getSelectedCategoryId } from './getSelectedCategoryId';

export const getPartsInSelectedCategory = createSelector(
  [getEntitiesSession, getSelectedCategoryId],
  (session, selectedCategoryId) => {
    const { Part, PartCategory } = session;
    
    if (!selectedCategoryId || !PartCategory.idExists(selectedCategoryId)) {
      return [];
    }
    
    const parts = Part
      .all()
      .toModelArray()
      .filter(p => p.partCategory.id === selectedCategoryId);

    return parts;
  }
);