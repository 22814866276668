import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';

const styles = theme => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    minWidth: '170px',
    margin: theme.spacing.unit,
  },
  icon: {
    paddingRight: theme.spacing.unit,
  },
});

const RobotBuilderButtonBarDesktop = ({
  classes,
  handleAddComponentClick,
  handleNewSessionClick,
}) => (
  <div className={classes.buttons}>
    <Button
      variant="outlined"
      color="primary"
      className={classes.button}
      onClick={handleNewSessionClick}
    >
      <RefreshIcon className={classes.icon} />
      New Session
    </Button>
    <Button
      variant="outlined"
      color="primary"
      className={classes.button}
      onClick={handleAddComponentClick}
    >
      <AddBoxIcon className={classes.icon} />
      Component
    </Button>
  </div>
);

RobotBuilderButtonBarDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAddComponentClick: PropTypes.func.isRequired,
  handleNewSessionClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(RobotBuilderButtonBarDesktop);
