import {
  all,
  call,
  put,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  apiGet,
} from '../../../common/utils/saga-utils';

import {
  setSelectedCategory,
} from '../../catalog/actions';

import {
  LOAD_ENTITIES_REQUEST,
  LOAD_ENTITIES_ERROR,
  LOAD_ENTITIES_ERROR_MESSAGE,
  loadEntitiesSuccess,
} from '../actions';

function* loadEntities() {
  const entities = yield all({
    boltPatterns: call(apiGet, '/Part/Attachment/boltPattern'),
    partLengths: call(apiGet, '/Part/lengths'),
    parts: call(apiGet, '/parts'),
    partCategories: call(apiGet, '/Part/categories?sorts=name'),
  });

  return entities;
}

export default function* loadEntitiesSaga() {
  try {
    yield put({ type: LOAD_ENTITIES_REQUEST });
    const entities = yield call(loadEntities);
    yield put(loadEntitiesSuccess(entities));
    yield call(setDefaultSelectedCategory, entities.partCategories);
  } catch (error) {
    yield put({ type: LOAD_ENTITIES_ERROR, payload: { error } });
    yield call(toast.error, LOAD_ENTITIES_ERROR_MESSAGE);
  }
}

function* setDefaultSelectedCategory(categories) {
  if (!categories || !categories.length) {
    return;
  }

  const { id: defaultCategoryId } = categories[0];
  yield put(setSelectedCategory(defaultCategoryId));
}