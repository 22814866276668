import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import pluralize from 'pluralize';

import { getEntitiesSession } from '../../entities/selectors';
import { getSelectedCategoryId } from '../selectors';
import { setSelectedCategory } from '../actions';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: '120px',
  },
});

class CategorySelect extends React.Component {
  state = {
    labelWidth: 0,
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.inputLabelRef).offsetWidth,
    });
  }

  render() {
    const {
      categories,
      classes,
      handleCategoryChange,
      selectedCategory,
    } = this.props;

    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          htmlFor="outlined-category"
          ref={ref => {
            this.inputLabelRef = ref;
          }}
        >
          Category
        </InputLabel>
        <Select
          value={selectedCategory}
          onChange={({ target }) => handleCategoryChange(target.value)}
          input={
            <OutlinedInput
              name="category"
              id="outlined-category"
              labelWidth={this.state.labelWidth}
            />
          }
        >
          {
            categories && categories.length &&
            categories.filter(({ id, name, enabled }) => ( name !== "Base Plates" )).map(({ id, name, enabled }) => (
              <MenuItem value={id} key={id} disabled={!enabled}>{name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )
  }
}

CategorySelect.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  classes: PropTypes.object.isRequired,
  handleCategoryChange: PropTypes.func.isRequired,
  selectedCategory: PropTypes.oneOfType([
    PropTypes.oneOf(['']),
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = state => {
  const session = getEntitiesSession(state);
  const { Part, PartCategory } = session;

  const categories = PartCategory.all().toModelArray().map(({id, name}) => ({
    id,
    name: pluralize(name),
    enabled: Part.all().toModelArray().filter(p => p.partCategory.id === id).some(p => p.enabledForWorkspace),
  }));

  let selectedCategory = getSelectedCategoryId(state);
  selectedCategory = selectedCategory || '';

  return {
    categories,
    selectedCategory,
  };
};

const actions = {
  handleCategoryChange: id => setSelectedCategory(id || null),
};

export default withStyles(styles)(
  connect(mapStateToProps, actions)(CategorySelect)
);
