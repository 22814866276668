import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import { apiGet, apiPut } from '../../../../common/utils/saga-utils';

import {
  getSelectedPart,
} from '../../../catalog/selectors';

import {
  setCurrentRobotPart,
  setPlacementMode,
  replaceRobotPartSuccess,
  replaceRobotPartRequest,
} from '../../actions';

import {
  getSelectedRobotPart,
  getCurrentRobotPartWorkspaceArguments,
} from '../../selectors';

export default function* replacePartSaga(threeProcessor) {
  const partToReplace = yield select(getSelectedRobotPart);
  const replaceWithPart = yield select(getSelectedPart);

  yield call(apiPut, '/Robot/Part', {
    replaceRobotPartId: partToReplace.id,
    withPartId: replaceWithPart.id,
  });

  const newPart = yield call(apiGet, `/Robot/Part/${partToReplace.id}`);
  yield put(replaceRobotPartRequest(partToReplace.id, newPart))
  yield put(setCurrentRobotPart(partToReplace.id));
  const args = yield select(getCurrentRobotPartWorkspaceArguments);

  yield call(threeProcessor.replacePart, partToReplace, args);

  yield put(setPlacementMode('place'));
  yield put(replaceRobotPartSuccess());
}
