import { ORM } from 'redux-orm';

import {
  Asset,
  Attachment,
  BoltPattern,
  Part,
  PartCategory,
  PartLength,
  Robot,
  RobotPart,
} from '../models';

const orm = new ORM();
orm.register(
  Asset,
  Attachment,
  BoltPattern,
  Part,
  PartCategory,
  PartLength,
  Robot,
  RobotPart,
);

export default orm;
