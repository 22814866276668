import {
  attr,
  Model,
} from 'redux-orm';

class Asset extends Model {
  static get fields() {
    return {
      id: attr(),
      fileName: attr(),
      contentType: attr(),
      thumbnail: attr(),
    };
  }

  static parse(assetData) {
    return this.upsert(assetData);
  }

  toJSON() {
    return {
      ...this.ref,
      url: `asset/${this.ref.id}`,
    };
  }

  updateFrom(otherAsset) {
    this.update(otherAsset.ref);
  }
}

Asset.modelName = 'Asset';

export default Asset;
