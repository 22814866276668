import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import {
  createRobotRequest,
} from '../../configurator/actions';

import {
  toggleDrawerVisible as toggleComponentCatalogDrawerVisible,
} from '../../catalog/actions';

import RobotBuilderButtonBarDesktop from './RobotBuilderButtonBarDesktop';
import RobotBuilderButtonBarMobile from './RobotBuilderButtonBarMobile';

const RobotBuilderButtonBar = ({
  handleAddComponentClick,
  handleNewSessionClick,
  width,
}) => {
  if (isWidthDown('xs', width)) {
    return (
      <RobotBuilderButtonBarMobile
        handleAddComponentClick={handleAddComponentClick}
        handleNewSessionClick={handleNewSessionClick}
      />
    )
  }

  return (
    <RobotBuilderButtonBarDesktop
      handleAddComponentClick={handleAddComponentClick}
      handleNewSessionClick={handleNewSessionClick}
    />
  )
};

RobotBuilderButtonBar.propTypes = {
  width: PropTypes.string.isRequired,
  handleAddComponentClick: PropTypes.func.isRequired,
  handleNewSessionClick: PropTypes.func.isRequired,
}

const actions = {
  handleAddComponentClick: toggleComponentCatalogDrawerVisible,
  handleNewSessionClick: createRobotRequest,
};

export default withWidth()(
  connect(null, actions)(RobotBuilderButtonBar)
);
