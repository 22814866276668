import { map } from 'lodash-es';
import * as THREE from 'three';

import store from '../../../app/store';
import {TranslationMode} from './TranslationMode';
import { RobotModel, PayloadStats, RobotStats } from '../../../features/shared/InverseKinematics/kinematics';

import {
  updateRobotStatistics,
} from '../../../features/configurator/actions';

export default class InverseKinematicsManager {
  static instance;

  constructor() {
    if (this.instance) {
      return this.instance;
    }

    this.ikRobot = new RobotModel();
    this.directChildrenOfActuators = [];
    this.ikPartsSequence = [];
    this.staticStats = new RobotStats();
    this.dynamicStats = new PayloadStats();

    this.resetRobot = this.resetRobot.bind(this);
    this.buildRobot = this.buildRobot.bind(this);
    this.traverseRobot = this.traverseRobot.bind(this);
    this.buildIK = this.buildIK.bind(this);
    this.areAnyChildrenGroups = this.areAnyChildrenGroups.bind(this);
    this.updateInverseKinematics = this.updateInverseKinematics.bind(this);
    this.getDynamicStats = this.getDynamicStats.bind(this);
    this.sanitizeStat = this.sanitizeStat.bind(this);
    this.clampRotations = this.clampRotations.bind(this);
    this.isChangeTooLarge = this.isChangeTooLarge.bind(this);

    this.instance = this;
  }

  resetRobot() {
    this.ikRobot = new RobotModel();
    this.directChildrenOfActuators = [];
    this.ikPartsSequence = [];
  }

  buildRobot(terminatingPart) {
    this.ikRobot = new RobotModel();
    this.dynamicStats = new PayloadStats();

    this.traverseRobot(terminatingPart);
    this.buildIK();

    this.ikRobot.getRobotStats(this.staticStats);
    store.dispatch(updateRobotStatistics(this.staticStats));
  }

  traverseRobot(robotPart) {
    //work backwards from the end and build up the chain

    if(robotPart.parent) {

      if (robotPart.parent.userData.canRotate) {
        this.directChildrenOfActuators.unshift(robotPart);
      }
      else if(robotPart.userData.canRotate && this.areAnyChildrenGroups(robotPart.children) === false) {
        //the terminating part is a actuator - add a dummy child part after the actuator so
        //that the solver can rotate it like usual using this zeroed rotation property
        this.directChildrenOfActuators.unshift({
          userData: {
            type: 'DummyActuatorChild',
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
        });
      }

      this.ikPartsSequence.unshift(robotPart);

      this.traverseRobot(robotPart.parent);
    }
  }

  buildIK() {
    this.ikPartsSequence.forEach(part => {
      switch(part.userData.partCategory) {
        case 'Actuator':
          this.ikRobot.addActuator(part.userData.type);
        break;
        case 'Link':
          this.ikRobot.addLink(
            part.userData.type,
            (part.userData.length && part.userData.length !== 0) ? part.userData.length : part.userData.defaultLength,
            part.userData.twist ? part.userData.twist : 0
          );
        break;
        case 'Bracket':
          this.ikRobot.addBracket(part.userData.type);
        break;
        case 'DummyActuatorChild':
          //this is not a type from the database - it is only inserted at runtime for robots that terminate in an actuator
          this.ikRobot.addBody(new THREE.Matrix4(), new THREE.Matrix4(), 0);
        break;
        default:
        //the solver only cares about the part types above
        break;
      }
    });
  }

  areAnyChildrenGroups(robotPartChildren) {
    let hasGroups = false;
    robotPartChildren.forEach(child => {
      if(child.type === "Group") {
        hasGroups = true;
      }
    });
    return hasGroups;
  }
  
  updateInverseKinematics(deltaGoal, testDriveTranslationMode, allowClamping, loggingEnabled) {
    if(this.ikRobot) {
      let startingRotations = map(this.directChildrenOfActuators, c => c.rotation.z);
      let updatedRotations = [];

      startingRotations.forEach(r => {
        updatedRotations.push(0);
      });

      switch(testDriveTranslationMode) {
        case TranslationMode.xOnly:
          deltaGoal = new THREE.Vector3(deltaGoal.x, 0, 0);
        break;
        case TranslationMode.yOnly:
          deltaGoal = new THREE.Vector3(0, deltaGoal.y, 0);
        break;
        case TranslationMode.zOnly:
          deltaGoal = new THREE.Vector3(0, 0, deltaGoal.z);
        break;
        default:
          //keep the vector as-is
        break;
      }

      try {
        this.ikRobot.getInverseKinematics(
          startingRotations,
          {kind: "delta-translation", x: deltaGoal.x, y: deltaGoal.y, z: deltaGoal.z},
          updatedRotations
        );
      }
      catch(err) {
        console.log('Invalid robot configuration!');
        console.log(err);
      }

      if(allowClamping) {
        this.clampRotations(startingRotations, updatedRotations);
      }

      //TODO: for HEBI's internal testing
      if(loggingEnabled) {
        console.log("vector:");
        console.log(deltaGoal);

        console.log("starting rotations:");
        console.log(startingRotations);

        console.log("updated rotations:");
        console.log(updatedRotations);

        console.log("----------------");
      }

      for(var i = 0; i < updatedRotations.length; i++) {
        this.directChildrenOfActuators[i].rotation.z = updatedRotations[i];
      }

      this.ikRobot.getPayloadStats(updatedRotations, this.dynamicStats);
    }
  }

  getDynamicStats() {
    this.dynamicStats.continuousPayload = this.sanitizeStat(this.dynamicStats.continuousPayload);
    this.dynamicStats.peakPayload = this.sanitizeStat(this.dynamicStats.peakPayload);

    return this.dynamicStats;
  }

  sanitizeStat(stat) {
    if(!stat || isNaN(stat)) {
      stat = 0;
    }
    return stat;
  }

  clampRotations(startingRotations, updatedRotations) {

    const acceptableVariance = 0.02;

    for(let i = 0; i < startingRotations.length; i++) {
      if(this.isChangeTooLarge(acceptableVariance, startingRotations[i], updatedRotations[i])) {
        if(startingRotations[i] < updatedRotations[i]) {
          updatedRotations[i] = startingRotations[i] + acceptableVariance;
        }
        else {
          updatedRotations[i] = startingRotations[i] - acceptableVariance;
        }
      }
    }
  }

  isChangeTooLarge(acceptableVariance, original, updated) {
    return Math.abs(original - updated) >= acceptableVariance;
  }
}
