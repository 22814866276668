import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons';
import {
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '104px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    backgroundColor: theme.palette.neutral.main.color,
    zIndex: theme.zIndex.appBar,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  button: {
    textTransform: 'lowercase',
    fontWeight: 'normal',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing.unit * 2,
    },
  },
  urlContainer: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing.unit * 2,
    },
  },
});

const links = [{
  id: 'mail',
  icon: faEnvelope,
  href: 'mailto:info@hebirobotics.com',
  newTab: false,
}, {
  id: 'youtube',
  icon: faYoutube,
  href: 'https://www.youtube.com/channel/UCIOok6K_XlNoDPgCNPhDQnA',
  newTab: true,
}, {
  id: 'twitter',
  icon: faTwitter,
  href: 'https://twitter.com/HebiRobotics',
  newTab: true,
}, {
  id: 'instagram',
  icon: faInstagram,
  href: 'https://www.instagram.com/hebirobotics/',
  newTab: true,
}, {
  id: 'linkedIn',
  icon: faLinkedin,
  href: 'https://www.linkedin.com/company/hebi-robotics/ ',
  newTab: true,
}];

const AppFooter = ({ classes }) => (
  <Toolbar className={classes.container}>
    <div className={classes.innerContainer}>
      <div className={classes.urlContainer}>
        <Button
          color="primary"
          href="https://www.hebirobotics.com"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.button}
        >
          hebirobotics.com
        </Button>

        <Button
          color="primary"
          href="http://docs.hebi.us"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.button}
        >
          docs.hebi.us
        </Button>
      </div>

      <div>
      {
        links.map(link => (
          <a
            key={link.id}
            href={link.href}
            {
              ...(
                !!link.newTab && {
                target: '_blank',
                rel: 'noopener noreferrer',
             })
            }
          >
            <IconButton className={classes.icon}>
              <FontAwesomeIcon icon={link.icon} size="sm" />
            </IconButton>
          </a>
        ))
      }
      </div>
    </div>
  </Toolbar>
);

AppFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppFooter);
