import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles'; 

const styles = theme => ({
  container: {
    '& > div': {
      paddingTop: theme.spacing.unit * 6,
      paddingBottom: theme.spacing.unit * 6,
      ...theme.mixins.gutters(),
    },
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.neutral.light.color,
  },
});

const DefaultLayout = ({
  component: Component,
  classes,
  ...rest
}) => {
  return (
    <Route {...rest} render={matchProps => (
      <div className={classes.container}>
        <Component {...matchProps} />
      </div>
      )}
    />
  )
};

DefaultLayout.propTypes = {
  component: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DefaultLayout);
