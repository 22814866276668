import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { SECONDARY_FONT } from '../../../common/fonts';

import {
  getTestDriveTabShouldBeEnabled,
  getRequestQuoteTabShouldBeEnabled,
} from '../selectors';

const styles = theme => ({
  root: {
  },
  tabsRoot: {
    backgroundColor: theme.palette.neutral.darkest.color,
  },
  tabRoot: {
    fontFamily: SECONDARY_FONT,
    fontSize: '16px',
    color: '#D3D3CE',
    '&:hover': {
      color: theme.palette.neutral.main.color,
      backgroundColor: '#5B5A54',
    },
    '&$tabSelected': {
      color: theme.palette.secondary.main,
    },
  },
  tabSelected: {}, // Needed to override CSS specificity
});

const ConfiguratorTabBar = ({
  classes,
  location,
  match,
  testDriveTabEnabled,
  requestQuoteTabEnabled,
}) => {
  const tabs = [{
    title: 'Build Robot',
    to: `${match.url}/build`,
    disabled: false,
  }, {
    title: 'Test Drive',
    to: `${match.url}/test-drive`,
    disabled: !testDriveTabEnabled,
  }, {
    title: 'Request Quote',
    to: `${match.url}/request-quote`,
    disabled: !requestQuoteTabEnabled,
  }];

  return (
    <AppBar position="static" color="default">
      <Tabs
        value={location.pathname}
        indicatorColor="secondary"
        variant="fullWidth"
        className={classes.tabsRoot}
      >
        {
          tabs.map(tab => (
            <Tab
              key={tab.title}
              component={Link}
              to={{
                pathname: tab.to,
                search: location.search
              }}
              disabled={tab.disabled}
              label={tab.title}
              value={tab.to}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
          ))
        }
      </Tabs>
    </AppBar>
  );
};



ConfiguratorTabBar.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  testDriveTabEnabled: PropTypes.bool.isRequired,
  requestQuoteTabEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  testDriveTabEnabled: getTestDriveTabShouldBeEnabled(state),
  requestQuoteTabEnabled: getRequestQuoteTabShouldBeEnabled(state),
});

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(ConfiguratorTabBar))
);
