import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Form, Formik } from 'formik';

import { closeModal } from '../../modals/actions';

import { apiPost } from '../../../common/utils/saga-utils';

const submitFeedback = feedbackMessage => {
  console.log(feedbackMessage);
  // Insert action here
  // e.g. axios.post('/user', values)

  apiPost('/Feedback', feedbackMessage);
};

const FeedbackForm = ({ setSentStatus }) => {
  return (
    <Formik
      initialValues={{ emailAddress: '', comments: '' }}
      onSubmit={(values, actions) => {
        try {
          submitFeedback(values);
          actions.setSubmitting(false);
          setSentStatus(true);
        } catch (e) {
          console.error(e);
        }
      }}
      render={props => (
        <Form onSubmit={props.handleSubmit}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="E-Mail"
                variant="outlined"
                type="email"
                name="emailAddress"
                required
                value={props.values.emailAddress}
                error={
                  props.errors.emailAddress &&
                  props.errors.emailAddress.length &&
                  props.touched.emailAddress
                }
                onChange={props.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                type="text"
                variant="outlined"
                multiline={true}
                rows={3}
                rowsMax={6}
                name="comments"
                required
                value={props.values.comments}
                error={
                  props.errors.comments &&
                  props.errors.comments.length &&
                  props.touched.comments
                }
                onChange={props.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

const ThankYouNote = () => (
  <Typography paragraph>Thank you for your feedback!</Typography>
);

const FeedbackModal = ({ title, description, handleCancel }) => {

  const [sentStatus, setSentStatus] = useState(false);

  return (
    <Dialog
      open={true}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography paragraph id="alert-dialog-description" variant="subtitle1">
          {description}
        </Typography>
        {sentStatus ? (
          <ThankYouNote />
        ) : (
            <FeedbackForm setSentStatus={setSentStatus} />
          )}
      </DialogContent>
    </Dialog>
  );
};

FeedbackModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired
};

const mapStateToProps = () => {

  // NOTE: You can use standard variables for static content
  //             mapStateToProps is for pulling values from the connected redux store.
  const title = 'Feedback';
  const description =
    'Is there something that went wrong? Let us know so we can improve.';

  return {
    title,
    description
  };
};

const actions = {
  handleCancel: closeModal
};

export default connect(
  mapStateToProps,
  actions
)(FeedbackModal);
