import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  media: {
    height: 182,
    backgroundColor: theme.palette.neutral.dark.color,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
});

const GettingStartedCardIconMedia = ({
  classes,
  icon,
}) => (
  <div className={classes.media}>
    {icon}
  </div>
);

GettingStartedCardIconMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node.isRequired,
};

export default withStyles(styles)(GettingStartedCardIconMedia);
