import { createSelector } from 'reselect';

import { getCurrentRobotPartParent } from './getCurrentRobotPartParent';

const defaultOffset = {
  rotation: {
    x: 0,
    y: 0,
    z: 0,
  },
  translation: {
    x: 0,
    y: 0,
    z: 0,
  },
};

// TODO: Update this to getCurrentRobotPartConstraints?
export const getCurrentRobotPartParentOffsets = createSelector(
  getCurrentRobotPartParent,
  parentRobotPart => {
    if (!parentRobotPart || !parentRobotPart.part) {
      return defaultOffset;
    }

    const attachments = parentRobotPart.part.attachments.toModelArray();

    if (!attachments || !attachments.length) {
      return defaultOffset;
    }

    // TODO: What do we do if there are more than 1 attachment points?
    const attachment = attachments[0];

    const parentPartCategory = parentRobotPart.part.partCategory.name;

    switch (parentPartCategory) {
      case 'Link':
        const offset = getLinkOffsets(parentRobotPart);
        return {
          ...offset,
          boltPattern: attachment.boltPattern.name,
        };
      default:
        return {
          rotation: attachment.childRotationOffset,
          translation: attachment.childTranslationOffset,
          boltPattern: attachment.boltPattern.name,
        };
    }
  }
);

const getLinkOffsets = (linkRobotPart) => {
  const twist = linkRobotPart.twist || 0;

  return {
    rotation: {
      x: 0,
      y: -0.0175 * Math.sin(twist),
      z: 0.0175 * (1 + Math.cos(twist)),
    },
    translation: {
      x: 0,
      y: 0,
      z: 0,
    },
  };
};
