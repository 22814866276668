import * as catalogActions from '../../catalog/actions';
import * as configurationActions from '../../configurator/actions';

import { createLoadingSelector } from './createLoadingSelector';

const allActions = {
  ...catalogActions,
  ...configurationActions,
};

const actionsToMonitor = Object.entries(allActions).reduce((result, [name, value]) => {
  if (name && name.length && name.endsWith('PREFIX')) {
    result.push(value);
  }

  return result;
}, []);

export const getApplicationIsLoading = createLoadingSelector(actionsToMonitor);
