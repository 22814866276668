import axios from 'axios';

import { REACT_APP_HEBI_API_BASE_URL } from '../app/config/env-config';

export default axios.create({
  baseURL: REACT_APP_HEBI_API_BASE_URL,
  timeout: 20000,
  headers: {
    'Accept': 'application/json',
  },
});