import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
  container: {
    display: 'flex',
    position: 'absolute',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    zIndex: theme.zIndex.workspace + 1,
    top: theme.spacing.unit * 2,
    pointerEvents: 'none',
  },
  instructionIcon: {
    margin: theme.spacing.unit * 1.5,
    color: theme.palette.neutral.darkest.color,
  },
  instructionText: {
    alignSelf: 'center',
    marginRight: theme.spacing.unit * 2.5,
    color: theme.palette.neutral.darkest.color
  },
});

const WorkspaceInstructions = ({
  classes,
  instructions,
}) => (
  <div className={classes.container}>
    {instructions.map(({ icon, instruction }) => (
      <React.Fragment key={icon}>
        <Icon className={classes.instructionIcon}>
          {icon}
        </Icon>
        <Typography variant="caption" className={classes.instructionText}>
          {instruction}
        </Typography>
      </React.Fragment>
    ))}
  </div>
);

WorkspaceInstructions.propTypes = {
  classes: PropTypes.object.isRequired,
  instructions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    instruction: PropTypes.string.isRequired,
  })).isRequired,
};

export default withStyles(styles)(WorkspaceInstructions);
