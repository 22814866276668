import {
  ROBOT_CREATE_WITH_PART_SUCCESS,
} from './createRobotWithPartRequest';

export const createRobotWithPartSuccess = (id) => ({
  type: ROBOT_CREATE_WITH_PART_SUCCESS,
  payload: {
    id,
  }
});
