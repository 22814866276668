import { createPartGroup as createLinkPartGroup } from './link';
import { createPartGroup as createGenericPartGroup } from './default';

export default {
  Link: {
    createPartGroup: createLinkPartGroup,
  },
  default: {
    createPartGroup: createGenericPartGroup,
  },
}
