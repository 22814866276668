import SceneManager from './SceneManager';

export default canvas => {
  const sceneManager = new SceneManager(canvas);
  const isTouchDevice = 'ontouchstart' in document.documentElement;

  bindEventListeners();

  function bindEventListeners() {
    if (isTouchDevice) {
      canvas.addEventListener('touchstart', touchStart);
      canvas.addEventListener('touchmove', touchMove);
      canvas.addEventListener('touchend', touchEnd);
    } else {
      canvas.addEventListener('mousedown', mouseDown);
      canvas.addEventListener('mousemove', mouseMove);
      canvas.addEventListener('mouseup', mouseUp);
    }

    canvas.addEventListener('wheel', wheel);


    //TODO: for HEBI's debug keybindings
    document.addEventListener('keydown', keyPress);
  }

  function touchStart(e) {
    if (!e.touches || e.touches.length !== 1) {
      return;
    }

    const touch = e.touches[0];
    sceneManager.onTouchStart(touch.pageX, touch.pageY);
  }

  function touchMove(e) {
    if (!e.touches || e.touches.length !== 1) {
      return;
    }
    const touch = e.touches[0];
    sceneManager.onMouseMove(touch.pageX, touch.pageY);
  }

  function touchEnd(e) {
    if (!e.touches || e.touches.length !== 1) {
      return;
    }
    const touch = e.touches[0];
    sceneManager.onMouseUp(touch.pageX, touch.pageY);
  }

  function wheel(e) {} // The three orbit controls handle everything for now.

  function mouseDown(e) {
    e = e || window.event; // support Internet Explorer
    const { clientX, clientY, which } = e;
    const isLeftClick = which === 1;

    if (isLeftClick) {
      sceneManager.onMouseDown(clientX, clientY);
    }
  }

  function mouseMove({ clientX, clientY }) {
    sceneManager.onMouseMove(clientX, clientY);
  }

  function mouseUp(e) {
    e = e || window.event; // support Internet Explorer
    const { clientX, clientY } = e;
    sceneManager.onMouseUp(clientX, clientY);
  }

  function keyPress(key) {
    sceneManager.onKeyPress(key.which);
  }

  function render() {
    sceneManager.render();
  }

  function resetCamera() {
    sceneManager.resetCamera();
  }

  async function addPart({ part, userData }) {
    await sceneManager.addPart(part, userData);
  }

  function activateTestDrive(parentPartId) {
    sceneManager.activateTestDrive(parentPartId);
  }

  function deactivateTestDrive() {
    sceneManager.deactivateTestDrive();
  }

  function disableBuildingControls() {
    sceneManager.disableBuildingControls();
  }

  function clearScene() {
    sceneManager.clearWorkspace();
  }

  function removeRobotParts(robotParts) {
    sceneManager.removeRobotParts(robotParts);
  }

  function updatePartConstraint(id, constraint, value) {
    sceneManager.updatePartConstraint(id, constraint, value);
  }

  async function replacePart(partToReplace, addPartArgs) {
    await sceneManager.replacePart(partToReplace, addPartArgs);
  }

  return {
    addPart,
    activateTestDrive,
    deactivateTestDrive,
    disableBuildingControls,
    clearScene,
    render,
    removeRobotParts,
    replacePart,
    resetCamera,
    updatePartConstraint,
  }
}
