import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { getApplicationIsLoading } from '../../selectors';

const styles = theme => ({
  container: {
    zIndex: theme.zIndex.appBar,
  },
});

const ProgressBar = ({
  classes,
  isLoading,
}) => (
  <div
    className={classes.container}
    style={{ visibility: isLoading ? 'visible' : 'hidden' }}
  >
    <LinearProgress color="secondary" />
  </div>
);

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading: getApplicationIsLoading(state),
});

export default withStyles(styles)(
  connect(mapStateToProps)(ProgressBar)
);
