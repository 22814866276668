import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorIcon from '@material-ui/icons/Error';
import TextField from '@material-ui/core/TextField';
import { Form } from 'formik';
import FormikPersist from '../../shared/FormikPersist';

import Autocomplete from '../../shared/Autocomplete';

const styles = theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing.unit * 16,
      paddingRight: theme.spacing.unit * 16,
    },
  },
  buttonGroup: {
    textAlign: 'center',
  },
  alignBottom: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  errorIcon: {
    color: theme.palette.status.danger.color,
  },
});

const RequestQuoteForm = ({
  classes,
  countries,
  isSubmitting,
  isValid,
  errors,
  touched,
  handleSubmit,
  handleBlur,
  handleChange,
  setFieldTouched,
  setFieldValue,
  setStatus,
  setFieldError,
  status,
  values,
}) => (
    <Form className={classes.container}>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            type="text"
            label="Name"
            fullWidth
            error={errors.name && errors.name.length && touched.name}
            helperText={errors.name && errors.name.length && touched.name ? errors.name : 'Required'}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            InputProps={{
              endAdornment:
                errors.name && errors.name.length && touched.name &&
                <InputAdornment>
                  <ErrorIcon className={classes.errorIcon} />
                </InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="emailAddress"
            type="email"
            label="Email"
            error={errors.emailAddress && errors.emailAddress.length && touched.emailAddress}
            fullWidth
            helperText={errors.emailAddress && errors.emailAddress.length && touched.emailAddress ? errors.emailAddress : 'Required'}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.emailAddress}
            InputProps={{
              endAdornment:
                errors.emailAddress && errors.emailAddress.length && touched.emailAddress &&
                <InputAdornment>
                  <ErrorIcon className={classes.errorIcon} />
                </InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>

          <Autocomplete
            options={countries}
            value={values.country}
            onChange={option => {
              setFieldTouched('country', true);
              setFieldValue('country', option);
              if (option == null || option === undefined || option === '') 
                setFieldError('country', 'Required');
              else 
                setFieldError('country', null);
              
              setStatus({ ...status, shrink: Boolean(option && option.value && option.value.length) });
            }}
            helperText={'Required'}
            placeholder={null}
            textFieldAdornment={
                errors.country && errors.country.length && touched.country &&
                  <InputAdornment>
                    <ErrorIcon className={classes.errorIcon} />
                  </InputAdornment>
              }
            textFieldProps={{
              label: "My country",
              InputLabelProps: status && status.shrink ? { shrink: true } : {},
              error: errors.country && errors.country.length && touched.country, 
              helperText: 'Required',
            }}
          />
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.contactViaPhone}
                  name="contactViaPhone"
                  onChange={(e, checked) => {
                    setFieldTouched('contactViaPhone', true);
                    setFieldValue('contactViaPhone', checked);
                    setStatus({ ...status, phoneNumberRequired: checked });

                    if (!checked) {
                      setFieldTouched('phoneNumber', false);
                      setFieldValue('phoneNumber', '');
                    }
                  }}
                  color="primary"
                />
              }
              label="I have some questions. Please contact me via phone."
            />
          </Grid>
          {
            status && status.phoneNumberRequired &&
            <Grid item xs={12}>
              <TextField
                name="phoneNumber"
                label="Phone number"
                fullWidth
                error={errors.phoneNumber && errors.phoneNumber.length && touched.phoneNumber}
                helperText="Required"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                InputProps={{
                  endAdornment:
                    errors.phoneNumber && errors.phoneNumber.length && touched.phoneNumber &&
                    <InputAdornment>
                      <ErrorIcon className={classes.errorIcon} />
                    </InputAdornment>,
                }}
              />
            </Grid>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="fieldOfResearch"
            label="My field of research"
            error={errors.researchField && errors.researchField.length && touched.researchField}
            fullWidth
            helperText="Optional"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.fieldOfResearch}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.alignBottom}>
          <FormControlLabel
            control={
              <Checkbox
                name="includeAssembly"
                color="primary"
                onChange={handleChange}
                checked={values.includeAssembly}
              />
            }
            label="Please include quote for HEBI Robotics to assemble"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="comments"
            label="Comments"
            multiline
            rows="4"
            helperText="Additional requests, accessories, special delivery needs, etc."
            error={errors.comments && errors.comments.length && touched.comments}
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.comments}
          />
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={12} className={classes.buttonGroup}>
          <Button
            disabled={isSubmitting || !isValid}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Please contact me with a quote
        </Button>
        </Grid>
      </Grid>
      <FormikPersist name="request-quote-form" />
    </Form>
  );

RequestQuoteForm.propTypes = {
  classes: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.object,
  values: PropTypes.object.isRequired,
};

export default withStyles(styles)(RequestQuoteForm);
