export const SUBMIT_FEEDBACK_PREFIX = 'SUBMIT_FEEDBACK';
export const SUBMIT_FEEDBACK_REQUEST = `${SUBMIT_FEEDBACK_PREFIX}_REQUEST`;
export const SUBMIT_FEEDBACK_SUCCESS = `${SUBMIT_FEEDBACK_PREFIX}_SUCCESS`;
export const SUBMIT_FEEDBACK_SUCCESS_MESSAGE = 'Your feedback has been submitted!';
export const SUBMIT_FEEDBACK_ERROR = `${SUBMIT_FEEDBACK_PREFIX}_ERROR`;

export const SUBMIT_FEEDBACK_ERROR_MESSAGE = `
  We are unable to submit your feedback.
  Please try again. If the problem persists, please visit our contact us
  page for other ways to submit this feedback.
`;

export const submitFeedbackRequest = (feedback, {
  resetForm,
  setSubmitting,
}) => ({
  type: SUBMIT_FEEDBACK_REQUEST,
  payload: {
    feedback,
    resetForm,
    setSubmitting,
  }
});
