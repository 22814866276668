import {
  ROBOT_LOAD_SUCCESS,
} from './loadRobotRequest';

export const loadRobotSuccess = (robot) => ({
  type: ROBOT_LOAD_SUCCESS,
  payload: {
    robot,
  },
});
