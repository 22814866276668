import {
  attr,
  fk,
  many,
  Model,
} from 'redux-orm';

import omit from 'lodash-es/omit';

class RobotPart extends Model {
  static get fields() {
    return {
      id: attr(),
      robot: fk('Robot'),
      part: fk('Part'),
      length: attr(),
      twist: attr(),
      children: many('RobotPart', 'parent'),
    };
  }
  
  static parse(robotPartData) {
    const {
      RobotPart,
    } = this.session;

    robotPartData.children = robotPartData.children || [];

    const parsedData = {
      ...omit(robotPartData, [
        'boltPatternId',
        'childRotationOffset',
        'childTranslationOffset',
        'partName',
        'partType',
        'partTypeHRDFName',
        'children',
      ]),
      children: robotPartData.children.map((robotPart) => RobotPart.parse(robotPart)),
      part: robotPartData.partId,
      robot: robotPartData.robotId,
    };

    return this.upsert(parsedData);
  }
  
  toJSON() {
    const part = {
      ...this.ref,
      children: this.children.toModelArray().map(child => child.toJSON())
    };
  
    return part;
  }

  updateFrom(otherPart) {
    this.update(otherPart.ref);
  }
}
  
RobotPart.modelName = 'RobotPart';
  
export default RobotPart;
  