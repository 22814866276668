export const ROBOT_LOAD_PREFIX = 'ROBOT_LOAD';
export const ROBOT_LOAD_REQUEST = `${ROBOT_LOAD_PREFIX}_REQUEST`;
export const ROBOT_LOAD_SUCCESS = `${ROBOT_LOAD_PREFIX}_SUCCESS`;
export const ROBOT_LOAD_ERROR = `${ROBOT_LOAD_PREFIX}_ERROR`;
export const ROBOT_LOAD_ERROR_MESSAGE = 'Error loading robot';

export const loadRobotRequest = (id) => ({
  type: ROBOT_LOAD_REQUEST,
  payload: {
    id,
  },
});
