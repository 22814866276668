import {
  attr,
  fk,
  Model,
} from 'redux-orm';

class Attachment extends Model {
  static get fields() {
    return {
      id: attr(),
      childTranslationOffset: attr(),
      childRotationOffset: attr(),
      twistInterval: attr(),
      boltPattern: fk('BoltPattern'),
    };
  }

  static parse(attachmentData) {
    const parsedData = {
      ...attachmentData,
      boltPattern: attachmentData.boltPatternId,
    };

    return this.upsert(parsedData);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherAttachment) {
    this.update(otherAttachment.ref);
  }
}

Attachment.modelName = 'Attachment';

export default Attachment;
