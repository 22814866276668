export const ROBOT_PART_UPDATE_CONSTRAINT_PREFIX = 'ROBOT_PART_UPDATE_CONSTRAINT';
export const ROBOT_PART_UPDATE_CONSTRAINT_REQUEST = `${ROBOT_PART_UPDATE_CONSTRAINT_PREFIX}_REQUEST`;
export const ROBOT_PART_UPDATE_CONSTRAINT_SUCCESS = `${ROBOT_PART_UPDATE_CONSTRAINT_PREFIX}_SUCCESS`;
export const ROBOT_PART_UPDATE_CONSTRAINT_ERROR = `${ROBOT_PART_UPDATE_CONSTRAINT_PREFIX}_ERROR`;
export const ROBOT_PART_UPDATE_CONSTRAINT_ERROR_MESSAGE = 'Error updating robot part configuration';

export const updateSelectedRobotPartConstraintRequest = (constraint, value) => ({
  type: ROBOT_PART_UPDATE_CONSTRAINT_REQUEST,
  payload: {
    constraint,
    value,
  },
});
