import {
  attr,
  fk,
  many,
  Model,
} from 'redux-orm';

import omit from 'lodash-es/omit';
import { REACT_APP_HEBI_API_BASE_URL } from '../app/config/env-config';

class Part extends Model {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      description: attr(),
      minLength: attr(),
      maxLength: attr(),
      defaultLength: attr(),
      twistInterval: attr(),
      thumbnailId: attr(),
      thumbnailUrl: attr(),
      inputBoltPattern: fk('BoltPattern'),
      partCategory: fk('PartCategory'),
      assets: many('Asset', 'parts'),
      attachments: many('Attachment', 'parts'),
    };
  }

  static parse(partData) {
    const {
      Asset,
      Attachment,
    } = this.session;

    partData.assets = partData.assets || [];
    partData.attachments = partData.attachments || [];

    const parsedData = {
      ...omit(partData, [
        'category',
        'assets',
        'attachments',
        'inputBoltPatternId',
        'partCategoryId',
        'hrdfNodeName',
      ]),
      assets: partData.assets.map(({asset}) => Asset.parse(asset)),
      attachments: partData.attachments.map((partAttachmentEntry) => Attachment.parse(partAttachmentEntry)),
      inputBoltPattern: partData.inputBoltPatternId,
      partCategory: partData.partCategoryId,
      thumbnailUrl: REACT_APP_HEBI_API_BASE_URL + `thumbnail/${partData.thumbnailId}`,
    };

    return this.upsert(parsedData);
  }

  toJSON() {
    const part = {
      ...this.ref,
      assets: this.assets.toModelArray().map(asset => asset.toJSON()),
      attachments: this.attachments.toModelArray().map(attachment => attachment.toJSON()),
      partCategory: this.partCategory.name,
    };
  
    return part;
  }

  updateFrom(otherPart) {
    this.update(otherPart.ref);
  }
}

Part.modelName = 'Part';

export default Part;
