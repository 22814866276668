import { createSelector } from 'reselect';

import { getEntitiesSession } from '../../entities/selectors';
import { getSelectedRobotPartId } from './getSelectedRobotPartId';

export const getSelectedRobotPart = createSelector(
  [getEntitiesSession, getSelectedRobotPartId],
  (session, selectedRobotPartId) => {
    const { RobotPart } = session;

    if (!selectedRobotPartId || !RobotPart.idExists(selectedRobotPartId)) {
      return null;
    }
    
    const robotPart = RobotPart.withId(selectedRobotPartId);
    return robotPart;
  }
);
