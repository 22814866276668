import storage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import { createMigrate } from 'redux-persist';

const migrations = {
  2: (state) => {
    if(state.entities && state.entities.Part.count() === 25) {
      return {
        ...state
      }
    }

    return {
      ...state,
      configurator: {
        bootstrapper: {
          initialized: false
        }
      }
    }
  }
}

const persistConfig = {
  key: 'HEBI-RobotBuilder',
  version: 2,
  storage,
  blacklist: [
    'loading',
    'modals',
    'popper',
  ],
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate( migrations, {debug: false }),
  transforms: [
    createWhitelistFilter('entities'),
    createWhitelistFilter('catalog', [
      'selectedCategoryId',
      'componentDrawerVisible',
    ]),
    createWhitelistFilter('configurator', [
      'bootstrapper.initialized',
    ]),
  ],
};

export default persistConfig;
