export const ROBOT_UPLOAD_HRDF_PREFIX = 'ROBOT_UPLOAD_HRDF';
export const ROBOT_UPLOAD_HRDF_REQUEST = `${ROBOT_UPLOAD_HRDF_PREFIX}_REQUEST`;
export const ROBOT_UPLOAD_HRDF_SUCCESS = `${ROBOT_UPLOAD_HRDF_PREFIX}_SUCCESS`;
export const ROBOT_UPLOAD_HRDF_ERROR = `${ROBOT_UPLOAD_HRDF_PREFIX}_ERROR`;
// TODO: Actually use the error from the server
export const ROBOT_UPLOAD_HRDF_ERROR_MESSAGE = 'Error loading HRDF file';

export const uploadHRDFRequest = (file) => ({
  type: ROBOT_UPLOAD_HRDF_REQUEST,
  payload: {
    file,
  },
});
