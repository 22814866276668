import { createSelector } from 'reselect';

import { getSelectedRobotPartChildren } from './getSelectedRobotPartChildren';

export const getShouldWarnBeforeRobotPartRemoval = createSelector(
  getSelectedRobotPartChildren,
  children => {
    if (children && children.length) {
      return true;
    }

    return false;
  }
);
