export const ROBOT_PART_REPLACE_PREFIX = 'ROBOT_PART_REPLACE';
export const ROBOT_PART_REPLACE_REQUEST = `${ROBOT_PART_REPLACE_PREFIX}_REQUEST`;
export const ROBOT_PART_REPLACE_SUCCESS = `${ROBOT_PART_REPLACE_PREFIX}_SUCCESS`;
export const ROBOT_PART_REPLACE_ERROR = `${ROBOT_PART_REPLACE_PREFIX}_ERROR`;
export const ROBOT_PART_REPLACE_ERROR_MESSAGE = 'Error while initializing configurator';

export const replaceRobotPartRequest = (replaceId, newPart) => ({
  type: ROBOT_PART_REPLACE_REQUEST,
  payload: {
    replaceId,
    newPart,
  },
});
