import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import ClearIndicator from './ClearIndicator';
import Control from './Control';
import DropdownIndicator from './DropdownIndicator';
import IndicatorsContainer from './IndicatorsContainer';
import MenuList from './MenuList';
import MultiValue from './MultiValue';
import NoOptionsMessage from './NoOptionsMessage';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';

// Implements react-select for autocomplete
// Uses react-window for virtualizing long lists to improve performance
// Inspired by https://material-ui.com/demos/autocomplete/
const styles = theme => ({
  divider: {
    height: theme.spacing.unit * 2,
  },
});

const components = {
  ClearIndicator,
  DropdownIndicator,
  Control,
  IndicatorsContainer,
  MenuList: MenuList(),
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const Autocomplete = ({
  classes,
  theme,
  onChange,
  options,
  placeholder,
  value,
  ...rest
}) => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <Select
      classes={classes}
      styles={selectStyles}
      options={options}
      components={components}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isClearable
      {...rest}
    />
  );
};

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default withStyles(styles, { withTheme: true })(Autocomplete);
