import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
});

const NoOptionsMessage = ({
  children,
  classes,
}) => (
  <Typography
    color="textSecondary"
    className={classes.noOptionsMessage}
  >
    {children}
  </Typography>
);

NoOptionsMessage.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoOptionsMessage);
