import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';

import { getSelectedRobotPartConstraints } from '../../selectors';
import { getPartLengths } from '../../../entities/selectors';

import { standardizeLength } from '../../../../common/utils/length-utils';

import {
  updateSelectedRobotPartConstraintRequest,
} from '../../actions';

const styles = theme => ({
  slider: {
    padding: '26px 0px',
  },
  radioGroup: {
    justifyContent: 'space-between',
  },
  radio: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    }
  },
  checked: {},
});

/**
 * Note that the length offset below removes the length of the two end caps for display purposes only.
 * For example, although an X5 Link's total length may be 325mm, the tube length is only 300mm.
 * However, the kinematic solver and HRDF upload process require the full link length.
 * http://docs.hebi.us/hardware.html#x5-link
 */
const END_CAPS_LENGTH_OFFSET = 0.025;

const LinkDialogContent = ({
  classes,
  handleConstraintChanged,
  length,
  lengthOptions,
  twist,
  twistInterval,
}) => (
  <React.Fragment>
    {Boolean(lengthOptions && lengthOptions.length) && (
      <FormControl component="fieldset" margin="dense">
        <FormLabel>Length (mm)</FormLabel>

        <RadioGroup
          value={length.toString()}
          onChange={event => handleConstraintChanged('length', Number(event.target.value))}
          row={true}
          classes={{
            root: classes.radioGroup,
          }}
        >
          {lengthOptions.map(option => (
            <FormControlLabel
              key={option.id}
              value={option.length.toString()}
              control={
                <Radio
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              label={standardizeLength(option.length - END_CAPS_LENGTH_OFFSET, option.unit)}
              labelPlacement="end"
            />
          ))}
        </RadioGroup>
      </FormControl>
    )}
    
    <FormControl component="fieldset" margin="none">
      <FormLabel>Twist Interval (degrees)</FormLabel>
      <RadioGroup
        value={Math.round(twist/twistInterval).toString()}
        onChange={event => handleConstraintChanged('twist', Number(event.target.value*twistInterval))}
        row={true}
        classes={{
          root: classes.radioGroup,
        }}
      >
        {[...Array(Math.round(Math.PI * 2 / twistInterval)).keys()].map(option => (
          <FormControlLabel
            key={option.id}
            value={option.toString()}
            control={
              <Radio
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
              />
            }
            label={(90*option).toString()+'°'}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </FormControl>
  </React.Fragment>
);

LinkDialogContent.propTypes = {
  handleConstraintChanged: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  lengthOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
  })).isRequired,
  twist: PropTypes.number.isRequired,
  twistInterval: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  const constraints = getSelectedRobotPartConstraints(state);
  const { length, twist, twistInterval } = constraints;
  const lengthOptions = getPartLengths(state);

  return {
    length,
    twist,
    twistInterval,
    lengthOptions,
  };
};

const actions = {
  handleConstraintChanged: updateSelectedRobotPartConstraintRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, actions)(LinkDialogContent)
);
