import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const GettingStartedCardActions = ({
  confirmButtonText,
  confirmButtonDisabled,
  onClick,
}) => (
  <Button
    size="small"
    color="primary"
    onClick={onClick}
    disabled={confirmButtonDisabled}
  >
    {confirmButtonText}
  </Button>
);

GettingStartedCardActions.defaultProps = {
  confirmButtonDisabled: false,
};

GettingStartedCardActions.propTypes = {
  confirmButtonText: PropTypes.string.isRequired,
  confirmButtonDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default GettingStartedCardActions;
