import {
  ROBOT_PART_UPDATE_CONSTRAINT_SUCCESS,
} from './updateSelectedRobotPartConstraintRequest';

export const updateSelectedRobotPartConstraintSuccess = (id, constraint, value) => ({
  type: ROBOT_PART_UPDATE_CONSTRAINT_SUCCESS,
  payload: {
    id,
    constraint,
    value,
  },
});
