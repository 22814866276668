import * as THREE from 'three';

export const createPartGroup = (meshes, userData) => {
  const group = createGroup();

  group.userData = {
    ...userData,
    addChild,
    applyTransformation,
    getChild,
    getChildMeshes,
    removeChild,
    updateLength,
    updateTwist,
    addInterfaceItem,
  };

  return group;

  function createGroup() {
    if (meshes.length !== 3) {
      throw new Error('Link must have three meshes.');
    }

    const linkGroup = new THREE.Group();
    const [spacedCap, tubeMesh, tightCap] = meshes;

    const beginCap = spacedCap.clone();
    const endCap = tightCap.clone();
    const tube = tubeMesh.clone();

    const tubeGroup = new THREE.Group();
    tubeGroup.add(tube);

    const endCapGroup = new THREE.Group();
    endCapGroup.add(endCap);
    const childGroup = new THREE.Group();
    endCapGroup.add(childGroup);

    const endGroup = new THREE.Group();
    endGroup.add(endCapGroup);

    tubeGroup.add(endGroup);
  
    linkGroup.add(beginCap);
    linkGroup.add(tubeGroup);

    return linkGroup;
  }

  /**
   * Traverses all components in the link, retrieves their correct offsets, and applies them
   */
  function applyTransformation() {
    const offsets = getAdjustedOffsets();
    const components = getComponents();

    Object.entries(offsets).forEach(([componentName, offset]) => {
      const component = components[componentName];

      if (component) {
        Object.entries(offset).forEach(([offsetType, offsetValues]) => {
          Object.entries(offsetValues).forEach(([axis, value]) => {
            component[offsetType][axis] = value;
          });
        });
      }
    });

    // Ensure that the end cap is centered around the tube so that it rotates correctly
    const { endCapMesh } = components;
    endCapMesh.geometry.center();
  }

  /**
   * After scaling the tube linearly, the tube must be repositioned to sit in its original position.
   * This equation was derived by manually applying offsets, plotting the resulting points, and determining
   * the best fit line, therefore it may not be 100% accurate for longer tube lengths.
   * @param {number} tubeLength 
   */
  function getTubePositionByTubeLength(tubeLength) {
    const tubePosition = 0.4986486 * tubeLength + 0.0130945;
    return tubePosition;
  }

  function getAdjustedOffsets() {
    const { length } = group.userData;
    const tubeLength = length;
    const tubePosition = getTubePositionByTubeLength(tubeLength);

    const defaultOffsets = {
      tubeMesh: {
        scale: {
          z: tubeLength,
        },
      },
      tubeGroup: {
        rotation: {
          y: THREE.Math.degToRad(90),
        },
        position: {
          x: tubePosition,
          z: 0.02,
        },
      },
      endGroup: {
        rotation: {
          y: THREE.Math.degToRad(90),
        },
        position: {
          z: tubePosition - 0.006,
        },
      },
      childGroup: {
        position: {
          z: -0.018, // adjust for thickness of end cap
        },
        rotation: {
          y: THREE.Math.degToRad(180), // orient correctly
        },
      },
    };

    return defaultOffsets;
  }

  function getComponents() {
    const beginCapMesh = group.children[0];
    const tubeGroup = group.children[1];
    const tubeMesh = tubeGroup.children[0];
    const endGroup = tubeGroup.children[1];
    const endCapGroup = endGroup.children[0];
    const endCapMesh = endCapGroup.children[0];
    const childGroup = endCapGroup.children[1];

    return {
      beginCapMesh,
      tubeGroup,
      tubeMesh,
      endCapGroup,
      endCapMesh,
      endGroup,
      childGroup,
    };
  }

  function updateLength(length) {
    group.userData.length = length;

    const {
      endGroup,
      tubeGroup,
      tubeMesh,
    } = getComponents();

    const {
      endGroup: endGroupOffset,
      tubeGroup: tubeGroupOffset,
    } = getAdjustedOffsets();

    tubeMesh.scale.z = length; // scale tube by given length
    tubeGroup.position.x = tubeGroupOffset.position.x; // reposition tube group so that its origin remains the same
    endGroup.position.z = endGroupOffset.position.z; // move end group so that it aligns with tube
  }

  function updateTwist(twist) {
    group.userData.twist = twist;

    const { endCapGroup } = getComponents();
    endCapGroup.rotation.x = twist;
  }

  function addChild(child) {
    const { childGroup } = getComponents();
    childGroup.add(child);
  }

  function removeChild(child) {
    const { childGroup } = getComponents();
    childGroup.remove(child);
  }

  function getChild() {
    const { childGroup } = getComponents();
    if (!childGroup.children || !childGroup.children.length) {
      return null;
    }

    return childGroup.children[0];
  }

  function getChildMeshes() {
    const { beginCapMesh, tubeMesh, endCapMesh } = getComponents();

    return [
      beginCapMesh,
      tubeMesh,
      endCapMesh,
    ];
  }

  function addInterfaceItem(interfaceItem) {

    if(group.userData.attachments && group.userData.attachments.length) {
      const { childTranslationOffset, childRotationOffset } = group.userData.attachments[0];
      const { childGroup } = getComponents();
      
      childGroup.add(interfaceItem);

      interfaceItem.position.set(childTranslationOffset.x, childTranslationOffset.y, childTranslationOffset.z);
      interfaceItem.rotation.set(childRotationOffset.x, childRotationOffset.y, childRotationOffset.z, 'XYZ');
    }
  }
}
