export const formatNumber = (value) => {
  value = value || 0;
  const minimumFractionDigits = 2;
  const maximumFractionDigits = 2;

  const formattedValue = value.toLocaleString(undefined, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formattedValue;
};
