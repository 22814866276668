import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiThemeProvider } from '@material-ui/core/styles';

import ExpansionPanel from './ExpansionPanel';
import ExpansionPanelSummary from './ExpansionPanelSummary';

import darkTheme from '../../../../common/themes/material-dark-theme';
import { formatNumber } from '../../../../common/utils/formatters';

import {
  getRobotStatistics,
} from '../../selectors';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: theme.spacing.unit * 3,
    width: '280px',
    zIndex: theme.zIndex.appBar,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    whiteSpace: 'pre',
  },
});

class TestDriveSpecifications extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      expanded: false,
    };
  }

  toggleExpanded() {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  }

  formatLabel(key, value) {
    let formattedValue = '';
    let formattedKey = '';
    value = value || 0;

    switch (key) {
      case 'maxHorizontalReach':
        formattedKey = 'Maximum horizontal reach';
        formattedValue = `${formatNumber(value * 1000)} mm`;
        break;
      case 'continuousPayload':
        formattedKey = 'Continuous payload at max reach';
        formattedValue = `${formatNumber(value)} kg`;
        break;
      case 'peakPayload':
        formattedKey = 'Peak payload at max reach';
        formattedValue = `${formatNumber(value)} kg`;
        break;
      case 'mass':
        formattedKey = 'Mass'
        formattedValue = `${formatNumber(value)} kg`;
        break;
      case 'continuousPower24V':
        formattedKey = 'Continuous power draw (estimated)';
        formattedValue = `${formatNumber(value)} W`;
        break;
      case 'peakPower24V':
        formattedKey = 'Peak power draw';
        formattedValue = `${formatNumber(value)} W`;
        break;
      default:
        break;
    }

    return `${formattedKey}\n${formattedValue}`;
  }
  
  render() {
    const { classes, statistics } = this.props;

    return (
      <MuiThemeProvider theme={darkTheme}>
        <ExpansionPanel
          className={classes.container}
          expanded={this.state.expanded}
          onChange={this.toggleExpanded}
          elevation={10}
        >
          <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Technical Specs</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.details}>
              {statistics &&
                Object.entries(statistics).map(([key, value]) => (
                  <Typography
                    key={key}
                    variant="body2"
                    className={classes.label}
                    gutterBottom
                  >
                    {this.formatLabel(key, value)}
                  </Typography>
                ))
              }
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </MuiThemeProvider>
    );
  }
}

TestDriveSpecifications.propTypes = {
  classes: PropTypes.object.isRequired,
  statistics: PropTypes.object,
};

const mapStateToProps = state => {
  const statistics = getRobotStatistics(state);

  return {
    statistics,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(TestDriveSpecifications)
);
