import { createMuiTheme } from '@material-ui/core/styles';

import {
  typography,
  zIndex,
} from './variables';

let theme = createMuiTheme({
  typography: {
    ...typography,
    color: '#474642',
  },
  zIndex,
  palette: {
    primary: {
      light: '#FF5D35',
      main: '#C82506',
      dark: '#8F0000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9cffe1',
      main: '#64F8AF',
      dark: '#1dc47f',
      contrastText: '#1D1D1D',
    },
    neutral: {
      light: {
        color: '#F1F1EF',
        contrastText: '#1D1D1D',
      },
      main: {
        color: '#D3D3CE',
        contrastText: '#1D1D1D',
      },
      dark: {
        color: '#B5B4AE',
        contrastText: '#1D1D1D',
      },
      darker: {
        color: '#6F6E68',
        contrastText: '#FFFFFF',
      },
      darkest: {
        color: '#474642',
        contrastText: '#FFFFFF',
      },
      workspace: {
        color: '#919191',
        contrastText: '#FFFFFF',
      },
    },
    accent: {
      accent1: {
        color: '#DCD521',
        contrastText: '#1D1D1D'
      },
      accent2: {
        color: '#F79205',
        contrastText: '#1D1D1D'
      },
      accent3: {
        color: '#05CE0B',
        contrastText: '#1D1D1D'
      },
    },
    status: {
      danger: {
        color: '#F80B0B',
        contrastText: '#1D1D1D',
      },
      warning: {
        color: '#F79205',
        contrastText: '#1D1D1D',
      },
      success: {
        color: '#05CE0B',
        contrastText: '#1D1D1D',
      },
    },
    text: {
      primary: '#474642',
      secondary: '#C82506',
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiAppBar: {
      colorDefault: theme.palette.neutral.darker,
      root: {
        backgroundColor: 'white',
      },
    },
    MuiFormLabel: {
      root: {
        color: theme.palette.neutral.darkest.color,
      },
    },
    MuiFormHelperText: {
      root: {
        color: theme.palette.neutral.darkest.color,
      },
    }
  },
};

export default theme;
