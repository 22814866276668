import * as THREE from 'three';

import EffectComposer from '../../../features/shared/threejs/postprocessing/EffectComposer';
import RenderPass from '../../../features/shared/threejs/postprocessing/RenderPass';
import OutlinePass from '../../../features/shared/threejs/postprocessing/OutlinePass';
import SMAAPass from '../../../features/shared/threejs/postprocessing/SMAAPass';

import { PRIMARY_HIGHLIGHT_COLOR, SECONDARY_HIGHLIGHT_COLOR } from '../../../common/themes/workspace';


export const PostProcessingComposer = (renderer, effectsScene, controlsScene, camera, width, height) => {

  const composer = new EffectComposer(renderer);

  const effectsRenderPass = new RenderPass(effectsScene, camera);
  effectsRenderPass.clear = true;
  effectsRenderPass.clearDepth = true;
  composer.addPass(effectsRenderPass);

  const inScreenControlsRenderPass = new RenderPass(controlsScene, camera);
  inScreenControlsRenderPass.clear = false;
  inScreenControlsRenderPass.clearDepth = true;
  composer.addPass(inScreenControlsRenderPass);

  function defineOutlinePass(canvasWidth, canvasHeight, scene, camera, outlineConfig) {
    var outlinePass = new OutlinePass(new THREE.Vector2(canvasWidth, canvasHeight), scene, camera);
    for(var property in outlineConfig) outlinePass[property] = outlineConfig[property];
    return outlinePass;
  }

  const hoverOutlinePass = defineOutlinePass(width, height, effectsScene, camera, {
    visibleEdgeColor: new THREE.Color(SECONDARY_HIGHLIGHT_COLOR),
    hiddenEdgeColor: new THREE.Color(SECONDARY_HIGHLIGHT_COLOR),
    edgeStrength: 1.0,
    edgeGlow: 2.0,
    edgeThickness: 1.0,
    pulsePeriod: 0,
  });

  const selectedOutlinePass = defineOutlinePass(width, height, effectsScene, camera, {
    visibleEdgeColor: new THREE.Color(PRIMARY_HIGHLIGHT_COLOR),
    hiddenEdgeColor: new THREE.Color(PRIMARY_HIGHLIGHT_COLOR),
    edgeStrength: 4.0,
    edgeGlow: 0.1,
    edgeThickness: 2.0,
    pulsePeriod: 0,
  });

  composer.addPass(hoverOutlinePass);
  composer.addPass(selectedOutlinePass);

  const smaaPass = new SMAAPass(1 / window.innerWidth, 1 / window.innerHeight);
  smaaPass.renderToScreen = true;
  composer.addPass(smaaPass);

  return {
    composer,
    hoverOutlinePass,
    selectedOutlinePass,
  };
};
