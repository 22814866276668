import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
  media: {
    height: 182,
  },
});

const GettingStartedCardMedia = ({
  classes,
  imageUrl,
}) => (
  <CardMedia
    className={classes.media}
    image={imageUrl}
  />
);

GettingStartedCardMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default withStyles(styles)(GettingStartedCardMedia);
