import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import saveAs from 'file-saver';
import { format } from 'date-fns';

import { apiGet } from '../../../common/utils/saga-utils';

import { getCurrentRobot } from '../selectors';

import {
  EXPORT_ROBOT_REQUEST,
  EXPORT_ROBOT_ERROR,
  EXPORT_ROBOT_ERROR_MESSAGE,
  EXPORT_ROBOT_SUCCESS,
} from '../actions';

function* processRobotExport() {
  try {
    const currentRobot = yield select(getCurrentRobot);

    if (currentRobot) {
      var result = yield call(apiGet, `/Robot/${currentRobot.id}`, {
        headers: {
          Accept: 'text/hrdf'
        }
      });
      var blob = new Blob([result], { type: 'text/hrdf;charset=utf-8' });
      saveAs(blob, `HEBI Robot ${format(new Date(), 'yyyy-MM-dd')}.hrdf`);
      yield put({ type: EXPORT_ROBOT_SUCCESS });
    } else {
      yield put({ type: EXPORT_ROBOT_ERROR });
      yield call(toast.error, EXPORT_ROBOT_ERROR_MESSAGE);
    }
  } catch (error) {
    yield put({ type: EXPORT_ROBOT_ERROR, error });
    yield call(toast.error, EXPORT_ROBOT_ERROR_MESSAGE);
  }
}

export default function* exportRobotWatcher() {
  yield takeEvery(EXPORT_ROBOT_REQUEST, processRobotExport);
}
