import { createSelector } from 'reselect';

import { getEntitiesSession } from '../../entities/selectors';

export const getPartLengths = createSelector(
  getEntitiesSession,
  session => {
    const { PartLength } = session;

    const partLengths = PartLength.all().toModelArray();
    return partLengths;
  }
);
