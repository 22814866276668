import { all } from 'redux-saga/effects';

import CatalogSaga from '../features/catalog/sagas/catalogSaga';
import ConfiguratorSaga from '../features/configurator/sagas/configuratorSaga';
import PopperSaga from '../features/poppers/sagas/popperSaga';
import RequestQuoteSaga from '../features/request-quote/sagas';

export default function* () {
  yield all([
    CatalogSaga(),
    ConfiguratorSaga(),
    PopperSaga(),
    RequestQuoteSaga(),
  ]);
};
