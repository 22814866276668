import React from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const DropdownIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={{
        ...getStyles('clearIndicator', props),
        padding: '4px',
      }}
    >
      <ArrowDropDownIcon style={{ fontSize: '24px' }} />
    </div>
  );
};

DropdownIndicator.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

export default DropdownIndicator;
