import {
  call,
} from 'redux-saga/effects';

import Api from '../api';

export const create = (someClass, ...args) => call(() => new someClass(...args));

export const apiGet = (url, config) => Api.get(url, config).then(r => r.data);
export const apiPost = (url, config) => Api.post(url, config).then(r => r.data);
export const apiPut = (url, config) => Api.put(url, config).then(r => r.data);
export const apiPatch = (url, config) => Api.patch(url, config).then(r => r.data);
export const apiDelete = (url, config) => Api.delete(url, config).then(r => r.data);
