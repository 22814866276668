import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import { AxisArrow } from 'mdi-material-ui';
import { resetCamera } from '../actions';
import ToggleButton from '../../shared/Components/ToggleButton';

const styles = theme => ({
  workspaceToolbar: {
    zIndex: theme.zIndex.appBar,
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    display: 'flex'
  }
});

const WorkspaceToolbar = ({ resetCamera, classes }) => (
  <div className={classes.workspaceToolbar}>
    <Tooltip title="Re-center the model view" placement="bottom-start">
      <div>
        <ToggleButton onClick={resetCamera}>
          <AxisArrow />
        </ToggleButton>
      </div>
    </Tooltip>
  </div>
);

WorkspaceToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  resetCamera: PropTypes.func.isRequired,
};

const actions = {
  resetCamera,
}

export default withStyles(styles)(connect(null, actions)(WorkspaceToolbar));
