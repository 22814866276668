import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { addPartToWorkspaceRequest } from '../../configurator/actions';

import { getPlacementMode } from '../../configurator/selectors';

import {
  getCatalogParts,
  getSelectedCategory,
} from '../selectors';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  seriesText: {
    marginLeft: '16px',
    marginTop: '22px'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row'
  },
  description: {
    whiteSpace: 'pre',
  },
  partImage: {
    flex: 1,
    height: '126px',
    maxWidth: '126px',
    marginRight: '16px',
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: theme.palette.neutral.darker.color,
    backgroundColor: theme.palette.neutral.workspace.color
  },
  partImageAltText: {
    alignSelf: 'center'
  },
  itemInfo: {
    flex: 1,
    height: '128px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  partName: {
    marginBottom: '8px'
  },
  placeButton: {
    maxWidth: '82px'
  }
});

class ComponentsList extends React.Component {
  handleAddPart(id) {
    const { addPartToWorkspaceRequest } = this.props;

    return () => {
      addPartToWorkspaceRequest(id);
    };
  }

  renderThumbnailOrPlaceholder(part, classes) {
    if (part.thumbnailUrl) {
      return (
        <img
          src={part.thumbnailUrl}
          className={classes.partImage}
          alt={part.name}
        />
      );
    }

    return (
      <div className={classes.partImage}>
        <Typography
          variant="caption"
          color="primary"
          className={classes.partImageAltText}
        >
          Coming Soon!
        </Typography>
      </div>
    );
  }

  render() {
    const {
      addPartButtonText,
      classes,
      parts,
      partCategory,
    } = this.props;

    if (!partCategory) {
      return <div />;
    }

    return (
      <div className={classes.container}>
        <Typography className={classes.seriesText} variant="subtitle2">
          {`X-Series ${pluralize(partCategory.name)}`}
        </Typography>
        <List>
          {parts.sort((a, b) => {
            if (a.enabled === b.enabled) {
              return 0;
            } else if (a.enabled) {
              return -1;
            }
            return 1;
          }).map((part, i) => (
            <div key={i}>
              <ListItem key={part.id} className={classes.listItem}>
                {this.renderThumbnailOrPlaceholder(part, classes)}
                <div className={classes.itemInfo}>
                  <div>
                    <Typography
                      variant="subtitle2"
                      className={classes.partName}
                    >
                      {part.name}
                    </Typography>

                    {
                      part.description &&
                      <Typography className={classes.description} variant="caption">
                        {part.description}
                      </Typography>
                    }
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.placeButton}
                    onClick={this.handleAddPart(part.id)}
                    disabled={!part.enabled}
                  >
                    {addPartButtonText}
                  </Button>
                </div>
              </ListItem>
              {i < parts.length && (
                <li>
                  <Divider variant="middle" />
                </li>
              )}
            </div>
          ))}
        </List>
      </div>
    );
  }
}

ComponentsList.propTypes = {
  addPartButtonText: PropTypes.string.isRequired,
  addPartToWorkspaceRequest: PropTypes.func.isRequired,
  parts: PropTypes.array.isRequired,
  partCategory: PropTypes.object
};

const mapStateToProps = state => {
  const partCategory = getSelectedCategory(state);
  const parts = getCatalogParts(state);
  const placementMode = getPlacementMode(state);

  const addPartButtonText = placementMode;

  return {
    addPartButtonText,
    parts,
    partCategory,
  };
};

const actions = {
  addPartToWorkspaceRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    actions
  )(ComponentsList)
);
