import { createSelector } from 'reselect';

import { getCurrentRobot } from './getCurrentRobot';

export const getPotentialParents = createSelector(
  getCurrentRobot,
  currentRobot => {
    if (!currentRobot) {
      return [];
    }

    const hierarchy = currentRobot.robotParts.toModelArray();
    const potentialParents = getLeafNodes(hierarchy);
    return potentialParents;
  }
);

function getLeafNodes(nodes, result = []) {
  for (var i = 0, length = nodes.length; i < length; i++) {
    if (!nodes[i].children.exists()) {
      result.push(nodes[i]);
    } else {
      result = getLeafNodes(nodes[i].children.toModelArray(), result);
    }
  }
  return result;
}
