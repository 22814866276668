import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons';

import DefaultCardBody from '../../components/GettingStartedCardContent';
import IconCardMedia from '../../components/GettingStartedCardIconMedia';
import GettingStartedCard from '../../components/GettingStartedCard';

import {
  getIsCreatingRobot,
} from '../../selectors';

import {
  uploadHRDFRequest,
} from '../../../configurator/actions';

const styles = theme => ({
  input: {
    display: 'none',
  },
  wrapperLabel: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  icon: {
    color: theme.palette.neutral.darkest.color,
  },
});

const LoadHRDFCard = ({
  classes,
  handleHRDFLoad,
  isSubmitting,
}) => (
  <GettingStartedCard
    media={
      <IconCardMedia
        icon={
          <FontAwesomeIcon
            icon={faCloudUploadAlt}
            size="4x"
            className={classes.icon}
          />
        }
      />
    }
    content={
      <DefaultCardBody
        title="Load HRDF file"
        description="Load an existing HRDF file as the starting point."
      />
    }
    actions={
      <React.Fragment>
        {!isSubmitting && <input
          accept=".hrdf"
          className={classes.input}
          id="hrdf-file-uploader"
          type="file"
          onChange={(event) => {
            if (!event.target.files || !event.target.files.length) {
              return;
            }

            const reader = new FileReader();
            reader.onload = async (event) => {
              const base64 = event.target.result.split(',').pop();
              handleHRDFLoad(base64);
            };

            reader.readAsDataURL(event.target.files[0]);
            event.target.value = null;
          }}
        />}
        <label htmlFor="hrdf-file-uploader" className={classes.wrapperLabel}>
          <Button
            component="span"
            size="small"
            color="primary"
            disabled={isSubmitting}
          >
            Select
          </Button>
        </label>
      </React.Fragment>
    }
  />
);

LoadHRDFCard.propTypes = {
  classes: PropTypes.object.isRequired,
  handleHRDFLoad: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isSubmitting: getIsCreatingRobot(state),
});

const actions = {
  handleHRDFLoad: uploadHRDFRequest,
};

export default connect(mapStateToProps, actions)(
  withStyles(styles)(LoadHRDFCard)
);
