import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';

import {
  INITIALIZE_CONFIGURATOR_SUCCESS_CACHED,
  INITIALIZE_CONFIGURATOR_ERROR,
  INITIALIZE_CONFIGURATOR_ERROR_MESSAGE,
  initializeConfiguratorSuccess,
  initializeConfiguratorRequest,
} from '../actions';

import {
  getConfiguratorIsInitialized,
} from '../selectors';

import loadEntitiesSaga from './loadEntitiesSaga';

export default function* initializeConfiguratorSaga() {
  try {
    yield put(initializeConfiguratorRequest());

    const initialized = yield select(getConfiguratorIsInitialized);
    if (initialized) {
      yield put({ type: INITIALIZE_CONFIGURATOR_SUCCESS_CACHED });
    } else {
      yield call(loadEntitiesSaga);
      yield put(initializeConfiguratorSuccess());
    }
  } catch (error) {
    yield put({ type: INITIALIZE_CONFIGURATOR_ERROR, error });
    yield call(toast.error, INITIALIZE_CONFIGURATOR_ERROR_MESSAGE);
  }
}
