import React from 'react';
import { connect } from 'react-redux';

import ConfiguratorDialog from '../configurator/components/dialogs/ConfiguratorDialog';
import TestDriveGrabberLabel from '../configurator/components/TestDriveGrabberLabel';

import Popper from './Popper';
import { getPopper } from './selectors';

const popperTypes = {
  ConfiguratorDialog,
  TestDriveGrabberLabel,
};

export class PopperManager extends React.Component {
  render() {
    const { popper } = this.props;
    const { show, location, type, popperProps = {} } = popper;

    let dialog = null;

    if (show) {
      const PopperComponent = popperTypes[type];

      if (PopperComponent) {
        dialog = (
          <Popper location={location}>
            <PopperComponent {...popperProps} />
          </Popper>
        );
      }
    }

    return dialog;
  }
}

const mapStateToProps = (state) => ({
  popper: getPopper(state),
});

export default connect(mapStateToProps)(PopperManager);
