import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';

const ClearIndicator = (props) => {
  const {
    children = <CancelIcon style={{ fontSize: '16px' }} />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={{
        ...getStyles('clearIndicator', props),
        padding: '6px',
      }}
    >
      {children}
    </div>
  );
};

ClearIndicator.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

export default ClearIndicator;
