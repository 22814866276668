import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

// Needs to be a class component to support refs
class Option extends React.Component {
  render() {
    const {
      children,
      innerProps,
      innerRef,
      isFocused,
      isSelected,
    } = this.props;

    return (
      <MenuItem
        buttonRef={innerRef}
        selected={isFocused}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        {...innerProps}
      >
        {children}
      </MenuItem>
    );
  }
}

Option.propTypes = {
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default Option;
