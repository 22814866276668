import { PRIMARY_FONT } from '../fonts';

export const typography = {
  useNextVariants: true,
  fontFamily: [
    PRIMARY_FONT,
    'sans-serif',
  ].join(','),
  h1: {
    fontSize: '96px',
    fontFamily: PRIMARY_FONT,
    fontWeight: 300,
    letterSpacing: '-1.5px',
  },
  h2: {
    fontSize: '60px',
    fontFamily: PRIMARY_FONT,
    fontWeight: 300,
    letterSpacing: '-0.5px',
    lineHeight: '73px',
  },
  h3: {
    fontSize: '48px',
    fontFamily: PRIMARY_FONT,
    lineHeight: '58px',
  },
  h4: {
    fontSize: '34px',
    fontFamily: PRIMARY_FONT,
    letterSpacing: '0.25px',
    lineHeight: '41px',
  },
  h5: {
    fontSize: '24px',
    fontFamily: PRIMARY_FONT,
    lineHeight: '29px',
  },
  h6: {
    fontSize: '20px',
    fontFamily: PRIMARY_FONT,
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },
  subtitle1: {
    fontSize: '16px',
    fontFamily: PRIMARY_FONT,
    letterSpacing: '0.15px',
    lineHeight: '20px',
  },
  subtitle2: {
    fontSize: '14px',
    fontFamily: PRIMARY_FONT,
    fontWeight: 600,
    letterSpacing: '0.1px',
    lineHeight: '17px',
  },
  body1: {
    fontSize: '16px',
    fontFamily: PRIMARY_FONT,
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  body2: {
    fontSize: '14px',
    fontFamily: PRIMARY_FONT,
    letterSpacing: '0.25px',
    lineHeight: '18px',
  },
  button: {
    fontSize: '14px',
    fontFamily: PRIMARY_FONT,
    fontWeight: 600,
    letterSpacing: '1.25px',
    lineHeight: '17px',
  },
  caption: {
    fontSize: '12px',
    fontFamily: PRIMARY_FONT,
    letterSpacing: '0.4px',
    lineHeight: '15px',
  },
  overline: {
    fontSize: '10px',
    fontFamily: PRIMARY_FONT,
    letterSpacing: '1.5px',
    lineHeight: '12px',
  },
};

export const zIndex = {
  mobileStepper: 1000,
  workspace: 1099,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};
