import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { SECONDARY_FONT } from '../../../common/fonts';

import GettingStartedCardsList from '../components/GettingStartedCardList';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.neutral.darkest.color,
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  title: {
    textAlign: 'center',
    fontFamily: SECONDARY_FONT,
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    letterSpacing: '2.5px',
    fontWeight: 'bold',
    marginBottom: '2em',
  },
});

const GettingStartedPage = ({
  classes,
}) => (
  <div className={classes.container}>
    <Typography variant="h5" className={classes.title}>
      How would you like to get started?
    </Typography>

    <GettingStartedCardsList />
  </div>
);

GettingStartedPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GettingStartedPage);
