import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    minHeight: '67px',
    borderBottom: `1px solid ${theme.palette.neutral.light.color}`,
    '&$expanded': {
      minHeight: '67px',
    },
  },
  expanded: {},
}))(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

export default ExpansionPanelSummary;
