import { createStore, compose, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import createRootReducer from './reducers/root-reducer';
import persistConfig from './config/persist-config';

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  sagaMiddleware,
  thunk,
  routerMiddleware(history),
];

const middlewareEnhancer = applyMiddleware(...middlewares);
const storeEnhancers = [middlewareEnhancer];

const environmentEnhancer = process.env.NODE_ENV === 'production' ?
  compose(...storeEnhancers) :
  composeWithDevTools(...storeEnhancers);

const rootReducer = createRootReducer(history);
export const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(connectRouter(history)(persistedReducer), environmentEnhancer);
export const persistor = persistStore(store);

if (module.hot) {
  module.hot.accept(() => {
    const nextRootReducer = require('./reducers/root-reducer');
    store.replaceReducer(
      persistReducer(persistConfig, nextRootReducer)
    );
  });
}

export default store;
