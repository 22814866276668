import {
  call,
  take,
} from 'redux-saga/effects';

import {
  RESET_CAMERA
} from '../actions';

export default function* resetCameraWatcher(threeProcessor) {
  while (true) {
    yield take(RESET_CAMERA);
    
    yield call(threeProcessor.resetCamera);
  }
}
