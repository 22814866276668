export const ADD_PART_TO_WORKSPACE_PREFIX = 'ADD_PART_TO_WORKSPACE';
export const ADD_PART_TO_WORKSPACE_REQUEST = `${ADD_PART_TO_WORKSPACE_PREFIX}_REQUEST`;
export const ADD_PART_TO_WORKSPACE_SUCCESS = `${ADD_PART_TO_WORKSPACE_PREFIX}_SUCCESS`;
export const ADD_PART_TO_WORKSPACE_ERROR = `${ADD_PART_TO_WORKSPACE_PREFIX}_ERROR`;
export const ADD_PART_TO_WORKSPACE_ERROR_MESSAGE = 'Error adding part to workspace';
export const ADD_PART_TO_WORKSPACE_NOT_SUPPORTED_MESSAGE = 'Cannot currently add a part with multiple potential parents. Please check back later!';

export const addPartToWorkspaceRequest = (id) => ({
  type: ADD_PART_TO_WORKSPACE_REQUEST,
  payload: {
    id,
  }
});
