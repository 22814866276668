import {
  attr,
  Model,
} from 'redux-orm';

class PartCategory extends Model {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
    };
  }

  static parse(partCategoryData) {
    return this.upsert(partCategoryData);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherPartCategory) {
    this.update(otherPartCategory.ref);
  }
}

PartCategory.modelName = 'PartCategory';

export default PartCategory;
