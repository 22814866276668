import {
  call,
  take,
  select,
} from 'redux-saga/effects';

import {
  ACTIVATE_TEST_DRIVE,
} from '../actions';

import {
  getPotentialParents,
} from '../selectors';

import { toast } from 'react-toastify';

export default function* activateTestDriveWatcher(threeProcessor) {
  while (true) {
    yield take(ACTIVATE_TEST_DRIVE);

    const potentialParents = yield select(getPotentialParents);

    if(potentialParents.length === 0) {
      yield call(toast.error, 'Need a robot to test drive!');
    }
    else if(potentialParents.length === 1) {
      const parentPart = potentialParents[0];
      yield call(threeProcessor.activateTestDrive, parentPart.id);
    }
    else {
      // TODO: Version 1.0+
      // If Multiple potential parents, wait for user to select one, validate, and continue
      yield call(toast.error, 'Unsupported robot configuration');
    }
  }
}
