import { createSelector } from 'reselect';

import { getCurrentRobotPart } from './getCurrentRobotPart';

export const getCurrentRobotPartAsset = createSelector(
  getCurrentRobotPart,
  currentRobotPart => {
    if (!currentRobotPart) {
      return null;
    }

    const assets = currentRobotPart.part.assets.toModelArray();
    if (!assets || !assets.length) {
      return null;
    }

    const asset = assets[0];
    return asset;
  }
);
