export const INITIALIZE_CONFIGURATOR_PREFIX = 'INITIALIZE_CONFIGURATOR';
export const INITIALIZE_CONFIGURATOR_REQUEST = `${INITIALIZE_CONFIGURATOR_PREFIX}_REQUEST`;
export const INITIALIZE_CONFIGURATOR_SUCCESS = `${INITIALIZE_CONFIGURATOR_PREFIX}_SUCCESS`;
export const INITIALIZE_CONFIGURATOR_SUCCESS_CACHED = `${INITIALIZE_CONFIGURATOR_PREFIX}_SUCCESS_CACHED`;
export const INITIALIZE_CONFIGURATOR_ERROR = `${INITIALIZE_CONFIGURATOR_PREFIX}_ERROR`;
export const INITIALIZE_CONFIGURATOR_ERROR_MESSAGE = 'Error while initializing configurator';

export const initializeConfiguratorRequest = () => ({
  type: INITIALIZE_CONFIGURATOR_REQUEST,
  payload: {
  },
});
