/* eslint-disable */

import { Matrix4 } from "three";
/**
 * Represents a matrix that has 2 dimensions,
 * i.e., numRows and columns. Modelled after EJML's
 * DMatrixRMaj
 */
var Matrix2d = /** @class */ (function () {
    function Matrix2d(numRows, numCols) {
        if (numRows === void 0) { numRows = 0; }
        if (numCols === void 0) { numCols = 0; }
        this.numRows = numRows;
        this.numCols = numCols;
        this.data = new Float64Array(0);
        this.data = new Float64Array(numRows * numCols);
    }
    /**
     * Creates a new Matrix2d around the provided data.  The data must encode
     * a row-major matrix. Any modification to the returned matrix will modify the
     * provided data.
     *
     * @param numRows Number of rows in the matrix.
     * @param numCols Number of columns in the matrix.
     * @param data Data that is being wrapped. Referenced Saved.
     * @return A matrix which references the provided data internally.
     *
     */
    Matrix2d.wrap = function (numRows, numCols, data) {
        var s = new Matrix2d();
        s.numRows = numRows;
        s.numCols = numCols;
        s.data = new Float64Array(s.getNumElements());
        for (var i = 0; i < s.getNumElements(); i++) {
            s.data[i] = data[i];
        }
        return s;
    };
    Matrix2d.prototype.getNumElements = function () {
        return this.numRows * this.numCols;
    };
    Matrix2d.prototype.reshape = function (rows, cols) {
        this.numRows = rows;
        this.numCols = cols;
        if (this.data.length < this.getNumElements()) {
            this.data = new Float64Array(this.getNumElements()).fill(0);
        }
        return this;
    };
    Matrix2d.prototype.set = function (row, col, value) {
        if (col < 0 || col >= this.numCols || row < 0 || row >= this.numRows) {
            throw new Error("Specified element is out of bounds: (" + row + " , " + col + ")");
        }
        this.data[row * this.numCols + col] = value;
    };
    Matrix2d.prototype.unsafe_set = function (row, col, value) {
        this.data[row * this.numCols + col] = value;
    };
    Matrix2d.prototype.get = function (row, col) {
        if (col < 0 || col >= this.numCols || row < 0 || row >= this.numRows) {
            throw new Error("Specified element is out of bounds: (" + row + " , " + col + ")");
        }
        return this.data[row * this.numCols + col];
    };
    Matrix2d.prototype.unsafe_get = function (row, col) {
        return this.data[row * this.numCols + col];
    };
    Matrix2d.prototype.zero = function () {
        // Note: we could do from-to index, but documentation is inconsistent
        // on how to exactly use it, i.e., inclusive vs exclusive end index.
        this.data.fill(0.0);
        return this;
    };
    Matrix2d.prototype.getNumCols = function () {
        return this.numCols;
    };
    Matrix2d.prototype.getNumRows = function () {
        return this.numRows;
    };
    Matrix2d.prototype.toString = function () {
        var out = "[ ";
        for (var row = 0; row < this.getNumRows(); row++) {
            for (var col = 0; col < this.getNumCols(); col++) {
                out += this.unsafe_get(row, col).toFixed(4);
                if (col + 1 < this.getNumCols()) {
                    out += " , ";
                }
            }
            if (row + 1 < this.getNumRows())
                out += " ;\n  ";
            else
                out += " ]";
        }
        return out;
    };
    return Matrix2d;
}());
export { Matrix2d };
/**
 * Mathematical operations on matrices / vectors
 */
var CommonOps = /** @class */ (function () {
    function CommonOps() {
    }
    /**
     * c = a * b
     *
     * @param a Not modified.
     * @param b Not modified.
     * @param c Modified. (optional)
     * @return c
     */
    CommonOps.mult = function (a, b, c) {
        if (c === void 0) { c = new Matrix2d(a.numRows, b.numCols); }
        // NOTE: EJML switches to do a reordered multiply if >15 columns
        // The code below is equivalent to mult_small()
        if (a === c || b === c) {
            throw new Error("Neither 'a' or 'b' can be the same matrix as 'c'");
        }
        else if (a.numCols != b.numCols) {
            throw new Error("The 'a' and 'b' matrices do not have compatible dimensions");
        }
        c.reshape(a.numRows, b.numCols);
        var aIndexStart = 0;
        var cIndex = 0;
        for (var i = 0; i < a.numRows; i++) {
            for (var j = 0; j < b.numCols; j++) {
                var total = 0;
                var indexA = aIndexStart;
                var indexB = j;
                var end = indexA + b.numRows;
                while (indexA < end) {
                    total += a.data[indexA++] * b.data[indexB];
                    indexB += b.numCols;
                }
                c.data[cIndex++] = total;
            }
            aIndexStart += a.numCols;
        }
        return c;
    };
    /**
     * c = a' * b
     *
     * @param a Not modified.
     * @param b Not modified.
     * @param c Modified. (optional)
     * @return c
     */
    CommonOps.multTransA = function (a, b, c) {
        if (c === void 0) { c = new Matrix2d(a.numCols, b.numRows); }
        // NOTE: EJML switches to do a reordered multiply if >15 columns
        // The code below is equivalent to multTransA_small()
        if (a === c || b === c) {
            throw new Error("Neither 'a' or 'b' can be the same matrix as 'c'");
        }
        else if (a.numRows != b.numRows) {
            throw new Error("The 'a' and 'b' matrices do not have compatible dimensions");
        }
        c.reshape(a.numCols, b.numCols);
        var cIndex = 0;
        for (var i = 0; i < a.numCols; i++) {
            for (var j = 0; j < b.numCols; j++) {
                var indexA = i;
                var indexB = j;
                var end = indexB + b.numRows * b.numCols;
                var total = 0;
                // loop for k
                for (; indexB < end; indexB += b.numCols) {
                    total += a.data[indexA] * b.data[indexB];
                    indexA += a.numCols;
                }
                c.data[cIndex++] = total;
            }
        }
        return c;
    };
    /**
     * <p>Performs the following operation:<br>
     * <br>
     * a = a + b <br>
     * a<sub>ij</sub> = a<sub>ij</sub> + b<sub>ij</sub> <br>
     * </p>
     *
     * @param a A Matrix. Modified.
     * @param b A Matrix. Not modified.
     */
    CommonOps.addEquals = function (a, b) {
        // NOTE: EJML switches to do a reordered multiply if >15 columns
        // The code below is equivalent to multTransA_small()
        if (a.numCols != b.numCols || a.numRows != b.numRows) {
            throw new Error("The 'a' and 'b' matrices do not have compatible dimensions");
        }
        var length = a.getNumElements();
        for (var i = 0; i < length; i++) {
            a.data[i] += b.data[i];
        }
    };
    return CommonOps;
}());
export { CommonOps };
var SizeOps = /** @class */ (function () {
    function SizeOps() {
    }
    /**
     * @return resize the array to the desired length, and reshape each element to the desired rows/cols
     */
    SizeOps.resizeMatrices = function (array, numMatrices, rows, cols) {
        // Reshape any existing entries
        for (var i = 0; i < Math.min(array.length, numMatrices); i++) {
            array[i].reshape(rows, cols);
        }
        // Add/Remove elements if needed
        while (array.length < numMatrices) {
            array.push(new Matrix2d(rows, cols));
        }
        while (array.length > numMatrices) {
            array.pop();
        }
        return array;
    };
    SizeOps.resizeTransforms = function (array, length) {
        if (length == array.length)
            return array;
        while (array.length < length)
            array.push(new Matrix4());
        while (array.length > length)
            array.pop();
        return array;
    };
    return SizeOps;
}());
export { SizeOps };

