import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiDelete } from '../../../common/utils/saga-utils';

import {
  ROBOT_PART_REMOVE_REQUEST,
  ROBOT_PART_REMOVE_CANCEL,
  ROBOT_PART_REMOVE_ERROR,
  ROBOT_PART_REMOVE_ERROR_MESSAGE,
  ROBOT_PART_REMOVE_CONFIRM_REQUEST,
  ROBOT_PART_REMOVE_CONFIRM_SUCCESS,
  removeRobotPartSuccess,
  clearSelectedRobotPart,
  setCurrentRobotPart,
} from '../actions';

import {
  getSelectedRobotPart,
  getPotentialParents,
} from '../selectors';

import {
  openModal,
  closeModal,
} from '../../modals/actions';

import {
  hidePopper,
} from '../../poppers/actions';

export default function* removeRobotPartWatcher(threeProcessor) {
  const confirmationModalName = 'RemovePartConfirmationModal';
  const configurationDialogName = 'ConfiguratorDialog';

  while (true) {
    try {
      yield take([
        ROBOT_PART_REMOVE_REQUEST,
      ]);

      yield put(openModal(confirmationModalName));

      const { type } = yield take([
        ROBOT_PART_REMOVE_CONFIRM_REQUEST,
        ROBOT_PART_REMOVE_CANCEL,
      ]);

      if (type === ROBOT_PART_REMOVE_CONFIRM_REQUEST) {
        const selectedRobotPart = yield select(getSelectedRobotPart);
        const robotPartsToRemove = getRobotPartsToRemove(selectedRobotPart);

        yield call(deleteRobotPart, selectedRobotPart.id);       
        yield put(removeRobotPartSuccess(robotPartsToRemove.map(rp => rp.id)));
        threeProcessor.removeRobotParts(robotPartsToRemove);
        yield put(clearSelectedRobotPart());
        yield call(resetCurrentRobotPart);

        yield put({ type: ROBOT_PART_REMOVE_CONFIRM_SUCCESS });
        yield put(hidePopper(configurationDialogName));
      }

      yield put(closeModal(confirmationModalName));
    } catch (error) {
      yield put({ type: ROBOT_PART_REMOVE_ERROR, error });
      yield call(toast.error, ROBOT_PART_REMOVE_ERROR_MESSAGE);
    }
  }
}

function* resetCurrentRobotPart() {
  const potentialParents = yield select(getPotentialParents);
  if (potentialParents && potentialParents.length) {
    const { id } = potentialParents[0];
    yield put(setCurrentRobotPart(id));
  }
}

function* deleteRobotPart(id) {
  yield call(apiDelete, `/Robot/Part/${id}`);
}

function getRobotPartsToRemove(selectedRobotPart) {
  const children = selectedRobotPart.children.toModelArray();

  const robotParts = getChildRobotParts(children, [selectedRobotPart]);
  return robotParts;
}

function getChildRobotParts(robotParts, result = []) {
  for (let i = 0, length = robotParts.length; i < length; i++) {
    result.push(robotParts[i]);

    result = getChildRobotParts(robotParts[i].children.toModelArray(), result);
  }

  return result;
}
