import { toast } from 'react-toastify';
import GLTFLoader from './GLTFLoader';
import {
    ASSET_CACHE_ERROR_MESSAGE,
  } from './constants';

export default () => {

    //NOTE: This asset cache needs to remain in memory for now.
    //      With the local storage limit of 10mb, we won't be 
    //      able to load the cached asset meshes there.
    let assetCache = [];

    async function getPartAssets(part) {
        let matchingAssets = getFirstPartAsset(part);
        if (matchingAssets == null) {
            await addPartToCache(part);
            return getFirstPartAsset(part);
        }
        return matchingAssets;
    }

    function getFirstPartAsset(part) {
        let matchingAssets = assetCache.filter(p => p.partId === part.id);
        if (matchingAssets && matchingAssets.length > 0) {
            return matchingAssets[0];
        }
        return null;
    }

    async function addPartToCache(part) {
        if (!part.assets)
            return;

        for (const partAsset of part.assets) {
            if (!partAsset.url)
                return;
            try {
                const gltf = await GLTFLoader.load(partAsset.url);
                gltf.scene.traverse(child => {
                    if (child.type === "Mesh") {
                        cachePartAsset(part, child);
                    }
                });
            } catch (error) {
                toast.error(ASSET_CACHE_ERROR_MESSAGE);
            }
        }
    }

    function cachePartAsset(part, mesh) {
        let cachedPartAssets = getCachedPartAssetsById(part.id);
        if (!cachedPartAssets) {
            let addedCachedPart = {
                partId: part.id,
                meshes: [mesh]
            };
            assetCache.push(addedCachedPart);
        }
        else {
            let cachedMesh = cachedPartAssets.meshes.filter(m => m === mesh);
            if (!cachedMesh || cachedMesh.length === 0) {
                cachedPartAssets.meshes.push(mesh);
            }
        }
    }

    function getCachedPartAssetsById(partId) {
        let matchingAssets = assetCache.filter(p => p.partId === partId);
        if (matchingAssets && matchingAssets.length > 0)
            return matchingAssets[0];
        else
            return null;
    }

    return {
        getPartAssets,
        addPartToCache,
    }
}