import React from 'react';
import PropTypes from 'prop-types';
import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';

import Cards from '../cards';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      '& > div': {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
      },
    },
  },
});

const GettingStartedCardList = ({
  classes,
}) => (
  <div className={classes.container}>
    {Cards.map((Card, i) => (
      <Grow
        in={true}
        timeout={(i + 1) * 500}
        key={i}
      >
        <div>
          <Card />
        </div>
      </Grow>
    ))}
  </div>
);

GettingStartedCardList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GettingStartedCardList);
