import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ConnectedRouter } from 'connected-react-router';

import './index.css';
import App from './app/App';
import store, { history, persistor, sagaMiddleware } from './app/store';
import sagas from './app/saga';
import theme from './common/themes/material-theme';

import * as serviceWorker from './serviceWorker';

sagaMiddleware.run(sagas);

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={theme}>
            <Component />
          </MuiThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )
};

render(App);

if (module.hot) {
  module.hot.accept('./app/App', () => {
    const NextApp = require('./app/App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
