import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = theme => ({
  toggleButton: {
    height: '48px',
    width: '48px',
    alignContent: 'center',
    border: '1px solid',
    borderColor: theme.palette.neutral.dark.color,
    backgroundColor: theme.palette.neutral.workspace.contrastText,
    boxShadow: '1px 1px 3px 0 rgba(0,0,0,0.4)',
    '&:hover': {
      backgroundColor: '#F1F1EF'
    },
    '&:disabled': {
      color: theme.palette.neutral.main.color
    }
  }
});

const ToggleButton = ({ classes, onClick, disabled, children }) => (
  <ButtonBase onClick={onClick} className={classes.toggleButton} focusRipple
    disabled={disabled === null || disabled === undefined ? false : disabled}>
    {children}
  </ButtonBase>
);

ToggleButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default withStyles(styles)(ToggleButton);
