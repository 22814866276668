import React from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { RotateRight, ZoomIn, PanTool } from '@material-ui/icons';
import queryString from 'query-string'

import {
  createRobotRequest,
  loadRobotRequest,
} from '../actions';
import { getConfiguratorIsInitialized } from '../selectors';

import ConfiguratorTabbedRoutes from '../components/ConfiguratorTabbedRoutes';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.neutral.darkest.color,
    color: theme.palette.neutral.darkest.contrastText,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.main
  },
  progress: {
    marginTop: '40px',
    width: '480px'
  },
  instructionsContainer: {
    marginTop: '175px'
  },
  instruction: {
    marginBottom: '42px',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start'
  },
  instructionIcon: {
    marginRight: '16px'
  },
  instructionText: {
    color: theme.palette.neutral.darkest.contrastText
  }
});

const instructions = [
  {
    id: 1,
    icon: classes => <RotateRight className={classes.instructionIcon} />,
    text: 'Rotate: left-click and drag'
  },
  {
    id: 2,
    icon: classes => <ZoomIn className={classes.instructionIcon} />,
    text: 'Zoom: scroll'
  },
  {
    id: 3,
    icon: classes => <PanTool className={classes.instructionIcon} />,
    text: 'Pan: right-click and drag'
  }
];

class ConfiguratorLoader extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const robotId = this.parseRobotIdFromQueryString(this.props);
    if (robotId) {
      this.loadRobot(robotId);
    } else {
      this.props.createRobotRequest();
    }
  }

  componentDidUpdate(prevProps) {
    const currentRobotId = this.parseRobotIdFromQueryString(this.props);
    const previousRobotId = this.parseRobotIdFromQueryString(prevProps);

    if (currentRobotId !== previousRobotId) {
      this.loadRobot(currentRobotId);
    }
  }

  loadRobot(id) {
    this.props.loadRobotRequest(id);
  }

  parseRobotIdFromQueryString(props) {
    const values = queryString.parse(props.location.search);
    if (!values || !values.robotId) {
      return null;
    }

    return values.robotId;
  }

  render() {
    const {
      classes,
      configuratorInitialized,
    } = this.props;

    if (configuratorInitialized) {
      return <ConfiguratorTabbedRoutes />;
    }

    return (
      <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Loading 3D Components
        </Typography>
        <LinearProgress
          variant="indeterminate"
          color="secondary"
          className={classes.progress}
        />
        <div className={classes.instructionsContainer}>
          {instructions.map(instruction => (
            <div key={instruction.id} className={classes.instruction}>
              {instruction.icon(classes)}
              <Typography variant="h6" className={classes.instructionText}>
                {instruction.text}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ConfiguratorLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  configuratorInitialized: PropTypes.bool.isRequired,
  loadRobotRequest: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  configuratorInitialized: getConfiguratorIsInitialized(state),
});

const actions = {
  createRobotRequest,
  loadRobotRequest,
};

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, actions)(ConfiguratorLoader))
);
