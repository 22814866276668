import {
    attr,
    many,
    Model,
  } from 'redux-orm';
  
  class Robot extends Model {
    static get fields() {
      return {
        id: attr(),
        name: attr(),
        description: attr(),
        robotParts: many('RobotPart', 'robots'),
      };
    }
  
    static parse(robotData) {
      const {
        RobotPart,
      } = this.session;
  
      robotData.robotParts = robotData.robotParts || [];
  
      const parsedData = {
        ...robotData,
        robotParts: robotData.robotParts.map((robotPart) => RobotPart.parse(robotPart)),
      };
  
      return this.upsert(parsedData);
    }
  
    toJSON() {
      const robot = {
        ...this.ref,
        robotParts: this.robotParts.toModelArray().map(robotPart => robotPart.toJSON())
      };
    
      return robot;
    }
  
    updateFrom(otherRobot) {
      this.update(otherRobot.ref);
    }
  }
  
  Robot.modelName = 'Robot';
  
  export default Robot;
  