import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { getDrawerIsVisible as getCatalogDrawerIsVisible } from '../selectors';
import { setDrawerVisible as setComponentCatalogDrawerVisible } from '../actions';

import CategorySelect from './CategorySelect';
import ComponentsList from './ComponentsList';

const drawerWidth = 320;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: theme.palette.neutral.light.color,
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  toolbar: theme.mixins.toolbar,
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing.unit,
  },
});

const ComponentCatalogDrawer = ({
  classes,
  isVisible,
  handleDrawerClose,
}) => (
  <Drawer
    className={classes.drawer}
    variant="persistent"
    anchor="right"
    open={isVisible}
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <div className={classes.toolbar} />

    <div className={classes.header}>
      <Typography variant="h6">
        Components
      </Typography>
      <IconButton onClick={handleDrawerClose}>
        <CloseIcon />
      </IconButton>
    </div>

    <CategorySelect />

    <Divider />

    <ComponentsList />
  </Drawer>
);

ComponentCatalogDrawer.propTypes = {
  components: PropTypes.array,
  isVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isVisible: getCatalogDrawerIsVisible(state),
});

const actions = {
  handleDrawerClose: () => setComponentCatalogDrawerVisible(false),
}

export default withStyles(styles)(connect(mapStateToProps, actions)(ComponentCatalogDrawer));
