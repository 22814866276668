
import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import { apiGet, apiPost } from '../../../../common/utils/saga-utils';

import {
  getSelectedPartId,
} from '../../../catalog/selectors';

import {
  createRobotPartSuccess,
} from '../../actions';

import {
  getCurrentRobotId,
  getPotentialParents,
} from '../../selectors';

import addPartToWorkspaceSaga from '../addPartToWorkspaceSaga';

export function* createChildPartSaga(threeProcessor) {
  const potentialParents = yield select(getPotentialParents);
  const parentRobotPart = potentialParents[0];
  const attachments = parentRobotPart.part.attachments.toModelArray();
  const partId = yield select(getSelectedPartId);
  const robotId = yield select(getCurrentRobotId);
  const parentRobotPartId = parentRobotPart.id;

  const id = yield call(apiPost, '/Robot/Part', {
    robotId,
    partId,
    parentRobotPartId,
    attachmentId: Boolean(attachments && attachments.length) ? attachments[0].id : null,
  });

  const newPart = yield call(apiGet, `/Robot/Part/${id}`);

  yield put(createRobotPartSuccess(newPart));
  yield call(addPartToWorkspaceSaga, id, threeProcessor);
}
