import { createSelector } from 'reselect';

import { getPartsInSelectedCategory } from './getPartsInSelectedCategory';
import { getPartIdsAvailableForCatalogSelection } from './getPartIdsAvailableForCatalogSelection';

export const getCatalogParts = createSelector(
  [getPartsInSelectedCategory, getPartIdsAvailableForCatalogSelection],
  (partsInSelectedCategory, availablePartIds) => {
    if (!partsInSelectedCategory || !partsInSelectedCategory.length) {
      return [];
    }

    availablePartIds = availablePartIds || [];

    const parts = partsInSelectedCategory
      .map(({ id, name, description, thumbnailUrl }) => ({
        id,
        name,
        description,
        thumbnailUrl,
        enabled: availablePartIds.includes(id),
      }));
    
    return parts;
  }
);
