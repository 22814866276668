import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import RobotBuilderButtonBar from './RobotBuilderButtonBar';
import HebiLogo from '../../shared/HebiLogo';
import { getInBuildMode } from '../../configurator/selectors';
import ProgressBar from '../../loading/components/ProgressBar/ProgressBar';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    '& > div': {
      paddingLeft: theme.spacing.unit * 6,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing.unit * 6,
      paddingRight: theme.spacing.unit * 6,
    },
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    color: theme.palette.neutral.darker.color,
  },
});

const AppNav = ({
  classes,
  showRobotBuilderButtons,
}) => (
  <AppBar position="static" color="inherit" className={classes.appBar}>
    <Toolbar className={classes.toolbar}>
      <HebiLogo />
      <div className={classes.titleContainer}>
        <Typography variant="subtitle1" className={classes.title}>
          3D Configurator
        </Typography>
      </div>

      {
        showRobotBuilderButtons && <RobotBuilderButtonBar />
      }
    </Toolbar>
    <ProgressBar />
  </AppBar>
);

AppNav.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  showRobotBuilderButtons: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  const { location } = props;
  const currentlyInBuildMode = getInBuildMode(state, location);

  return {
    showRobotBuilderButtons: currentlyInBuildMode,
  };
};

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(AppNav))
);
