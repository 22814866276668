import { createReducer } from '../../../common/utils/reducer-utils';

import {
  SET_CURRENT_ROBOT,
  SET_CURRENT_ROBOT_PART,
  SET_PLACEMENT_MODE,
  TOGGLE_PLACEMENT_MODE,
  UPDATE_ROBOT_STATISTICS,
  SET_SCENE_LOADED,
} from '../actions';

const placementModes = ['place', 'replace'];

const initialState = {
  currentRobotId: null,
  currentRobotPartId: null,
  placementMode: 'place',
  statistics: null,
  sceneLoaded: false,
};

const setCurrentRobot = (state, payload) => {
  return {
    ...state,
    currentRobotId: payload.currentRobotId,
  };
}

const setCurrentRobotPart = (state, payload) => {
  return {
    ...state,
    currentRobotPartId: payload.currentRobotPartId,
  };
};

const setPlacementMode = (state, payload) => ({
  ...state,
  placementMode: payload.mode,
});

const togglePlacementMode = (state) => {
  const { placementMode: currentPlacementMode } = state;
  const newPlacementMode = placementModes.find(p => p !== currentPlacementMode);

  return {
    ...state,
    placementMode: newPlacementMode,
  };
};

const updateStatistics = (state, payload) => ({
  ...state,
  statistics: payload.statistics,
});

const setSceneLoaded = (state, payload) => ({
  ...state,
  sceneLoaded: payload.loaded,
});

export default createReducer(initialState, {
  [SET_CURRENT_ROBOT]: setCurrentRobot,
  [SET_CURRENT_ROBOT_PART]: setCurrentRobotPart,
  [SET_PLACEMENT_MODE]: setPlacementMode,
  [TOGGLE_PLACEMENT_MODE]: togglePlacementMode,
  [UPDATE_ROBOT_STATISTICS]: updateStatistics,
  [SET_SCENE_LOADED]: setSceneLoaded,
});
