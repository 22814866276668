import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const IndicatorsContainer = (props) => {
  const { getStyles } = props;
  return (
    <components.IndicatorsContainer
      {...props}
      style={{
        ...getStyles('indicatorsContainer', props),
        padding: '6px',
      }}
    />
  );
};

IndicatorsContainer.propTypes = {
  getStyles: PropTypes.func.isRequired,
};

export default IndicatorsContainer;
