import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  singleValue: {
    fontSize: 16,
  },
});

const SingleValue = ({
  children,
  classes,
  innerProps,
}) => (
  <Typography className={classes.singleValue} {...innerProps}>
    {children}
  </Typography>
);

SingleValue.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
};

export default withStyles(styles)(SingleValue);
