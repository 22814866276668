import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reduceReducers } from '../../common/utils/reducer-utils';

import entities from './entities-reducer';

import configurator from '../../features/configurator/reducers';
import catalog from '../../features/catalog/reducer';
import modals from '../../features/modals/reducer';
import popper from '../../features/poppers/reducer';
import loading from '../../features/loading/reducers';

import entitiesCrudReducer from '../../features/entities/reducers';

export default (history) => {
  const combinedReducers = combineReducers({
    catalog,
    entities,
    router: connectRouter(history),
    configurator,
    modals,
    popper,
    loading,
  });

  const rootReducer = reduceReducers(
    combinedReducers,
    entitiesCrudReducer,
  );

  return rootReducer;
}
