import {
  POPPER_HIDE,
  POPPER_SHOW,
  POPPER_UPDATE_SUCCESS,
} from './actions';

import { createReducer } from '../../common/utils/reducer-utils';

const initialState = {
  show: false,
  location: {
    x: null,
    y: null,
  },
  type: null,
  popperProps: undefined,
};

const showPopper = (state, payload) => ({
  ...state,
  show: true,
  ...payload,
});

const hidePopper = (state, payload) => ({
  ...initialState,
});

const updatePopper = (state, payload) => ({
  ...state,
  location: payload.location,
  popperProps: payload.popperProps,
});

export default createReducer(initialState, {
  [POPPER_SHOW]: showPopper,
  [POPPER_HIDE]: hidePopper,
  [POPPER_UPDATE_SUCCESS]: updatePopper,
});
