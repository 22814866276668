import React, { PureComponent } from 'react';
import { FormikConsumer } from 'formik';

class FormikPersistor extends PureComponent {
  componentWillMount() {
    window.addEventListener('beforeunload', this.clear);
  }

  componentDidMount() {
    const {
      setValues,
      setErrors,
      setStatus,
      setTouched,
    } = this.props;

    const data = sessionStorage.getItem(this.storageKey);
    if (data) {
      const {
        errors,
        status,
        touched,
        values,
      } = JSON.parse(data);
      setErrors(errors);
      setStatus(status);
      setTouched(touched || {});
      setValues(values);
    }
  }

  componentDidUpdate() {
    const {
      errors,
      status,
      touched,
      values,
    } = this.props;
    sessionStorage.setItem(
      this.storageKey,
      JSON.stringify({
        errors,
        status,
        touched: touched || {},
        values,
      })
    );
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.clear);
  }

  get storageKey() {
    return `formik.form.${this.props.name}`;
  }

  clear = () => {
    sessionStorage.removeItem(this.storageKey);
  }

  render() {
    return null;
  }
}

const FormikPersist = ({ name }) => (
  <FormikConsumer>
    {({
      errors,
      status,
      touched,
      values,
      setErrors,
      setStatus,
      setTouched,
      setValues,
    }) => (
      <FormikPersistor
        name={name}
        setErrors={setErrors}
        setStatus={setStatus}
        setTouched={setTouched}
        setValues={setValues}
        status={status}
        touched={touched}
        values={values}
        errors={errors}
      />
    )}
  </FormikConsumer>
);

export default FormikPersist