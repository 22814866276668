import {
  fork,
} from 'redux-saga/effects';

import configureCanvasWatcher, { createCanvas } from './configureCanvasWatcher';
import resetCameraWatcher from './resetCameraWatcher';
import loadRobotWatcher from './loadRobotWatcher';
import exportRobotWatcher from './exportRobotWatcher';
import createRobotWatcher from './createRobotWatcher';
import removeRobotPartWatcher from './removeRobotPartWatcher';
import selectRobotPartWatcher from './selectRobotPartWatcher';
import activateTestDriveWatcher from './activateTestDriveWatcher';
import deactivateTestDriveWatcher from './deactivateTestDriveWatcher';
import configurePartWatcher from './configurePartWatcher';
import addPartToWorkspaceWatcher from './addPartToWorkspaceWatcher';

export default function* root() {
  const { threeProcessor } = createCanvas();
  yield fork(addPartToWorkspaceWatcher, threeProcessor);
  yield fork(configureCanvasWatcher);
  yield fork(resetCameraWatcher, threeProcessor);
  yield fork(loadRobotWatcher, threeProcessor);
  yield fork(activateTestDriveWatcher, threeProcessor);
  yield fork(deactivateTestDriveWatcher, threeProcessor);
  yield fork(createRobotWatcher);
  yield fork(exportRobotWatcher);
  yield fork(removeRobotPartWatcher, threeProcessor);
  yield fork(selectRobotPartWatcher);
  yield fork(configurePartWatcher, threeProcessor);
}
