import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import WorkspaceInstructions from '../../shared/WorkspaceInstructions';
import ComponentCatalogDrawer from '../../catalog/components/ComponentCatalogDrawer';
import ExportRobotButton from '../components/ExportRobotButton';
import WorkspaceToolbar from '../components/WorkspaceToolbar';
import { setDrawerVisible as setComponentCatalogDrawerVisible } from '../../catalog/actions';

import {
  hidePopper,
} from '../../poppers/actions';

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
})

const instructions = [{
  icon: 'rotate_right',
  instruction: 'Rotate: left click + drag',
}, {
  icon: 'zoom_in',
  instruction: 'Scroll to zoom',
}, {
  icon: 'pan_tool',
  instruction: 'Pan: right click + drag',
}];

class BuildRobotPage extends React.Component {
  componentWillUnmount() {
    this.props.setComponentCatalogDrawerVisible(false);
    this.props.hidePopper();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <WorkspaceInstructions instructions={instructions} />
        <WorkspaceToolbar />
        <ComponentCatalogDrawer />
        <ExportRobotButton />
      </div>
    )
  }
}

BuildRobotPage.propTypes = {
  classes: PropTypes.object.isRequired,
  setComponentCatalogDrawerVisible: PropTypes.func.isRequired,
};

const actions = {
  hidePopper,
  setComponentCatalogDrawerVisible,
};

export default withStyles(styles)(
  connect(
    null,
    actions
  )(BuildRobotPage)
);
