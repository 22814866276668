import React from 'react';
import { connect } from 'react-redux';

import RemovePartConfirmationModal from '../configurator/components/RemovePartConfirmationModal';
import FeedbackModal from '../configurator/components/FeedbackModal';

import { getModals } from './selectors';

const modalComponentLookupTable = {
  RemovePartConfirmationModal,
  FeedbackModal,
};

export class ModalManager extends React.Component {
  render() {
    const { currentModals } = this.props;

    const renderedModals = currentModals.map((modalDescription, index) => {
      const { type, modalProps = {} } = modalDescription;
      const ModalComponent = modalComponentLookupTable[type];

      return <ModalComponent {...modalProps} key={type + index} />;
    });

    return (
      <React.Fragment>
        {renderedModals}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  currentModals: getModals(state),
});

export default connect(mapStateToProps)(ModalManager);
