import {
  call,
  select,
  take,
} from 'redux-saga/effects';

import {
  LOCATION_CHANGE,
  getLocation,
} from 'connected-react-router';

import { zIndex } from '../../../common/themes/variables'

import ThreeProcessor from '../../../common/utils/three/ThreeProcessor';

import {
  INITIALIZE_CONFIGURATOR_SUCCESS,
  INITIALIZE_CONFIGURATOR_SUCCESS_CACHED,
} from '../actions';

import {
  getWorkspaceIsVisible,
} from '../selectors';

const CANVAS_ID = 'configurator-workspace';

const getCanvasVisibility = isVisible => isVisible ? 'visible' : 'hidden';

export default function* configureCanvasWatcher() {
  while (true) {
    yield take([
      LOCATION_CHANGE,
      INITIALIZE_CONFIGURATOR_SUCCESS,
      INITIALIZE_CONFIGURATOR_SUCCESS_CACHED,
    ]);

    const location = yield select(getLocation);
    yield call(updateCanvasVisibility, location);
  }
}

function* updateCanvasVisibility(location) {
  const shouldBeVisible = yield select(getWorkspaceIsVisible, location);
  const canvas = document.getElementById(CANVAS_ID);
  canvas.style.visibility = getCanvasVisibility(shouldBeVisible);
}

export function createCanvas() {
  const canvas = document.createElement('canvas');
  canvas.id = CANVAS_ID;

  canvas.style.position = 'absolute';
  canvas.style.zIndex = zIndex.workspace;
  document.body.appendChild(canvas);

  const threeProcessor = ThreeProcessor(canvas);
  threeProcessor.render();

  return {
    threeProcessor,
  };
}
