import React from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Draggable from 'react-draggable';

import darkTheme from '../../common/themes/material-dark-theme';

import {
  updatePopperRequest,
} from './actions';

import {
  getPopperLocation,
} from './selectors';

const styles = theme => ({
  container: {
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    pointerEvents: 'none',
  },
});

class Popper extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleDrag(e, position) {
    const { x, y } = position;
    this.props.handlePopperUpdate(x, y);
  }

  render() {
    const {
      children,
      classes,
      location,
    } = this.props;

    return (
      <MuiThemeProvider theme={darkTheme}>
        <Draggable
          defaultPosition={{ x: location.x + 2, y: location.y }}
          position={location}
          onStop={this.handleDrag}
          handle=".drag-handle"
        >
          <div className={classes.container}>
            {children}
          </div>
        </Draggable>
      </MuiThemeProvider>
    );
  }
}

Popper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

const mapStateToProps = state => ({
  location: getPopperLocation(state),
});

const actions = {
  handlePopperUpdate: updatePopperRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, actions)(Popper)
);
