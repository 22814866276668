import { createSelector } from 'reselect';

import { getSelectedRobotPart } from './getSelectedRobotPart';

export const getSelectedRobotPartChildren = createSelector(
  getSelectedRobotPart,
  selectedRobotPart => {
    if (!selectedRobotPart) {
      return [];
    }

    const childRobotParts = selectedRobotPart.children.toModelArray();
    return childRobotParts;
  }
);
