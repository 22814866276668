import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
  card: {
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
      minWidth: 288,
    },
    [theme.breakpoints.up('sm')]: {
      width: 288,
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  actions: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
  },
});

const GettingStartedCard = ({
  classes,
  media,
  content,
  actions,
}) => (
  <Card className={classes.card} raised>
    {media}
    <CardContent>
      {content}
    </CardContent>
    <CardActions className={classes.actions}>
      {actions}
    </CardActions>
  </Card>
);

GettingStartedCard.propTypes = {
  classes: PropTypes.object.isRequired,
  media: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
};

export default withStyles(styles)(GettingStartedCard);
