import {
  call,
  select,
} from 'redux-saga/effects';

import {
  getPotentialParents,
} from '../../selectors';

import { createChildPartSaga } from './createChildPartSaga';
import { createRootPartSaga } from './createRootPartSaga';
import { ensurePotentialParentSelectedBeforeCreationSaga } from './ensurePotentialParentSelectedBeforeCreationSaga';

export default function* placePartSaga(threeProcessor) {
  const potentialParents = yield select(getPotentialParents);
  const numberOfPotentialParents = potentialParents.length;

  if (numberOfPotentialParents === 0) {
    yield call(createRootPartSaga, threeProcessor);
  } else if (numberOfPotentialParents === 1) {
    yield call(createChildPartSaga, threeProcessor);
  } else {
    yield call(ensurePotentialParentSelectedBeforeCreationSaga);
  }
}
