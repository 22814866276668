import { createReducer } from '../../common/utils/reducer-utils';
import {
  SELECT_PART,
  SET_DRAWER_VISIBLE,
  SET_SELECTED_CATEGORY,
  TOGGLE_DRAWER_VISIBLE,
} from './actions';

const initialState = {
  componentDrawerVisible: false,
  selectedCategoryId: null,
  selectedPartId: null,
};

const setDrawerVisibility = (state, payload) => ({
  ...state,
  componentDrawerVisible: payload.visible,
});

const toggleDrawerVisibility = (state) => ({
  ...state,
  componentDrawerVisible: !state.componentDrawerVisible,
});

const setSelelectedCategory = (state, payload) => ({
  ...state,
  selectedCategoryId: payload.id,
});

const setSelelectedPart = (state, payload) => ({
  ...state,
  selectedPartId: payload.id,
});

export default createReducer(initialState, {
  [SET_DRAWER_VISIBLE]: setDrawerVisibility,
  [TOGGLE_DRAWER_VISIBLE]: toggleDrawerVisibility,
  [SET_SELECTED_CATEGORY]: setSelelectedCategory,
  [SELECT_PART]: setSelelectedPart,
});
