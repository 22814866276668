import {
  MODAL_CLOSE,
  MODAL_OPEN,
} from './actions';

import { createReducer } from '../../common/utils/reducer-utils';

const initialState = [];

const openModal = (state, payload) => {
  const { type, modalProps } = payload;
  return state.concat({ type, modalProps });
}

const closeModal = (state) => {
  const newState = state.slice();
  newState.pop();
  return newState;
}

export default createReducer(initialState, {
  [MODAL_OPEN]: openModal,
  [MODAL_CLOSE]: closeModal,
});
