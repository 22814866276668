import {
  attr,
  Model,
} from 'redux-orm';

class BoltPattern extends Model {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
    };
  }

  static parse(boltPatternData) {
    if (!boltPatternData) {
      return;
    }

    return this.upsert(boltPatternData);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherBoltPattern) {
    this.update(otherBoltPattern.ref);
  }
}

BoltPattern.modelName = 'BoltPattern';

export default BoltPattern;
