import { createSelector } from 'reselect';

import { getEntitiesSession } from '../../entities/selectors';

export const getPartIdsAvailableForCatalogSelection = createSelector(
  getEntitiesSession,
  session => {
    const { Part } = session;
    const availablePartIds = Part
      .all()
      .filter(p => p.enabledForWorkspace === true)
      .toRefArray()
      .map(p => p.id);

    return availablePartIds;
  }
);
