import {
  createReducer,
} from '../../common/utils/reducer-utils';

import {
  LOAD_ENTITIES_SUCCESS,
} from '../../features/configurator/actions';

import orm from '../orm';

const initialState = orm.getEmptyState();

const loadData = (state, payload) => {
  const session = orm.session(state);

  const {
    Asset,
    BoltPattern,
    Part,
    PartLength,
    PartCategory,
    Robot,
  } = session;

  const {
    boltPatterns,
    parts,
    partCategories,
    partLengths,
  } = payload;

  // Clear out any existing models from state so that we can avoid
  // conflicts from the new data coming in if data is reloaded
  const models = [
    Asset,
    BoltPattern,
    Part,
    PartLength,
    PartCategory,
    Robot,
  ];

  models.forEach(modelType => {
    modelType.all().toModelArray().forEach(model => model.delete());
  });

  boltPatterns.forEach(boltPattern => BoltPattern.parse(boltPattern));
  partCategories.forEach(partCategory => PartCategory.parse(partCategory));
  partLengths.forEach(partLength => PartLength.parse(partLength));
  parts.forEach(part => Part.parse(part));

  return session.state;
}

export default createReducer(initialState, {
  [LOAD_ENTITIES_SUCCESS]: loadData,
});
