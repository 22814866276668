import { createSelector } from 'reselect';

import { getSelectedRobotPart } from './getSelectedRobotPart';

const defaultConstraints = {
  length: 0,
  twist: 0,
  twistInterval: 0,
};

export const getSelectedRobotPartConstraints = createSelector(
  getSelectedRobotPart,
  selectedRobotPart => {
    if (!selectedRobotPart) {
      return defaultConstraints;
    }
    
    const associatedPart = selectedRobotPart.part;

    // TODO: Version 1.0+
    // If multiple attachments, get selected attachment point
    let twistInterval = defaultConstraints.twistInterval;
    const attachments = associatedPart.attachments.toModelArray();
    if (attachments && attachments.length) {
      twistInterval = attachments[0].twistInterval;
    }

    return {
      length: selectedRobotPart.length || defaultConstraints.length,
      twist: selectedRobotPart.twist || defaultConstraints.twist,
      twistInterval,
    };
  }
);
