import { createSelector } from 'reselect';

import { getEntitiesSession } from '../../entities/selectors';
import { getSelectedCategoryId } from './getSelectedCategoryId';

export const getSelectedCategory = createSelector(
  [getEntitiesSession, getSelectedCategoryId],
  (session, selectedCategoryId) => {
    const { PartCategory } = session;

    if (!selectedCategoryId || !PartCategory.idExists(selectedCategoryId)) {
      return null;
    }
    
    const category = PartCategory.withId(selectedCategoryId);
    return category;
  }
);
