export const SET_SELECTED_ROBOT_PART = 'SET_SELECTED_ROBOT_PART';
export const SET_SELECTED_ROBOT_PART_ERROR = `${SET_SELECTED_ROBOT_PART}_ERROR`;
export const SET_SELECTED_ROBOT_PART_ERROR_MESSAGE = 'Error setting the selected part';

export const setSelectedRobotPart = (id, x, y) => ({
  type: SET_SELECTED_ROBOT_PART,
  payload: {
    selectedRobotPartId: id,
    location: {
      x,
      y,
    },
  },
});
