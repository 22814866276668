import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  ADD_PART_TO_WORKSPACE_REQUEST,
  ADD_PART_TO_WORKSPACE_ERROR,
  ADD_PART_TO_WORKSPACE_ERROR_MESSAGE,
  addPartToWorkspaceSuccess,
  setSceneLoaded,
} from '../actions';

import {
  getPlacementMode,
} from '../selectors';

import {
  selectPart,
} from '../../catalog/actions';

import createPartStrategy from './createPart/strategies';

export default function* addPartToWorkspaceWatcher(threeProcessor) {
  while (true) {
    try {
      const { payload } = yield take(ADD_PART_TO_WORKSPACE_REQUEST);
      const { id } = payload;

      yield put(selectPart(id));
    
      const placementMode = yield select(getPlacementMode);
      const { createPart } = createPartStrategy[placementMode];
      yield call(createPart, threeProcessor);
      yield put(addPartToWorkspaceSuccess());
      yield put(setSceneLoaded(true));
    }
    catch (error) {
      yield put({ type: ADD_PART_TO_WORKSPACE_ERROR, error });
      yield call(toast.error, ADD_PART_TO_WORKSPACE_ERROR_MESSAGE);
    }
  }
}
