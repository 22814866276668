import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
  },
});

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

// Needs to be a class component to support refs
class Control extends React.Component {
  render() {
    const {
      children,
      classes,
      innerProps,
      innerRef,
      selectProps,
    } = this.props;

    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: classes.input,
            inputRef: innerRef,
            children,
            ...innerProps,
          },
          endAdornment: selectProps.textFieldAdornment,
        }}
        {...selectProps.textFieldProps}
      />
    )
  }
}

Control.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  selectProps: PropTypes.shape({
    textFieldProps: PropTypes.object.isRequired,
  }),
};

export default withStyles(styles)(Control);
