import { find, isEmpty } from 'lodash-es';

import * as THREE from 'three';
import OrbitControls from 'three-orbitcontrols';

import TransformControls from '../../../features/shared/threejs/TransformControls';

import store from '../../../app/store';
import PartGroupFactory from './PartGroupFactory';
import AssetCacheManager from './AssetCacheManager';
import WindowManager from './WindowManager';
import {TranslationMode} from './TranslationMode';

import {
  showPopper,
  hidePopper,
  updatePopperRequest,
} from '../../../features/poppers/actions';

import {
  clearSelectedRobotPart,
  setSelectedRobotPart,
} from '../../../features/configurator/actions';

import { WORKSPACE_BKG_COLOR, WORKSPACE_GRID_COLOR, PRIMARY_HIGHLIGHT_COLOR } from '../../../common/themes/workspace';
import { PostProcessingComposer } from './PostProcessingComposer';

import Stats from 'stats.js/build/stats';
import InverseKinematicsManager from './InverseKinematicsManager';

export default canvas => {
  const assetCacheManager = new AssetCacheManager();

  const origin = new THREE.Vector3(0, 0, 0);
  const { dispatch } = store;

  const workspaceDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const stats = new Stats();
  if (process.env.NODE_ENV === 'development') {
    stats.showPanel(0);
    document.body.appendChild(stats.dom);
  }

  const pointLightForCamera = new THREE.PointLight(0xffffff, 0.8);   //this light's position needs to be updated each frame so that it tracks with the camera

  const { testDriveTarget, testDriveSnapTarget, screenPlane } = buildTestDriveControls();
  deemphasizeTestDriveTarget();

  const workspaceScene = buildWorkspaceScene(pointLightForCamera, testDriveTarget, screenPlane);   //scene that will have lights, objects, and postprocessing effects

  const camera = buildCamera(workspaceDimensions);
  resetCamera();

  const renderer = buildRenderer(workspaceDimensions);
  let sceneIsDirty = true;
  let wasIntersecting = true;

  const orbitControls = buildOrbitControls();
  const rotateControls = buildRotationControls();

  const controlsScene = buildControlsScene([rotateControls]);      //draw controls separately so they are always on top

  const { composer, hoverOutlinePass, selectedOutlinePass } = PostProcessingComposer(renderer, workspaceScene, controlsScene, camera, workspaceDimensions.width, workspaceDimensions.height);

  const raycaster = new THREE.Raycaster();

  let robotParts = [];
  const partGroupFactory = new PartGroupFactory(camera);

  const inverseKinematicsManager = new InverseKinematicsManager();
  let testDriveActive = false;
  let isDraggingTarget = false;
  let dragStartPosition = new THREE.Vector3();
  let testDriveTranslationMode = TranslationMode.all;

  //////////////////////////////
  //TODO: for HEBI's internal testing
  let allowClamping = true;
  let loggingEnabled = false;
  //////////////////////////////

  const windowManager = new WindowManager(canvas, camera, renderer, composer);

  // Mark scene as dirty (needs a redraw)
  function invalidateScene() {
    sceneIsDirty = true;
  }

  function buildWorkspaceScene(camPointLight, target, plane) {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(WORKSPACE_BKG_COLOR);

    const grid = new THREE.GridHelper(2, 24, WORKSPACE_GRID_COLOR, WORKSPACE_GRID_COLOR);
    grid.rotateX(Math.PI / 2);
    scene.add(grid);

    if (process.env.NODE_ENV === 'development') {
      scene.add(new THREE.AxesHelper(1));
    }

    scene.add(new THREE.AmbientLight(0xffffff, 0.1));
    scene.add(camPointLight);

    scene.add(target);
    target.visible = false;

    scene.add(plane);

    return scene;
  }

  function buildControlsScene(controls) {
    const scene = new THREE.Scene();

    controls.forEach(control => {
      scene.add(control);
    });

    return scene;
  }

  function buildRenderer({ width, height }) {
    const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    renderer.gammaOutput = true;

    return renderer;
  }

  function buildCamera({ width, height }) {
    const fieldOfView = 45;
    const aspectRatio = width / height;
    const nearPlane = 0.01;
    const farPlane = 5;

    const cam = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
    cam.up.set(0, 0, 1);    //set z axis of camera to up

    return cam;
  }

  function resetCamera() {
    camera.position.set(0.5, 0.5, 0.15);
    camera.lookAt(origin);
    camera.updateProjectionMatrix();

    if (orbitControls) {
      orbitControls.target.set(0, 0, 0);
      orbitControls.update();
    }
  }

  function buildOrbitControls() {
    const orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.target.set(0, 0, 0);
    orbitControls.maxDistance = 2;
    orbitControls.minDistance = 0.3;
    orbitControls.update();

    orbitControls.addEventListener('change', () => {
      sceneIsDirty = true;
      if (testDriveActive) {
        updateGrabberLabel();
      }
    });

    return orbitControls;
  }

  function buildRotationControls() {
    const controls = new TransformControls(camera, renderer.domElement);
    controls.setSpace('local');
    controls.setMode('rotate');

    controls.showX = false;
    controls.showY = false;
    controls.showZ = true;

    controls.addEventListener('change', render);

    controls.addEventListener('dragging-changed', function (event) {
      orbitControls.enabled = !event.value;
    });

    return controls;
  }

  function buildTestDriveControls() {
    const target = new THREE.Mesh(
      new THREE.SphereGeometry(0.025, 32, 32),
      new THREE.MeshBasicMaterial({color: PRIMARY_HIGHLIGHT_COLOR, transparent: true})
    );

    const snapTarget = new THREE.Mesh(
      new THREE.BoxGeometry(0.01, 0.01, 0.01),
      new THREE.MeshBasicMaterial({ color: 0xffffff, visible: false })
    );

    const plane = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(10, 10, 16, 16),
      new THREE.MeshBasicMaterial({ color: 0xffffff, alphaTest: 0, visible: false })
    );

    return { testDriveTarget: target, testDriveSnapTarget: snapTarget, screenPlane: plane };
  }

  function updateGrabberLabel() {
    const targetWindowCoordinates = windowManager.getWindowCoordinates(testDriveTarget);
    const positionOffset = 100;
    const dynamicStats = inverseKinematicsManager.getDynamicStats();
    let worldPositionOfSnapTarget = getWorldSnapTarget();

    if(dynamicStats) {
      dispatch(updatePopperRequest(targetWindowCoordinates.x + positionOffset, targetWindowCoordinates.y - positionOffset, {
        statistics: {
          x: Math.abs(worldPositionOfSnapTarget.x),
          y: Math.abs(worldPositionOfSnapTarget.y),
          z: Math.abs(worldPositionOfSnapTarget.z),
          continuousPayload: dynamicStats.continuousPayload,
          peakPayload: dynamicStats.peakPayload,
        },
      }));
    }
  }

  function update() {
    pointLightForCamera.position.copy(camera.position);
    screenPlane.lookAt(camera.position);

    if (process.env.NODE_ENV === 'development') {
      stats.update();
    }
  }

  function render() {
    requestAnimationFrame(render);

    if(sceneIsDirty) {
      update();
      composer.render();
      sceneIsDirty = false;
    }
  }

  function onTouchStart(x, y) {
    // Prevent event from firing if user is actively rotating
    if (rotateControls.visible && rotateControls.dragging) {
      return;
    }

    const mouse2D = windowManager.normalizeMouseScreenCoords(x, y);

    if (testDriveActive) {
      // Enable camera rotation if not dragging test drive target
      orbitControls.enableRotate = !raycastAndBuildScreenOffset(mouse2D);
    } else {
      const intersectedPart = checkNearestRaycastIntersectionOnRobot(mouse2D);
      selectRobotPart(intersectedPart);
      // Enable camera rotation if part was not clicked on
      orbitControls.enableRotate = !intersectedPart;
    }
  }

  function onMouseDown(x, y) {
    // Prevent event from firing if user is actively rotating
    if (rotateControls.visible && rotateControls.dragging) {
      return;
    }

    const mouse2D = windowManager.normalizeMouseScreenCoords(x, y);

    if(testDriveActive) {
      raycastAndBuildScreenOffset(mouse2D);
    }
    else {
      const intersectedPart = checkNearestRaycastIntersectionOnRobot(mouse2D);
      selectRobotPart(intersectedPart);
    }
  }

  function onMouseMove(x, y) {

    // Prevent event from firing if user is actively rotating
    if (rotateControls.visible && rotateControls.dragging) {
      sceneIsDirty = true;
      return;
    }

    const mouse2D = windowManager.normalizeMouseScreenCoords(x, y);

    if(testDriveActive) {
      raycastForTargetHighlight(mouse2D);

      if(isDraggingTarget) {
        // If cursor is over testDriveTarget, need to disable camera rotation (both behaviors are bound to left click)
        orbitControls.enableRotate = false;
        raycastAndUpdateTestDrivePosition(mouse2D);
        sceneIsDirty = true;
      } else {
        // reenable camera rotation once end effector drag is completed
        orbitControls.enableRotate = true;
      }
    }
    else if(!testDriveActive) {
      const intersectedPart = checkNearestRaycastIntersectionOnRobot(mouse2D);
      hoverOverRobotPart(intersectedPart);

      if(intersectedPart || wasIntersecting) {
        sceneIsDirty = true;
      }
      wasIntersecting = intersectedPart;
    }
  }

  function onMouseUp(x, y) {
    isDraggingTarget = false;
    deemphasizeTestDriveTarget();

    //constrain to the snap target
    let worldPositionOfSnapTarget = getWorldSnapTarget();
    testDriveTarget.position.copy(worldPositionOfSnapTarget);

    snapScreenspacePlaneToTestDriveTarget();
    sceneIsDirty = true;
  }

  //TODO: for HEBI's internal testing
  function onKeyPress(key) {
    switch(key) {
      case 88:  //x
        testDriveTranslationMode = TranslationMode.xOnly;
      break;
      case 89:  //y
        testDriveTranslationMode = TranslationMode.yOnly;
      break;
      case 90:  //z
        testDriveTranslationMode = TranslationMode.zOnly;
      break;
      case 69:  //e
        testDriveTranslationMode = TranslationMode.all;
      break;
      case 67:  //c
        allowClamping = !allowClamping;
      break;
      case 76:  //l
        loggingEnabled = !loggingEnabled;
      break;
      default:
      break;
    }
  }

  async function addPart(part, userData) {
    const partAssets = await assetCacheManager.getPartAssets(part);
    const group = partGroupFactory.create(partAssets.meshes, userData);
    const { parentRobotPartId } = getUserData(group);

    if (parentRobotPartId) {
      const parent = findRobotPartForId(parentRobotPartId);
      const { addChild } = getUserData(parent);
      addChild(group);
    }
    else {
      workspaceScene.add(group);
    }

    robotParts.push(group);

    selectRobotPart(group);

    return group;
  }

  function findRobotPartForId(id) {
    return find(robotParts, p => p.userData.id === id);
  }

  function selectRobotPart(robotPart) {
    if(robotPart && !testDriveActive) {
      const { id } = getUserData(robotPart);
      const robotPartGroup = findRobotPartForId(id);

      if (robotPartGroup.parent && robotPartGroup.parent.userData && robotPartGroup.parent.userData.canRotate) {
        rotateControls.attach(robotPartGroup);
      } else {
        rotateControls.detach();
      }

      const x = 20;
      const y = 130;
      dispatch(setSelectedRobotPart(id, x, y));
    }
    else {
      dispatch(clearSelectedRobotPart());
      rotateControls.detach();
    }

    selectedOutlinePass.selectedObjects = selectMeshesForOutline(robotPart);
    sceneIsDirty = true;
  }

  /**
   * Traverses up the parent tree until finding the root PartGroup object,
   * returning the userData object and all associated attributes and functions
   * @param {*} robotPart 
   */
  function getUserData(robotPart) {
    if (isEmpty(robotPart.userData)) {
      return getUserData(robotPart.parent);
    }

    return robotPart.userData;
  }

  function hoverOverRobotPart(robotPart) {
    hoverOutlinePass.selectedObjects = selectMeshesForOutline(robotPart);
  }

  function selectMeshesForOutline(robotPart) {
    if (!robotPart) {
      return [];
    }

    const { getOutlineMeshes } = getUserData(robotPart);
    const meshes = getOutlineMeshes();
    return meshes;
  }

  function checkNearestRaycastIntersectionOnRobot(mouseVector) {
    var mouse3D = new THREE.Vector3(mouseVector.x, mouseVector.y, 0.5);
    raycaster.setFromCamera(mouse3D, camera);

    var intersections = raycaster.intersectObjects(robotParts, true);

    if (intersections.length > 0) {
      return intersections[0].object.parent;    //get the Three Group that is holding the particular mesh we intersected
    }
    return null;
  }

  function raycastForTargetHighlight(mouseVector) {
    var mouse3D = new THREE.Vector3(mouseVector.x, mouseVector.y, 1);
    raycaster.setFromCamera(mouse3D, camera);

    var targetIntersection = raycaster.intersectObject(testDriveTarget);

    if(targetIntersection.length > 0) {
      selectedOutlinePass.selectedObjects = [ targetIntersection[0].object ];
      sceneIsDirty = true;
    }
    else if(!isDraggingTarget) {
      if(selectedOutlinePass.selectedObjects.length > 0) {
        sceneIsDirty = true;
      }

      selectedOutlinePass.selectedObjects = [];
    }
  }

  function raycastAndBuildScreenOffset(mouseVector) {
    var mouse3D = new THREE.Vector3(mouseVector.x, mouseVector.y, 1);
    raycaster.setFromCamera(mouse3D, camera);

    var targetIntersection = raycaster.intersectObject(testDriveTarget);
    var planeIntersection = raycaster.intersectObject(screenPlane);

    if(targetIntersection.length > 0 && planeIntersection.length > 0) {
      isDraggingTarget = true;

      emphasizeTestDriveTarget();

      dragStartPosition.copy(planeIntersection[0].point.sub(screenPlane.position));
    }
    else {
      isDraggingTarget = false;
      deemphasizeTestDriveTarget();
    }

    return isDraggingTarget;
  }

  function raycastAndUpdateTestDrivePosition(mouseVector) {
    var mouse3D = new THREE.Vector3(mouseVector.x, mouseVector.y, 1);
    raycaster.setFromCamera(mouse3D, camera);

    var planeIntersection = raycaster.intersectObject(screenPlane);

    if(planeIntersection.length > 0) {
      const delta = planeIntersection[0].point.sub(dragStartPosition);
      testDriveTarget.position.copy(delta);

      const worldSnapPosition = getWorldSnapTarget();
      const deltaGoal = new THREE.Vector3().subVectors(delta, worldSnapPosition);

      inverseKinematicsManager.updateInverseKinematics(deltaGoal, testDriveTranslationMode, allowClamping, loggingEnabled);

      workspaceScene.updateMatrixWorld();   //force matrix hierarchy update

      //constrain to the snap target
      testDriveTarget.position.copy(worldSnapPosition);

      updateGrabberLabel();
    }
    else {
      snapScreenspacePlaneToTestDriveTarget();
    }
  }

  function snapScreenspacePlaneToTestDriveTarget() {
    //update plane position so it stays with the target
    screenPlane.position.copy(testDriveTarget.position);
    screenPlane.lookAt(camera.position);
  }

  function getWorldSnapTarget() {
    let worldPositionOfSnapTarget = new THREE.Vector3();
    testDriveSnapTarget.getWorldPosition(worldPositionOfSnapTarget);
    return worldPositionOfSnapTarget;
  }

  function activateTestDrive(parentPartId) {

    disableBuildingControls();

    if(parentPartId) {
      const parentPart = findRobotPartForId(parentPartId);

      if(parentPart) {
        testDriveTarget.visible = true;
        dragStartPosition = new THREE.Vector3();

        parentPart.userData.addInterfaceItem(testDriveSnapTarget);

        //set testDriveTarget's local position to the world position of the testDriveSnapTarget - by setting
        //the local position instead of adding it as a child, the coordinate system of testDriveTarget will
        //remain the same as the world because it remains a direct child of the scene
        let worldPositionOfSnapTarget = getWorldSnapTarget();
        testDriveTarget.position.copy(worldPositionOfSnapTarget);

        //do the same thing for the plane
        screenPlane.position.copy(worldPositionOfSnapTarget);

        snapScreenspacePlaneToTestDriveTarget();

        inverseKinematicsManager.buildRobot(parentPart);
        inverseKinematicsManager.updateInverseKinematics(new THREE.Vector3(), allowClamping, loggingEnabled);

        dispatch(showPopper(0, 0, 'TestDriveGrabberLabel'));
        updateGrabberLabel();
      }
    }

    sceneIsDirty = true;
  }

  function deactivateTestDrive() {
    testDriveActive = false;
    testDriveTarget.visible = false;

    dispatch(hidePopper());

    inverseKinematicsManager.resetRobot();
    sceneIsDirty = true;
  }

  function disableBuildingControls() {
    hoverOutlinePass.selectedObjects = [];
    selectedOutlinePass.selectedObjects = [];

    testDriveActive = true;

    rotateControls.detach();    //don't let the user fight the IK
    sceneIsDirty = true;
  }

  function emphasizeTestDriveTarget() {
    testDriveTarget.material.opacity = 1.0;    //increase the opacity
    sceneIsDirty = true;
  }

  function deemphasizeTestDriveTarget() {
    testDriveTarget.material.opacity = 0.8;    //decrease the opacity
    sceneIsDirty = true;
  }

  function clearScene(scene) {
    scene.traverse(function (object) {
      if (object instanceof THREE.Group) {
        scene.remove(object);
      }
    });
  }

  function clearWorkspace() {
    rotateControls.detach();
    dispatch(hidePopper());
    robotParts = [];
    clearScene(workspaceScene);
    sceneIsDirty = true;
  }

  function clearSelectedPart() {
    selectRobotPart(null);
    sceneIsDirty = true;
  }

  function removeRobotParts(robotPartsToRemove) {
    clearSelectedPart();

    for (let i = robotPartsToRemove.length - 1; i >= 0; i -= 1) {
      const { id, parentRobotPartId } = robotPartsToRemove[i];

      const groupToRemove = findRobotPartForId(id);

      if (parentRobotPartId) {
        const parentGroup = findRobotPartForId(parentRobotPartId);
        const { removeChild } = parentGroup.userData;
        removeChild(groupToRemove);
      } else {
        workspaceScene.remove(groupToRemove);
      }
    }

    const robotPartIds = robotPartsToRemove.map(rp => rp.id);
    robotParts = robotParts.filter(rp => robotPartIds.includes(rp.userData.id) === false);
  }

  function updatePartConstraint(id, constraint, value) {
    const robotPart = findRobotPartForId(id);
    if (!robotPart) {
      return;
    }

    const { updateConstraint } = getUserData(robotPart);
    updateConstraint(constraint, value);
    sceneIsDirty = true;
  }

  function updateRobotPart(id, newRobotPart) {
    const index = robotParts.findIndex(p => p.userData.id === id);
    robotParts[index] = newRobotPart;
    sceneIsDirty = true;
  }

  async function replacePart(partToReplace, addPartArgs) {
    clearSelectedPart();

    const robotPartToReplace = findRobotPartForId(partToReplace.id);
    const {
      getChild,
      parentRobotPartId,
    } = getUserData(robotPartToReplace);

    // First, store child group that we need to recreate
    const partGroupHierarchyToKeep = getChild();

    // Then remove the part we are replacing
    if (parentRobotPartId) {
      const parentGroup = findRobotPartForId(parentRobotPartId);
      const { removeChild } = parentGroup.userData;
      removeChild(robotPartToReplace);
    } else {
      workspaceScene.remove(robotPartToReplace);
    }

    // Add the new part
    const { part, userData } = addPartArgs;
    const newPart = await addPart(part, userData);

    // Finally, re-add all previously existing parts
    if (partGroupHierarchyToKeep) {
      const { setLocalTransform } = getUserData(partGroupHierarchyToKeep);
      const { addChild } = getUserData(newPart);
      addChild(partGroupHierarchyToKeep);

      // Apply replacement part's child offset to child
      if (part.attachments && part.attachments.length) {
        const { childTranslationOffset, childRotationOffset } = part.attachments[0];
        setLocalTransform(childTranslationOffset, childRotationOffset);
      }
    }

    updateRobotPart(partToReplace.id, newPart);
    selectRobotPart(newPart);
  }

  return {
    addPart,
    activateTestDrive,
    deactivateTestDrive,
    disableBuildingControls,
    clearWorkspace,
    render,
    update,
    removeRobotParts,
    replacePart,
    resetCamera,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onTouchStart,
    onKeyPress,
    updatePartConstraint,
    invalidateScene,
  };
}
