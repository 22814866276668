export const UPDATE_AVAILABLE_PARTS_PREFIX = 'UPDATE_AVAILABLE_PARTS';
export const UPDATE_AVAILABLE_PARTS_REQUEST = `${UPDATE_AVAILABLE_PARTS_PREFIX}_REQUEST`;
export const UPDATE_AVAILABLE_PARTS_SUCCESS = `${UPDATE_AVAILABLE_PARTS_PREFIX}_SUCCESS`;
export const UPDATE_AVAILABLE_PARTS_ERROR = `${UPDATE_AVAILABLE_PARTS_PREFIX}_ERROR`;
export const UPDATE_AVAILABLE_PARTS_ERROR_MESSAGE = 'Error updating the catalog';

export const updateAvailablePartsRequest = () => ({
  type: UPDATE_AVAILABLE_PARTS_REQUEST,
  payload: {
  }
});
