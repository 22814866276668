
export const SUBMIT_QUOTE_PREFIX = 'SUBMIT_QUOTE';
export const SUBMIT_QUOTE_REQUEST = `${SUBMIT_QUOTE_PREFIX}_REQUEST`;
export const SUBMIT_QUOTE_SUCCESS = `${SUBMIT_QUOTE_PREFIX}_SUCCESS`;
export const SUBMIT_QUOTE_SUCCESS_MESSAGE = 'Your quote has been submitted!';
export const SUBMIT_QUOTE_ERROR = `${SUBMIT_QUOTE_PREFIX}_ERROR`;

export const SUBMIT_QUOTE_ERROR_MESSAGE = `
  We are unable to submit your request for quote.
  Please try again. If the problem persists, please visit our contact us
  page for other ways to submit this request.
`;

export const submitQuoteRequest = (quote, {
  resetForm,
  setSubmitting,
}) => ({
  type: SUBMIT_QUOTE_REQUEST,
  payload: {
    quote,
    resetForm,
    setSubmitting,
  }
});
