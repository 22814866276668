import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { SECONDARY_FONT } from '../../../common/fonts';
import RequestQuoteFormContainer from '../containers/RequestQuoteFormContainer';

const styles = theme => ({
  container: {
    color: theme.palette.neutral.light.contrastText,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.neutral.darkest.color,
    textTransform: 'uppercase',
    fontFamily: SECONDARY_FONT,
    letterSpacing: '2.5px',
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
  },
  form: {
    paddingTop: theme.spacing.unit * 3,
  },
});

const RequestQuotePage = ({ classes }) => (
  <div className={classes.container}>
    <Typography
      variant="h5"
      className={classes.title}
      gutterBottom
    >
      How much would it cost to build this robot?
    </Typography>

    <Typography
      variant="body1"
      className={classes.subtitle}
      gutterBottom
    >
      Please send me a detailed quote based on this configuration.
    </Typography>

    <div className={classes.form}>
      <RequestQuoteFormContainer />
    </div>
  </div>
);

RequestQuotePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RequestQuotePage);
