import {
  put,
  throttle,
} from 'redux-saga/effects';

import {
  POPPER_UPDATE_REQUEST,
  updatePopperSuccess,
} from '../actions';

export default function* updatePopperSaga() {
  yield throttle(15, POPPER_UPDATE_REQUEST, updatePopper);
}

function* updatePopper(action) {
  const { payload } = action;
  const { location, popperProps } = payload;

  yield put(updatePopperSuccess(location, popperProps));
}
