import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import DefaultCardBody from '../../components/GettingStartedCardContent';
import DefaultCardMedia from '../../components/GettingStartedCardMedia';
import DefaultCardActions from '../../components/GettingStartedCardActions';

import GettingStartedCard from '../../components/GettingStartedCard';

import emptyWorkspaceImage from './empty-workspace.png';

import {
  getIsCreatingRobot,
} from '../../selectors';

import {
  createRobotRequest,
} from '../../../configurator/actions';

const FromScratchCard = ({
  handleCreateRobot,
  isSubmitting,
}) => (
  <GettingStartedCard
    media={
      <DefaultCardMedia
        imageUrl={emptyWorkspaceImage}
      />
    }
    content={
      <DefaultCardBody
        title="From Scratch"
        description="Build your robot without starting from a specific base plate."
      />
    }
    actions={
      <DefaultCardActions
        confirmButtonText="Select"
        onClick={handleCreateRobot}
        confirmButtonDisabled={isSubmitting}
      />
    }
  />
);

FromScratchCard.propTypes = {
  handleCreateRobot: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isSubmitting: getIsCreatingRobot(state),
});

const actions = {
  handleCreateRobot: createRobotRequest,
};

export default withRouter(
  connect(mapStateToProps, actions)(FromScratchCard)
);
